import { Fragment, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useL10n from 'L10n';
import useStripeEvents from 'store/StripeEvents';
import { actionTypes, billingActionCreators } from 'store/billing';
import { stripeActionCreators } from 'store/stripe';
import { hasVenue, venuesActionCreators } from 'store/venues';
import { ReactComponent as Led } from 'assets/img/led.svg';
import useForm from 'components/Form';
import { FormCheckbox, FormInput, FormInputGroup, FormLabel, FormRadio } from 'components/formControls';
import { Radio } from 'components/controls';
import { IconButton, TextButton } from 'components/buttons';
import { Pane, PaneBody, PaneHeader } from 'components/Pane';
import RouterPrompt from 'components/RouterPrompt';
import ValidationMessage from 'components/ValidationMessage';
import LostConnectionDialog from 'dialogs/LostConnectionDialog';
import Feedback from './Feedback';
import StripeDialog from './stripe/StripeDialog';
import StripeOnboardingDialog from './stripe/StripeOnboardingDialog';

const Billing = props => {
	const [showStripeDialog, setShowStripeDialog] = useState(false);
	const [showStripeOnboardingDialog, setShowStripeOnboardingDialog] = useState(false);
	const [showLostConnectionDialog, setShowLostConnectionDialog] = useState(false);
	const [localBillingSettings, setLocalBillingSettings] = useState();
	const billingStore = useSelector(state => state.billing);
	const stripeStore = useSelector(state => state.stripe);
	const venue = useSelector(state => state.venues.venue);
	const venueId = venue?.venueUUID;
	const user = useSelector(state => state.user.user);
	const dispatch = useDispatch();
	const _t = useL10n().getText;

	const formSave = () => {
		// console.log('formSave', form.data);
		// trying something a little different / quicker but less rigorous - no "model" class,
		// instead, creating object (billingSettings) on the fly
		const billingSettings = {};
		for(const key in form.data) {
			if(key === 'feeType') {
				if(form.data.feeType === 'f') {
					if(form.data.pbFlatFee_onPrem) billingSettings.pbFlatFee_onPrem = form.data.pbFlatFee_onPrem;
					if(form.data.pbFlatFee_offPrem) billingSettings.pbFlatFee_offPrem = form.data.pbFlatFee_offPrem;
				}
				if(form.data.feeType === 'p') {
					if(form.data.pbPercentFee_onPrem) billingSettings.pbPercentFee_onPrem = form.data.pbPercentFee_onPrem;
					if(form.data.pbPercentFee_offPrem) billingSettings.pbPercentFee_offPrem = form.data.pbPercentFee_offPrem;
				}
			} else if(!key.endsWith('Prem')) {
				const value = form.data[key];
				if(value !== '') {
					billingSettings[key] = value;
				}
			}
		}
		dispatch(billingActionCreators.addUpdateBillingSettings(billingSettings));
	}

	const form = useForm(formSave, validationRules);
	const formRef = useRef(form);

	const lostConnectionHandler = lost => {
		setShowLostConnectionDialog(true);
	}

	const updateVenue = stripeConnectedAccountId => {
		const newVenue = { ...venue, stripeConnectedAccountId: stripeConnectedAccountId };
		console.log('updateVenue', newVenue);
		venuesActionCreators.setVenue(newVenue);
		formRef.current.onChange(null, 'stripeConnectedAccountId', stripeConnectedAccountId);
	}

	const updateBillingSettings = (billingSettings, ) => {
		// store in local state and act on in below effect to overcome the usual stale data in event handlers
		setLocalBillingSettings(billingSettings);
	}

	useEffect(() => {
		// I am not yet sure if I should be updating the billing store or if the user needs to hit save for this to happen so this may need to change...
		if(localBillingSettings) {
			const settings = { ...form.data, ...localBillingSettings };
			formRef.current.reset(settings, true);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [localBillingSettings]);

	const stripeEvents = useStripeEvents(updateVenue, updateBillingSettings, lostConnectionHandler);

	const fetchSettings = () => {
		dispatch(billingActionCreators.fetchSepaDirectDebitSetupKey());
	}

	useEffect(() => {
		if(venueId || hasVenue()) {
			dispatch(billingActionCreators.fetchBillingSettings());
		}
	}, [venueId, dispatch]);

	useEffect(() => {
		if(billingStore.billingSettings) {
			const settings = billingStore.billingSettings;
			if((settings.pbFlatFee_onPrem || settings.pbFlatFee_offPrem) && user?.hasPrivilegedAccess) settings.feeType = 'f';
			if((settings.pbPercentFee_onPrem || settings.pbPercentFee_offPrem) && user?.hasPrivilegedAccess) settings.feeType = 'p';
			formRef.current.reset(settings);
		}
	}, [billingStore.billingSettings, user?.hasPrivilegedAccess]);

	useEffect(() => {
		if(stripeStore.restaurantOnboardingLink) {
			window.open(stripeStore.restaurantOnboardingLink, 'external');
		}
	}, [stripeStore.restaurantOnboardingLink]);

	const stripeButtonClickHandler = e => {
		setShowStripeOnboardingDialog(true);
	}

	const hideStripeOnboardingDialog = (btn, email) => {
		setShowStripeOnboardingDialog(false);
		switch(btn) {
			case 'BTN_SEND_EMAIL':
				// send email
				dispatch(stripeActionCreators.emailRestaurantOnboardingLink(email));
				break;
				case 'BTN_OPEN_STRIPE':
					// open Stripe Onboarding window:
					if(stripeStore.restaurantOnboardingLink) {
						window.open(stripeStore.restaurantOnboardingLink, 'external');
					} else {
						dispatch(stripeActionCreators.generateRestaurantOnboardingLink());
					}
					break;
			default: break;
		}
	}

	const cashPaymentsChangeHandler = e => {
		if(form.data.sepaMandateStatus !== 'a') {
			e.preventDefault();
			setShowStripeDialog(true);
		} else {
			form.onChange(e, 'acceptsCashPayments', !form.data.acceptsCashPayments);
		}
	}

	const hideStripeDialog = btn => {
		console.log('dialog returned:', btn);
		setShowStripeDialog(false);
	}

	const getLedClass = () => {
		switch(form.data.sepaMandateStatus) {
			case 'p': return 'led_amber';
			case 'a': return 'led_green';
			case 'i': return 'led_red';
			default: return 'led_off';
		}
	}

	const getLedLabel = () => {
		switch(form.data.sepaMandateStatus) {
			case 'p': return _t('LBL_SEPA_MANDATE_PENDING');
			case 'a': return _t('LBL_SEPA_MANDATE_ACTIVE');
			case 'i': return _t('LBL_SEPA_MANDATE_INACTIVE');
			default: return '';
		}
	}

	return (
		<Fragment>
			<Pane className="Billing animated fadeIn max-width-600">
				<PaneHeader className={ billingStore.loading === actionTypes.fetchBillingSettingsRequest ? 'async': ''}>

					<h1>{ _t('HDG_BILLING', true) }</h1>
					<div className="Pane-toolbar">
						<IconButton
							title={ _t('TTP_SAVE') }
							icon="save"
							async={ billingStore.loading === actionTypes.addUpdateBillingSettingsRequest }
							onClick={ form.onSave }
						/>
					</div>

				</PaneHeader>
				<PaneBody>

					<section className="Pane-body_section" id="paymentSettings">
						<Radio
							name="receivesOrdersInOwnPOS"
							disabled={ true }
							defaultChecked={ venue?.receivesOrdersInOwnPOS }
						>
							{ _t('LBL_PAYMENTS_BY_POS') }
							<br/><em>{ _t('TXT_PAYMENTS_BY_POS') }</em>
						</Radio>
						<Radio
							name="receivesOrdersInOwnPOS"
							disabled={ true }
							defaultChecked={ !venue?.receivesOrdersInOwnPOS }
						>
							{ _t('LBL_PAYMENTS_BY_PICTABITE') }
						</Radio>
					</section>

					<section className="Pane-body_section" id="billingForm">
						<ValidationMessage open={ !!form.errors }/>

						<div className="form-row">
							<FormLabel htmlFor="menuPricesIncludeVAT" className="col-sm-5" label="_PRICES_INCLUDE_TAX"/>
							<div className="col-sm-7">
								<FormCheckbox form={ form } field="menuPricesIncludeVAT"/>
							</div>
						</div>
						<div className="form-row">
							<FormLabel htmlFor="stripe" className="col-sm-5" label="_STRIPE_ACCOUNT_ID"/>
							<div className="col-sm-7">
								{ form.data.stripeConnectedAccountId
									? <FormInput form={ form } readOnly={ true } field="stripeConnectedAccountId"/>
									: <TextButton
											className="btn-primary"
											// async={ stripeStore.restaurantOnboardingLink }
											onClick={ stripeButtonClickHandler }
										>
											{ _t('BTN_CONNECT_STRIPE', true) }
									</TextButton>
									// : <button
									// 		// className={ classNames('btn', 'btn-primary', { async: stripeStore.loading === stripeActionTypes.generateRestaurantOnboardingLinkRequest })}
									// 		className={ classNames('btn', 'btn-primary', { async: stripeStore.restaurantOnboardingLink })}
									// 		onClick={ stripeButtonClickHandler }
									// 	>
									// 		{ _t('LBL_CONNECT_STRIPE', true) }
									// 	</button>
								}
							</div>
						</div>
						<div className="form-row">
							<FormLabel htmlFor="legalName" className="col-sm-5" label="_LEGAL_NAME"/>
							<div className="col-sm-7">
								<FormInput form={ form } field="legalName"/>
							</div>
						</div>
						<div className="form-row">
							<FormLabel htmlFor="taxId" className="col-sm-5" label="_TAX_ID"/>
							<div className="col-sm-7">
								<FormInput form={ form } field="taxId"/>
							</div>
						</div>

						{/* <div className="form-row">
							<FormLabel htmlFor="bankAccountNumber" className="col-sm-5" label="_ACCOUNT_NO"/>
							<div className="col-sm-7">
								<FormInput form={ form } field="bankAccountNumber"/>
							</div>
						</div>
						<div className="form-row">
							<FormLabel htmlFor="bankRoutingNumber" className="col-sm-5" label="_ROUTING_NO"/>
							<div className="col-sm-7">
								<FormInput form={ form } field="bankRoutingNumber"/>
							</div>
						</div>
						<div className="form-row">
							<FormLabel htmlFor="accountLinkedEmail" className="col-sm-5" label="_ACCOUNT_LINKED_EMAIL"/>
							<div className="col-sm-7">
								<FormInput type="email" form={ form } field="accountLinkedEmail"/>
							</div>
						</div>
						<div className="form-row">
							<FormLabel htmlFor="accountLinkedMobilePhone" className="col-sm-5" label="_ACCOUNT_LINKED_MOBILE"/>
							<div className="col-sm-7">
								<FormInput type="tel" form={ form } field="accountLinkedMobilePhone"/>
							</div>
						</div> */}

					</section>

					<section className="Pane-body_section" id="bankingForm">

						<div className="form-row">
							<FormLabel htmlFor="acceptsCashPayments" className="col-sm-5" label="_ACCEPT_CASH_PAYMENTS"/>
							<div className="col-sm-7">
								<FormCheckbox form={ form } field="acceptsCashPayments" onChange={ cashPaymentsChangeHandler }/>
							</div>
						</div>
						<div className="form-row">
							<FormLabel htmlFor="stripeCustomerId" className="col-sm-5" label="_STRIPE_CUSTOMER_ID"/>
							<div className="col-sm-7">
								<FormInput readOnly form={ form } field="stripeCustomerId"/>
							</div>
						</div>
						<div className="form-row">
							<FormLabel htmlFor="sepaPaymentMethodId" className="col-sm-5" label="_SEPA_PAYMENT_METHOD_ID"/>
							<div className="col-sm-7">
								<FormInput readOnly form={ form } field="sepaPaymentMethodId"/>
							</div>
						</div>
						<div className="form-row">
							<FormLabel htmlFor="sepaMandateStatus" className="col-sm-5" label="_SEPA_MANDATE_STATUS"/>
							<div className="col-sm-7">
								{/* <FormInput readOnly form={ form } field="sepaMandateStatus"/> */}
								<Led id="sepaStatusLed" className={ getLedClass() }/>
								<label htmlFor="sepaStatusLed">{ getLedLabel() }</label>
							</div>
						</div>
						<div className="form-row">
							<TextButton
								className="btn-primary offset-sm-5"
								onClick={ () => setShowStripeDialog(true) }
							>
								{ _t(`BTN_${ form.data.sepaMandateStatus ? 'CHANGE': 'SET' }_PAYMENT_METHOD`, true) }
							</TextButton>
						</div>

					</section>

					{ !!user?.hasPrivilegedAccess &&
						<section className="Pane-body_section" id="adminForm">

							<div className="form-row">
								<div className="offset-sm-5 col-sm-7 form-adjacent gap15 text-center">
									<label>{ _t('LBL_ON_PREM') }</label>
									<label>{ _t('LBL_OFF_PREM') }</label>
								</div>
							</div>

							<div className="form-row">
								<FormRadio className="col-form-label col-sm-5" form={ form } field="feeType" value="p">
									{ _t('LBL_PERCENT_FEE') }
								</FormRadio>
								<div className="col-sm-7 form-adjacent gap15">
									<FormInputGroup
										append="%"
										type="money"
										disabled={ form.data.feeType !== 'p' }
										form={ form }
										field={ form.data.feeType === 'p' ? 'pbPercentFee_onPrem' : 'unknown' }
									/>
									<FormInputGroup
										append="%"
										type="money"
										disabled={ form.data.feeType !== 'p' }
										form={ form }
										field={ form.data.feeType === 'p' ? 'pbPercentFee_offPrem' : 'unknown' }
									/>
								</div>
							</div>
							<div className="form-row">
								<FormRadio className="col-form-label col-sm-5" form={ form } field="feeType" value="f">
									{ _t('LBL_FLAT_FEE') }
								</FormRadio>
								<div className="col-sm-7 form-adjacent gap15">
									<FormInputGroup
										prepend={ venue?.currencySymbol }
										type="money"
										disabled={ form.data.feeType !== 'f' }
										form={ form }
										field={ form.data.feeType === 'f' ? 'pbFlatFee_onPrem' : 'unknown' }
									/>
									<FormInputGroup
										prepend={ venue?.currencySymbol }
										type="money"
										disabled={ form.data.feeType !== 'f' }
										form={ form }
										field={ form.data.feeType === 'f' ? 'pbFlatFee_offPrem' : 'unknown' }
									/>
								</div>
							</div>

						</section>
					}

					<Feedback status={ billingStore.status }/>

				</PaneBody>
			</Pane>

			<StripeDialog
				show={ showStripeDialog }
				onHide={ hideStripeDialog }
				fetchSettings={ fetchSettings }
				settings={ billingStore.SepaDirectDebitSetupKey }
			/>

			<LostConnectionDialog
				show={ showLostConnectionDialog }
				onHide={ () => { setShowLostConnectionDialog(false ) }}
				reset={ stripeEvents.reset }
			/>

			<StripeOnboardingDialog
				show={ showStripeOnboardingDialog }
				email={ billingStore.billingSettings?.email }
				onHide={ hideStripeOnboardingDialog }
			/>

			<RouterPrompt when={ form.isDirty }/>

			</Fragment>
		);

}

export default Billing;

const validationRules = {
	// stripeConnectedAccountId: true,
	legalName: true,
	taxId: true,
}