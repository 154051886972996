import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { config } from 'store';

const useStripeEvents = (updateVenue, updateBillingSettings, lostConnectionHandler) => {
	const venue = useSelector(state => state.venues.venue);
	const [isBusy, setIsBusy] = useState(true);
	const [event, setEvent] = useState();
	const [resetValue, setResetValue] = useState(Math.random());
	const verbose = true;

	useEffect(() => {
		const log = (...args) => {
			if(verbose) console.log(...args);
		}

		const eventHandler = evt => {
			const event = {
				type: evt.type,
				data: JSON.parse(evt.data)
			}
			if(verbose) console.log(event);
			setEvent(event);
			setIsBusy(false);
			switch(event.type) {
				case 'onSubscribe': break;
				case 'updateStripeConnectedAccountId':
					updateVenue(event.data.stripeConnectedAccountId);
					break;
				case 'updateSEPAMandateStatus':
					updateBillingSettings(event.data);
					break;
					default: break;
			}
		}

		const events = ['onSubscribe', 'updateStripeConnectedAccountId', 'updateSEPAMandateStatus'];
		let source;
		if(venue) {
			const url = config.ssePath + '/venue/subscribe';
			log('Attempting SSE on', url);
			source = new EventSource(url, { withCredentials: true });
			setIsBusy(true);
			if(source) {
				source.onopen = event => log('open', event, 'readyState:', source.readyState);
				source.onmessage = event => log('message:', event);
				source.onerror = event => {
					log('error:', event);
					if(source.readyState === 2 && lostConnectionHandler) {
						setIsBusy(false);
						// if connection is closed (2) then attempt to reconnect...
						lostConnectionHandler(true);
					}
				}
				events.forEach(eventType => source.addEventListener(eventType, eventHandler, false));
			} else {
				console.error('call to EventSource failed');
			}
		}
		return () => {
			if(source && venue) {
				events.forEach(eventType => source.removeEventListener(eventType, eventHandler, false));
				log('closing SSE connection...', venue);
				source.close();
				source = undefined;
			}
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [venue, resetValue, verbose]);

	const reset = () => {
		// consumers can call this to force reopening the existing connection
		setResetValue(Math.random());
	}

	return {
		event,
		isBusy,
		reset
	}
}

export default useStripeEvents;