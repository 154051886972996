import { Fragment, useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'components/Modal';
import Typeahead from 'components/Typeahead';
import { Checkbox } from 'components/controls';

const AddModifierItemDialog = props => {

	const [value, setValue] = useState(null);
	const [isDefault, setIsDefault] = useState(false);

	useEffect(() => {
		// reset dropdown each time dialog is shown
		if(props.show) {
			// reset
			setValue(null);
			setIsDefault(false);
		}
	}, [props.show]);

	const onButtonClick = e => {
		let groupItem = null;
		if(e.target.id === 'btnOk') {
			const item = props.modifierStore.items.find(item => item.id === value);
			groupItem = {
				modifierItemId: item.id,
				isDefault: isDefault ? 1 : 0	// the api requires a number rather than a boolean
			}
		}
		props.onHide(groupItem);
	}

	const onChange = (event, id, key, label) => {
		// console.log(event, id, key, label);
		if(key) {
			key = parseInt(key, 10);
			setValue(key);
		} else {
			setIsDefault(event.target.checked);
		}
	}

	return (
		<Modal show={ props.show }>
			<ModalHeader>Add New Modifier Group Item</ModalHeader>
			<ModalBody className="simple-controls">

				{ props.modifierStore.items?.length > 0
					?
						<Fragment>

							<Typeahead
								placeholder="Select Modifier Group Item"
								className="focus"
								data={ props.modifierStore.items }
								value={ value }
								maxMatches={ 5 }
								minLength={ 2 }
								keys="id"
								labels="retailName"
								highlight={ true }
								onChange={ onChange }
							/>

							<Checkbox label="default" checked={ isDefault } onChange={ onChange }/>

						</Fragment>

					:
						<p>Please use MODIFIERS to add some modifier items first.</p>
				}
			</ModalBody>
			<ModalFooter className="Modal-footer_buttons">
				{ props.modifierStore.items?.length > 0
					?
						<Fragment>
							<button className="btn btn-secondary" onClick={ onButtonClick } id="btnCancel">Cancel</button>
							<button className="btn btn-primary" disabled={ value === null } onClick={ onButtonClick } id="btnOk">OK</button>
						</Fragment>
					:
						<button className="btn btn-primary" onClick={ onButtonClick } id="btnCancel">OK</button>
				}
			</ModalFooter>
		</Modal>
	);
};

export default AddModifierItemDialog;