import { Fragment, useRef } from "react";
import { Link } from "react-router-dom";
import useL10n from "L10n";

import { ReactComponent as Logo } from 'assets/img/logo.svg';

import home_banner1 from 'assets/img/home1.jpg';
import home_banner2 from 'assets/img/home2.jpg';

import phs0 from 'assets/img/app/phs0.png';
import phs0_x2 from 'assets/img/app/phs0-x2.png';

import phs1 from 'assets/img/app/phs1.png';
import phs1_x2 from 'assets/img/app/phs1-x2.png';

import phs2 from 'assets/img/app/phs2.png';
import phs2_x2 from 'assets/img/app/phs2-x2.png';

import phs3 from 'assets/img/app/phs3.png';
import phs3_x2 from 'assets/img/app/phs3-x2.png';

import phs4 from 'assets/img/app/phs4.png';
import phs4_x2 from 'assets/img/app/phs4-x2.png';

import phs5 from 'assets/img/app/phs5.png';
import phs5_x2 from 'assets/img/app/phs5-x2.png';

import icon_gift from 'assets/img/icon_gift.svg';
import icon_increase from 'assets/img/icon_increase.svg';
import icon_serve from 'assets/img/icon_serve.svg';
import icon_table from 'assets/img/icon_table.svg';

import { FormInput } from 'components/formControls';
import useForm from 'components/Form';
import MapControl from "components/MapControl";
import ValidationMessage from "components/ValidationMessage";
import './Home.scss';

const Home = props => {
	const demo = useRef();
	const _t = useL10n().getText;

	const formSave = e => {
		props.requestDemo(form.data);
	}

	const form = useForm(formSave, {
		restaurant: true,
		address: true,
		name: true,
		phone: true,
		email: true
	});

	const requestDemoClickHandler = e => {
		const demoSection = demo.current;
		demoSection.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
		setTimeout(() => {
			// without the timeout, the scroll is instant rather than smooth
			demoSection.querySelector('INPUT').focus();
		}, 500);
	}

	const mapsInitHandler = () => {
		const placeHandler = () => {
			const place = autocomplete.getPlace();
			const address = buildAddress(place);
			const addressString = (address.street ? address.street + ', ' : '')
				+ (address.city ? address.city + ', ' : '')
				+ (address.provinceName ? address.provinceName + ', ' : '')
				+ address.countryName
				+ (address.postCode ? ' ' + address.postCode : '')
			;
			form.onChange(null, 'address', addressString);
		}

		const venueInput = document.querySelector('#addressInput');
		const autocomplete = new window.google.maps.places.Autocomplete(venueInput, {
			fields: ['address_components', 'geometry'],
			types: ['establishment'],
		});
		autocomplete.addListener('place_changed', placeHandler);
	}

	return (
		<Fragment>
			<header className="Home-header fixed-top" role="navigation">

				<Link to="/" className="Home-logo">
					<Logo/>
					<span className="sr-only">Pictabite</span>
				</Link>

				<div className="Home-header_toolbar">
					<Link className="btn btn-secondary" to={{ pathname: '/venues/home/', state: { fromHome: true }}}>
						{ _t('BTN_SIGNIN', true) }
					</Link>
					<button className="btn btn-primary" onClick={ requestDemoClickHandler }>{ _t('BTN_REQUEST_DEMO', true) }</button>
				</div>

			</header>

			<main className="Home">
				<div className="container">

					<MapControl
						onInit={ mapsInitHandler }
					/>

					<h1>{ _t('HDG_HOME_H1') }</h1>
					<h2>{ _t('HDG_HOME_H2') }</h2>

					<section className="Home-banner">
						<picture>
							<source media="(max-width: 575px)" srcSet={ home_banner2 }/>
							<source media="(min-width: 576px)" srcSet={ home_banner1 }/>
							<img src={ home_banner1 } alt="banner" className="img-fluid"/>
						</picture>
					</section>

					<section className="Home-content">

						<div className="row gutters-lgu-30">

							<div className="Home-info col-md-6">
								<div className="Home-img">
									<img src={ icon_serve } alt="" className="img-fluid"/>
								</div>
								<div className="Home-txt">
									<h3>{ _t('HDG_HOME_P1') }</h3>
									<p>{ _t('TXT_HOME_P1') }</p>
								</div>
							</div>

							<div className="Home-info col-md-6">
								<div className="Home-img">
									<img src={ icon_increase } alt="" className="img-fluid"/>
								</div>
								<div className="Home-txt">
									<h3>{ _t('HDG_HOME_P2') }</h3>
									<p>{ _t('TXT_HOME_P2') }</p>
								</div>
							</div>

						</div>
						<div className="row gutters-lgu-30">

							<div className="Home-info col-md-6">
								<div className="Home-img">
									<img src={ icon_table } alt="" className="img-fluid"/>
								</div>
								<div className="Home-txt">
									<h3>{ _t('HDG_HOME_P3') }</h3>
									<p>{ _t('TXT_HOME_P3') }</p>
								</div>
							</div>

							<div className="Home-info col-md-6">
								<div className="Home-img">
									<img src={ icon_gift } alt="" className="img-fluid"/>
								</div>
								<div className="Home-txt">
									<h3>{ _t('HDG_HOME_P4') }</h3>
									<p>{ _t('TXT_HOME_P4') }</p>
								</div>
							</div>

						</div>

					</section>

					<section className="Home-images">
						<h3>{ _t('HDG_MOBILE_APP') }</h3>
						<div className="row gutters-mbl-10">
							<div className="col-md-6 col-lg-4">
								<img src={ phs0 } srcSet={ `${ phs0 }, ${ phs0_x2 } 2x`} alt=""></img>
							</div>
							<div className="col-md-6 col-lg-4">
								<img src={ phs1 } srcSet={ `${ phs1 }, ${ phs1_x2 } 2x`} alt=""></img>
							</div>
							<div className="col-md-6 col-lg-4">
								<img src={ phs2 } srcSet={ `${ phs2 }, ${ phs2_x2 } 2x`} alt=""></img>
							</div>
							<div className="col-md-6 col-lg-4">
								<img src={ phs3 } srcSet={ `${ phs3 }, ${ phs3_x2 } 2x`} alt=""></img>
							</div>
							<div className="col-md-6 col-lg-4">
								<img src={ phs4 } srcSet={ `${ phs4 }, ${ phs4_x2 } 2x`} alt=""></img>
							</div>
							<div className="col-md-6 col-lg-4">
								<img src={ phs5 } srcSet={ `${ phs5 }, ${ phs5_x2 } 2x`} alt=""></img>
							</div>
						</div>
					</section>

				</div>

				<section ref={ demo } className="Home-demo">
					<div className="container">

						<div className="row">
							<div className="col-md-6 Home-demo_panel">

								<div className="Home-info">
									<span className="Home-bullet">1</span>
									<div className="Home-txt">
										<h3>{ _t('HDG_DEMO_P1') }</h3>
										<p>{ _t('TXT_DEMO_P1') }</p>
									</div>
								</div>

								<div className="Home-info">
									<span className="Home-bullet">2</span>
									<div className="Home-txt">
										<h3>{ _t('HDG_DEMO_P2') }</h3>
										<p>{ _t('TXT_DEMO_P2') }</p>
									</div>
								</div>

								<div className="Home-info">
									<span className="Home-bullet">3</span>
									<div className="Home-txt">
										<h3>{ _t('HDG_DEMO_P3') }</h3>
										<p>{ _t('TXT_DEMO_P3') }</p>
									</div>
								</div>

							</div>

							<div className="col-md-6 Home-demo_panel" id="form">
								<h3 className="text-center">{ _t('HDG_DEMO_REQUEST') }</h3>

								<ValidationMessage open={ !!form.errors }/>

								<div className="form-row">
									<div className="col-sm-12">
										<FormInput placeholder={ _t('PLH_DEMO_RESTAURANT_NAME') } form={ form } field="restaurant"/>
									</div>
								</div>
								<div className="form-row">
									<div className="col-sm-12">
										<FormInput id="addressInput" placeholder={ _t('PLH_DEMO_RESTAURANT_ADDRESS') } form={ form } field="address"/>
									</div>
								</div>
								<div className="form-row">
									<div className="col-sm-6">
										<FormInput placeholder={ _t('PLH_DEMO_NAME') } form={ form } field="name"/>
									</div>
									<div className="col-sm-6">
										<FormInput placeholder={ _t('PLH_DEMO_MOBILE') } form={ form } field="phone"/>
									</div>
								</div>
								<div className="form-row">
									<div className="col-sm-12">
										<FormInput placeholder={ _t('PLH_DEMO_EMAIL') } form={ form } field="email"/>
									</div>
								</div>

								<button className="btn btn-primary w-100" onClick={ form.onSave }>{ _t('BTN_DEMO_SUBMIT', true) }</button>

							</div>

						</div>

					</div>
				</section>

			</main>

			<nav className="Home-footer">
				<div className="container">
					<div>{ _t('TXT_WEB_COPYRIGHT') }</div>
					<div className="text-center">
						<button className="btn btn-link" onClick={ () => props.showMessage('terms') }>
							{ _t('HDG_WEB_TERMS') }
						</button>•
						<button className="btn btn-link" onClick={ () => props.showMessage('privacy') }>
							{ _t('HDG_WEB_PRIVACY') }
						</button>•
						<button className="btn btn-link" onClick={ () => props.showMessage('guidelines') }>
							{ _t('HDG_WEB_GUIDELINES') }
						</button>
					</div>
						<button className="btn btn-link text-uppercase" onClick={ props.contactUs }>{ _t('HDG_CONTACT_US', true) }</button>
				</div>
			</nav>
		</Fragment>
	);
};

export default Home;

class Address {
	street = null;
	city = null;
	postCode = null;
	countryName = null;
	provinceName = null;	// state
	lat = null;
	lon = null;
}

const buildAddress = (place) => {
	const address = new Address();
	let street = '';
	let postCode = '';
	for(const component of place.address_components) {
		const componentType = component.types[0];
		// console.log(componentType, component.short_name, component.long_name);
		switch(componentType) {
			case 'street_number': {
				street = `${ component.long_name } ${ street }`;
				break;
			}
			case 'route': {
				street += component.short_name;
				break;
			}
			case 'postal_code': {
				postCode = `${ component.long_name }${ postCode }`;
				break;
			}
			case 'postal_code_suffix': {
				postCode = `${ postCode }-${ component.long_name }`;
				break;
			}
			case 'locality':
			case 'postal_town': {
				address.city = component.long_name;
				break;
			}
			case 'administrative_area_level_1': {
				// address.provinceName = component.long_name;
				address.provinceName = component.short_name;
				break;
			}
			case 'country': {
				// address.countryName = component.long_name;
				address.countryName = component.short_name;
				break;
			}
			default: break;
		}
	}
	address.street = street;
	address.postCode = postCode;
	address.lat = place.geometry.location.lat();
	address.lon = place.geometry.location.lng();
	return address;
}