import { Fragment, useState } from "react";

const MoreButton = props => {
	const [more, setMore] = useState(false);

	const buttonClick = e => {
		switch(e.currentTarget.innerText) {
			case 'more_horiz': setMore(true); break;
			case 'edit': setMore(false); props.onOrderModify(props.order); break;
			case 'delete': setMore(false); props.onOrderCancel(props.order); break;
			default: setMore(false); break;
		}
	}

	return (
		<Fragment>
			{ more
				?
					<Fragment>
						<button title="modify" className="btn btn-icon btn-secondary" onClick={ buttonClick }>
							<span className="material-icons">edit</span>
						</button>
						<button title="cancel" className="btn btn-icon btn-secondary" onClick={ buttonClick }>
							<span className="material-icons">delete</span>
						</button>
					</Fragment>
				:
					<button className="btn btn-icon btn-secondary" onClick={ buttonClick }>
						<span className="material-icons">more_horiz</span>
					</button>
			}
		</Fragment>
	);
};

export default MoreButton;