import storeUtils from './storeUtils';

export const actionTypes = {
	printersFail: 'PRINTERS_FAIL',
	
	fetchPrintersRequest: 'FETCH_PRINTERS_PRINTERS_REQUEST',
	fetchPrintersSuccess: 'FETCH_PRINTERS_PRINTERS_SUCCESS',
	addPrinterRequest: 'ADD_PRINTER_PRINTERS_REQUEST',
	addPrinterSuccess: 'ADD_PRINTER_PRINTERS_SUCCESS',
	updatePrinterRequest: 'UPDATE_PRINTER_PRINTERS_REQUEST',
	updatePrinterSuccess: 'UPDATE_PRINTER_PRINTERS_SUCCESS',
	removePrinterRequest: 'REMOVE_PRINTER_PRINTERS_REQUEST',
	removePrinterSuccess: 'REMOVE_PRINTER_PRINTERS_SUCCESS',

	updatePrintJobRequest: 'UPDATE_PRINTJOB_PRINTERS_REQUEST',
	updatePrintJobSuccess: 'UPDATE_PRINTJOB_PRINTERS_SUCCESS',
	deletePrintJobRequest: 'DELETE_PRINTJOB_PRINTERS_REQUEST',
	deletePrintJobSuccess: 'DELETE_PRINTJOB_PRINTERS_SUCCESS',

	createPrintJobsRequest: 'CREATE_PRINTJOBS_PRINTERS_REQUEST',
	createPrintJobsSuccess: 'CREATE_PRINTJOBS_PRINTERS_SUCCESS',

	setPrintersSuccess: 'SET_PRINTERS_PRINTERS_SUCCESS'
}

const initialState = { printers: null, types: null, common: null, loading: null, errors: null };

export const printersActionCreators = {
	
	
	fetchPrinters: () => async dispatch => {
		dispatch({ type: actionTypes.fetchPrintersRequest });
		const response = await storeUtils.execute('printers', 'getPrinters');
		if(response.errors) {
			dispatch({ type: actionTypes.printersFail, errors: response.errors });
		} else {
			dispatch({ type: actionTypes.fetchPrintersSuccess, response: response });
		}
	},
	
	addPrinter: printer => async dispatch => {
		dispatch({ type: actionTypes.addPrinterRequest });
		const response = await storeUtils.execute('printers', 'addPrinter', printer);
		if(response.errors) {
			dispatch({ type: actionTypes.printersFail, errors: response.errors });
		} else {
			dispatch({ type: actionTypes.addPrinterSuccess, response: response });
		}
	},
	
	updatePrinter: printer => async dispatch => {
		dispatch({ type: actionTypes.updatePrinterRequest });
		const data = await storeUtils.execute('printers', 'updatePrinter', printer);
		if(data.errors) {
			dispatch({ type: actionTypes.printersFail, errors: data.errors });
		} else {
			dispatch({ type: actionTypes.updatePrinterSuccess, printer: printer });
		}
	},
	
	removePrinter: printer => async dispatch => {
		dispatch({ type: actionTypes.removePrinterRequest });
		const data = await storeUtils.execute('printers', 'removePrinter', { printerId: printer.printerId });
		if(data.errors) {
			dispatch({ type: actionTypes.printersFail, errors: data.errors });
		} else {
			dispatch({ type: actionTypes.removePrinterSuccess, printer: printer });
		}
	},

	updatePrintJob: payload => async dispatch => {
		dispatch({ type: actionTypes.updatePrintJobRequest });
		const data = await storeUtils.execute('printers', 'updatePrintJob', payload);
		if(data.errors) {
			dispatch({ type: actionTypes.printersFail, errors: data.errors });
		} else {
			dispatch({ type: actionTypes.updatePrintJobSuccess });
		}
	},
	
	deletePrintJob: payload => async dispatch => {
		dispatch({ type: actionTypes.deletePrintJobRequest });
		const data = await storeUtils.execute('printers', 'deletePrintJob', payload);
		if(data.errors) {
			dispatch({ type: actionTypes.printersFail, errors: data.errors });
		} else {
			dispatch({ type: actionTypes.deletePrintJobSuccess });
		}
	},

	createPrintJobs: payload => async dispatch => {
		dispatch({ type: actionTypes.createPrintJobsRequest });
		const data = await storeUtils.execute('printers', 'createPrintJobs', payload);
		if(data.errors) {
			dispatch({ type: actionTypes.printersFail, errors: data.errors });
		} else {
			dispatch({ type: actionTypes.createPrintJobsSuccess });
		}
	},

	setPrinters: printers => {
		return { type: actionTypes.setPrintersSuccess, printers: printers };
	}
};

export const reducer = (state = initialState, action) => {

	if(action.type.endsWith('PRINTERS_REQUEST')) {
		return {
			...state,
			loading: action.type,
			errors: null
		}
	}

	switch(action.type) {
		case actionTypes.printersFail: {
			return {
				...state,
				loading: null,
				errors: action.errors
			}
		}

		case actionTypes.addPrinterSuccess:
		case actionTypes.fetchPrintersSuccess: {
			const printers = action.response.printers.map(printer => {
				printer.enabled = !!printer.enabled;
				return printer;
			})
			const types = [];
			action.response.printerTypes.forEach(type => {
				if(!types[type.family]) types[type.family] = { models: [] };
				types[type.family].models.push({ value: type.type, label: type.model });
			});
			return {
				...state,
				loading: null,
				printers: printers,
				types: types,
				common: action.response.common
			}
		}

		case actionTypes.updatePrinterSuccess: {
			const printers = state.printers?.map(printer => printer.printerId === action.printer.printerId ? action.printer : printer);
			return {
				...state,
				loading: null,
				printers: printers
			}
		}

		case actionTypes.removePrinterSuccess: {
			const printers = storeUtils.deleteElement(state.printers, action.printer.printerId, 'printerId');
			return {
				...state,
				loading: null,
				printers: printers
			}
		}

		case actionTypes.updatePrintJobSuccess:
		case actionTypes.deletePrintJobSuccess: {
			// server to client updates are handled via SSE so...
			return {
				...state,
				loading: null
			}
		}

		case actionTypes.setPrintersSuccess: {
			return {
				...state,
				loading: null,
				printers: action.printers
			}
		}

		default: {
			return state;
		}
	}
}