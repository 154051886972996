import { Fragment, useEffect, useState } from 'react';
import { actionTypes } from 'store/menus';
import { Collapsible, CollapserButton } from 'components/Collapsible';
import { IconButton } from 'components/buttons';
import { ItemPaneFooter, Pane, PaneBody, PaneHeader, PaneItem, PaneItemButton, PaneMenu } from 'components/Pane';
import MenuItem from './MenuItem';

const MenusPane = props => {

	const [collapsiblesOpen, setCollapsiblesOpen] = useState(true);
	const menus = props.menuStore.menus;
	const menu = menus?.find(menu => menu.menuId === props.menuId);
	let shiftData;

	useEffect(() => {
		// this is really for testing - do I want to keep this?
		// if I'm keeping it, would be better to work without altKey but then need to ensure target is not input etc - or another dialog is displayed
		const keyDownHandler = e => {
			if(e.altKey) {
				switch(e.key) {
					case '+': props.onAddEditClick(); break;
					default: break;
				}
			}
		}
		window.addEventListener('keydown', keyDownHandler);
		return () => {
			window.removeEventListener('keydown', keyDownHandler);
		};
	}, [props]);

	useEffect(() => {
		if(props.menuId) {
			// default for newly shown menu is to open all sections
			setCollapsiblesOpen(true);
		}
	}, [props.menuId]);

	const toggleCollapsibles = toggle => {
		menu.menuSections.forEach(section => {
			section.collapsed = collapsiblesOpen;
		});
		setCollapsiblesOpen(!collapsiblesOpen);
	}

	const renderMenuItems = section => {
		return section.menuItems.map((item, ix) => {
			if(item.menuItemId === props.item.menuItemId) {
				// this is the current item, so store data needed for disabling shift buttons
				shiftData = [section.menuItems.length - 1, ix];
			}
			const className = props.activeItemId === item.menuItemId &&
				(props.menuStore.loading === actionTypes.fetchItemRequest || props.menuStore.loading === actionTypes.updateItemRequest) ? 'async' : '';
			return <MenuItem key={ item.menuItemId } className={ className } item={ item } menuId={ props.menuId } currencySymbol={ props.currencySymbol }/>
		});
	}

	const renderMenu = () => {
		if(!props.menuId || !menu) return null;
		if(menu.menuSections.length === 0) {
			return <p className="Pane-message">
				Empty menu – use <span role="img" aria-label="add menu, section or item button">➕</span> to add sections and items.
			</p>
		}
		return menu.menuSections.map((section, ix) => {
			// if(ix) return null;
			// console.log(section);
			return (
				<Collapsible
					key={ section.menuSectionId }
					id={ section.menuSectionId }
					header={(
						<Fragment>
							<h1>{ section.title }</h1>
							<PaneItemButton icon="edit" index={ ix } onClick={ props.onSectionEdit }/>
						</Fragment>
					)}
					collapse={ section.collapsed }
					onCollapseToggle={ props.onCollapsibleToggle }
				>
					{ (section.menuItems && section.menuItems.length > 0) &&
						renderMenuItems(section)
					}
				</Collapsible>
			)
		});
	}

	const renderMenus = () => {
		if(!menus) return null;
		return menus.map(menu => {
			return (
				<PaneItem key={ menu.menuId } to={ '' + menu.menuId }>
					{ menu.title }
					<PaneItemButton index={ menu } icon="settings" onClick={ props.onMenuEdit }/>
				</PaneItem>
			);
		});
	}

	const label = menu?.title || 'Menus';
	return (
		<Pane id="menusPane" hasMenu>

			<PaneHeader>

				<PaneMenu
					disabled={ !menus || menus?.length === 0 }
					label={ label }
					loading={ props.menuStore.loading === actionTypes.fetchMenusRequest}
				>
					{ renderMenus() }
				</PaneMenu>

				<div className="Pane-toolbar">
					<IconButton title="search menu items" icon="search" onClick={ props.openSearch }/>
				</div>

			</PaneHeader>

			<PaneBody className="MenuItems scrolling">
				{ menus?.length === 0
					? <p className="Pane-message">Use <span role="img" aria-label="add menu, section or item button">➕</span> to add your first menu.</p>
					: renderMenu()
				}
			</PaneBody>

			<ItemPaneFooter shiftData={ shiftData } shiftElement={ props.shiftItem }>
				<IconButton title="add new item" icon="add" onClick={ props.onAddEditClick }/>
				<IconButton title="menus options" icon="more_horiz" onClick={ props.onMenusOptionsClick }/>
				<CollapserButton open={ collapsiblesOpen } disabled={ !menus || props.currentMenuIndex === null } onClick={ toggleCollapsibles }/>
			</ItemPaneFooter>

		</Pane>
	);
};

export default MenusPane;