import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import useL10n from 'L10n';
import utilities from 'services/utilities';
import { actionTypes, rewardsActionCreators } from 'store/rewards';
import { hasVenue } from 'store/venues';
import useForm from 'components/Form';
import { FormInput, FormRadio } from 'components/formControls';
import { IconButton } from 'components/buttons';
import { Pane, PaneBody, PaneHeader } from 'components/Pane';
import RouterPrompt from 'components/RouterPrompt';
import ValidationMessage from 'components/ValidationMessage';

const LoyaltyRewards = props => {
	const orderValueForCalc = 20;
	const rewardsStore = useSelector(state => state.rewards);
	const currencySymbol = useSelector(state => state.venues.venue?.currencySymbol);
	const venueId = useSelector(state => state.venues.venue?.venueUUID);
	const dispatch = useDispatch();
	const _t = useL10n().getText;

	useEffect(() => {
		if(venueId || hasVenue()) {
			dispatch(rewardsActionCreators.fetchLoyaltyRewardsSettings());
		}
	}, [venueId, dispatch]);

	useEffect(() => {
		// load form from store
		if(rewardsStore.loyaltyRewardsSettings) {
			formRef.current.reset(rewardsStore.loyaltyRewardsSettings);
		}
	}, [rewardsStore.loyaltyRewardsSettings]);

	const formSave = () => {
		const errors = {};
		let hasErrors = false;
		if(form.data.type) {
			const errText = _t('TTP_VALIDATION_MUST_BE_POSITIVE');
			if(parseInt(form.data.rewardTrigger, 10) <= 0) {
				errors.rewardTrigger = errText;
				hasErrors = true;
			};
			if(form.data.reward <= 0) {
				errors.reward = errText;
				hasErrors = true;
			}
			if(form.data.type === 'c' && form.data.orderMin <= 0) {
				errors.orderMin = errText;
				hasErrors = true;
			}
		}
		if(hasErrors) {
			form.setErrors(errors);
		} else {
			dispatch(rewardsActionCreators.updateLoyaltyRewardsSettings(form.data));
		}
	}

	const form = useForm(formSave);
	const formRef = useRef(form);

	const resetErrors = e => {
		// if we change the Loyalty Reward type, any previous errors displayed will be misleading so let's clear them
		form.setErrors();
	}

	const onSave = e => {
		let validationRules;
		switch(form.data.type) {
			case 'c': validationRules = { rewardTrigger: true }; break;
			case 'p': validationRules = { reward: true, rewardTrigger: true }; break;
			case 'm': validationRules = { reward: true, rewardTrigger: true }; break;
			default: break;
		}
		form.onSave(e, validationRules);
	}

	return (
		<div className="pb20">

			<Pane className="LoyaltyRewards animated fadeIn max-width-800">
				<PaneHeader className={ rewardsStore.loading === actionTypes.fetchLoyaltyRewardsSettingsRequest ? 'async': ''}>
					<h1>{ _t('HDG_LOYALTY_REWARDS', true) }</h1>
					<div className="Pane-toolbar">
						<IconButton
							title={ _t('TTP_SAVE') }
							icon="save"
							async={ rewardsStore.loading === actionTypes.updateLoyaltyRewardsSettingsRequest }
							onClick={ onSave }
						/>
					</div>
				</PaneHeader>
				<PaneBody className="container">

					<ValidationMessage open={ !!form.errors }/>

					<Pane>
						<PaneHeader>
							<FormRadio form={ form } field="type" value="" onClick={ resetErrors }>
								{ _t('HDG_NO_REWARDS', true) }
							</FormRadio>
						</PaneHeader>
						<PaneBody className="container">
							<p>{ _t('TXT_NO_REWARDS') }</p>
						</PaneBody>
					</Pane>

					<Pane>
						<PaneHeader>
							<FormRadio form={ form } field="type" value="c" onClick={ resetErrors }>
								{ _t('HDG_ORDERCOUNT_REWARDS', true) }
							</FormRadio>
							<ul>
								<li>{ _t('TXT_LOVE_FREE') }</li>
								<li>{ _t('TXT_SIMPLE') }</li>
							</ul>
						</PaneHeader>
						<PaneBody className={ classNames('container', { disabled: form.data.type !== 'c' })}>
							<div className="mb10">
								{ _t('LBL_NEXT_IS_FREE', false,
										<FormInput
											type="number"
											className="w80"
											disabled={ form.data.type !== 'c' }
											form={ form }
											field={ form.data.type === 'c' ? 'rewardTrigger' : '' }
										/>
								) }
							</div>
							<div className="mb10">
								{ _t('LBL_REWARDS_WHEN_OVER', false,
										<FormInput
											type="money"
											className="w80"
											disabled={ form.data.type !== 'c' }
											form={ form }
											field={ form.data.type === 'c' ? 'orderMin' : '' }
										/>
								) }
							</div>
							<div>
								{ _t('LBL_FREE_CAPPED', false,
										<FormInput
											type="money"
											className="w80"
											disabled={ form.data.type !== 'c' }
											form={ form }
											field={ form.data.type === 'c' ? 'reward' : '' }
										/>
								) }
							</div>
						</PaneBody>
					</Pane>

					<Pane>
						<PaneHeader>
							<FormRadio form={ form } field="type" value="p" onClick={ resetErrors }>
								{ _t('HDG_POINTBASED_REWARDS', true) }
							</FormRadio>
							<ul>
								<li>{ _t('TXT_PROPORTONAL_2_AMOUNT') }</li>
							</ul>
						</PaneHeader>
						<PaneBody className="container">
							<div className="mb10">
								{ _t('LBL_ACCUMULATE_POINTS', false,
										<FormInput
											type="number"
											className="w80"
											disabled={ form.data.type !== 'p' }
											form={ form }
											field={ form.data.type === 'p' ? 'rewardTrigger' : '' }
										/>,
										<FormInput
											type="money"
											className="w80"
											disabled={ form.data.type !== 'p' }
											form={ form }
											field={ form.data.type === 'p' ? 'reward' : '' }
										/>
								) }
							</div>
							<div>
								{ _t('LBL_POINTS_EARNED', false, currencySymbol) }
							</div>
						</PaneBody>
					</Pane>

					<Pane>
						<PaneHeader>
							<FormRadio form={ form } field="type" value="m" onClick={ resetErrors }>
								{ _t('HDG_MIXEDSTRATEGY_REWARDS', true) }
							</FormRadio>
							<ul>
								<li>{ _t('TXT_HIGHEST_INCENTIVE') }</li>
								<li>{ _t('TXT_PROPORTONAL_2_AMOUNT') }</li>
							</ul>
						</PaneHeader>
						<PaneBody className="container">
							<div className="mb10">
								{ _t('LBL_ACCUMULATE_DISCOUNT', false,
										<FormInput
											type="number"
											className="w80"
											disabled={ form.data.type !== 'm' }
											form={ form }
											field={ form.data.type === 'm' ? 'rewardTrigger' : '' }
										/>
								) }
							</div>
							<div className="mb10">
								{ _t('LBL_DISCOUNT_WITH_SPEND', false, currencySymbol,
									<FormInput
										type="money"
										className="w80"
										disabled={ form.data.type !== 'm' }
										form={ form }
										field={ form.data.type === 'm' ? 'reward' : '' }
									/>
								) }
							</div>
							<div>
								{ _t('LBL_DISCOUNT_EXAMPLE', false, currencySymbol, orderValueForCalc,
									form.data.type === 'm' && form.data.reward
										? utilities.formatPrice(orderValueForCalc * 10000 / form.data.reward, currencySymbol)
										: '…'
								) }
							</div>
						</PaneBody>
					</Pane>

				</PaneBody>
			</Pane>

			<RouterPrompt when={ form.isDirty }/>

		</div>
	);

}

export default LoyaltyRewards;