import storeUtils from './storeUtils';

export const actionTypes = {
	stripeFail: 'STRIPE_FAIL',

	generateRestaurantOnboardingLinkRequest: 'GENERATE_RESTAURANTONBOARDINGLINK_STRIPE_REQUEST',
	generateRestaurantOnboardingLinkSuccess: 'GENERATE_RESTAURANTONBOARDINGLINK_STRIPE_SUCCESS',

	emailRestaurantOnboardingLinkRequest: 'EMAIL_RESTAURANTONBOARDINGLINK_STRIPE_REQUEST',
	emailRestaurantOnboardingLinkSuccess: 'EMAIL_RESTAURANTONBOARDINGLINK_STRIPE_SUCCESS'
}

const initialState = { restaurantOnboardingLink: null, loading: null, errors: null };

export const stripeActionCreators = {
	generateRestaurantOnboardingLink: () => async dispatch => {
		dispatch({ type: actionTypes.generateRestaurantOnboardingLinkRequest });
		const data = await storeUtils.execute('stripe', 'generateRestaurantOnboardingLink');
		if(data.errors) {
			dispatch({ type: actionTypes.stripeFail, errors: data.errors });
		} else {
			dispatch({ type: actionTypes.generateRestaurantOnboardingLinkSuccess, restaurantOnboardingLink: data.link });
		}
	},

	emailRestaurantOnboardingLink: email => async dispatch => {
		dispatch({ type: actionTypes.emailRestaurantOnboardingLinkRequest });
		const data = await storeUtils.execute('other', 'emailRestaurantOnboardingLink', { email: email });
		if(data.errors) {
			dispatch({ type: actionTypes.stripeFail, errors: data.errors });
		}
	}
};

export const reducer = (state = initialState, action) => {

	if(action.type.endsWith('STRIPE_REQUEST')) {
		return {
			...state,
			loading: action.type,
			errors: null
		}
	}

	switch(action.type) {
		case actionTypes.stripeFail: {
			return {
				...state,
				loading: null,
				errors: action.errors
			}
		}

		case actionTypes.generateRestaurantOnboardingLinkSuccess: {
			return {
				...state,
				loading: null,
				restaurantOnboardingLink: action.restaurantOnboardingLink
			}
		}

		default: {
			return state;
		}
	}
}