import { Fragment, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { menusActionCreators } from 'store/menus';
import { modifiersActionCreators } from 'store/modifiers';
import { hasVenue } from 'store/venues';
import Content from 'layout/Content';
import { IconButton } from 'components/buttons';
import MenusContainer from './Menus';
import ModifiersContainer from './Modifiers';
import ModifierGroupsContainer from './ModifierGroups';
import Settings from './Settings';

const ProductsContainer = props => {

	const history = useHistory();
	const dispatch = useDispatch();
	const menuStore = useSelector(state => state.menus);
	const venueStore = useSelector(state => state.venues);

	useEffect(() => {
		if((venueStore.venue || hasVenue()) && !menuStore.menus && !menuStore.loading) {
			// venue has changed so we need to fetch new venue data...
			// NOTE there is an edge case where this could prompt the user to sign in 2ce - imagine there's an invalid token, then both these will return 481s
			// prompting re-logins - imagine the 2nd request gets delayed until after the user has signed in due to the 1st - they'll be prompted again
			// however the advantages of firing asynchronously outweigh this concern
			dispatch(menusActionCreators.fetchMenus());
			dispatch(modifiersActionCreators.fetchModifiers());
			history.push('./');
		}
	}, [venueStore.venue, menuStore, dispatch, history]);

	const togglePosConsole = e => {
		const rect = e.currentTarget.getBoundingClientRect();
		props.togglePosConsole(e, rect.y);
	}

	const nav = [
		{ path: 'menus', label: 'Menus', component: MenusContainer },
		{ path: 'modifiers', label: 'Modifiers', component: ModifiersContainer },
		{ path: 'modifier-groups', label: 'Modifier Groups', component: ModifierGroupsContainer },
		{ path: 'settings', component: Settings, label: <Fragment>
				<span className="material-icons">settings</span>
				<span>Settings</span>
			</Fragment>
		}
	];

	return (
			<Content path="/venues/products/" nav={ nav } navRight={ props.showPosConsoleButton === true &&
				<IconButton title="show POS console" icon="assignment" onClick={ togglePosConsole }/>
			}/>
	);

	
};

export default ProductsContainer;