import classNames from 'classnames';
import PriceOptions from 'Products/Menus/PriceOptions';
import { FormCheckbox, FormInput, FormInputWithButton } from 'components/formControls';

const MenuItemForm = props => {

	return (
		<div className="simple-form">

			<FormInput placeholder="Description" type="textarea" className={ classNames({ 'smaller': props.onSalesTaxButtonClick })} form={ props.form } field="dscr" disabled={ props.disabled }/>

			{ props.onSalesTaxButtonClick &&
				<FormInputWithButton
					placeholder="Sales Tax Code"
					icon="more_horiz"
					form={ props.form }
					field="salesTaxCode"
					disabled={ props.disabled }
					onButtonClick={ props.onSalesTaxButtonClick }
				/>
			}

			<div className="controls-stacked item-state">
				<div className="controls-stacked_row">
					<FormCheckbox className="form-control" form={ props.form } field="isOutOfStock" disabled={ !props.form.data.menuItemId }>Out of stock</FormCheckbox>
					<FormCheckbox className="form-control" form={ props.form } field="isHidden" disabled={ !props.form.data.menuItemId }>Hidden</FormCheckbox>
				</div>
			</div>

			<PriceOptions
				name="onPromo"
				form={ props.form }
				which={ props.form.data.onPromo }
				disabled={ props.disabled }
				config={ [
					{ label: 'Price', field: 'price', value: 0, price: props.form.data.price },
					{ label: 'On promo', field: 'promoPrice', value: 1, price: props.form.data.promoPrice }
				] }
				stacked={ !props.dialog }
			/>

		</div>
	);

};

export default MenuItemForm;