import utilities from "services/utilities";
import { PaneItem } from "components/Pane";
import './MenuItem.scss';

const MenuItem = ({ className, item, menuId, currencySymbol }) => {
	return (
		<PaneItem className={ className } to={ menuId + ':' + item.menuItemId }>
			{/* <div>{ item.menuItemId }</div> */}
			<div>{ item.name }</div>
			{ !!item.isHidden &&
				<div><span className="Pane-item-tag">HIDDEN</span></div>
			}
			{ !item.isHidden && !item.isInStock &&
				<div><span className="Pane-item-tag Pane-item-tag_primary">OUT OF STOCK</span></div>
			}
			<div>{ utilities.formatPrice(item.onPromo && item.promoPrice ? item.promoPrice : item.price, currencySymbol) }</div>
		</PaneItem>
	);
};

export default MenuItem;