import { useEffect, useState } from 'react';
import { rewardsActionCreators } from 'store/rewards';
import { useDispatch, useSelector } from 'react-redux';
import utilities from 'services/utilities';
import { hasVenue } from 'store/venues';
import Paginator from 'components/Paginator';
import { Pane, PaneHeader, PaneBody, PaneFooter } from 'components/Pane';
import PhotoViewer from 'components/PhotoViewer';
import { Row, Cell, Col } from 'components/Table';
import ValidationMessage from 'components/ValidationMessage';

const Customers = props => {
	const [showPhoto, setShowPhoto] = useState();
	const [page, setPage] = useState(1);
	const rewardsStore = useSelector(state => state.rewards);
	const loyaltyStats = rewardsStore.customerLoyaltyStats;
	const usersPoints = loyaltyStats?.usersPoints;
	const rewardsSettings = loyaltyStats?.rewardsSettings;
	const currencySymbol = useSelector(state => state.venues.venue?.currencySymbol);
	const dispatch = useDispatch();
	const pagingSize = 3;

	const venueId = useSelector(state => state.venues.venue?.venueUUID);

	useEffect(() => {
		if(venueId || hasVenue()) {
			dispatch(rewardsActionCreators.fetchCustomerLoyaltyStats({
				pagingOffset: 0,
				pagingSize: pagingSize
			}));
		}
	}, [venueId, dispatch]);

	const getRewardsCycleOrders = user => {
		switch(rewardsSettings.type) {
			case 'c':
			case 'm': return user.orderCount + ' / ' + rewardsSettings.rewardTrigger;
			default: return user.orderCount;
		}
	}

	const getRewardsCycleValue = user => {
		switch(rewardsSettings.type) {
			case 'p': return utilities.formatPrice(user.points, currencySymbol) + ' / ' + utilities.formatPrice(rewardsSettings.rewardTrigger, currencySymbol);
			default: return utilities.formatPrice(user.points, currencySymbol);
		}
	}

	const getMode = () => {
		switch(rewardsSettings?.type) {
			case 'c': return utilities.getText('LBL_ORDERCOUNT_REWARDS');
			case 'm': return utilities.getText('LBL_MIXEDSTRATEGY_REWARDS');
			case 'p': return utilities.getText('LBL_POINTBASED_REWARDS');
			default: return '';
		}
	}

	const getTrigger = () => {
		switch(rewardsSettings?.type) {
			case 'c':
			case 'm': return rewardsSettings.rewardTrigger + ' orders';
			case 'p': return utilities.formatPrice(rewardsSettings.rewardTrigger, currencySymbol);
			default: return '';
		}
	}

	const fetchPage = dir => {
		const newPage = page + dir;
		setPage(newPage);
		dispatch(rewardsActionCreators.fetchCustomerLoyaltyStats({
			pagingOffset: (newPage - 1) * pagingSize,
			pagingSize: pagingSize
		}));
	}

	const renderBody = () => {
		return usersPoints.map((user, ix) => {
			return <Row key={ ix }>
				<Cell w="5%" className="pv0">{
					user.photo
						? <span className="material-icons" onClick={ () => setShowPhoto(user.photo) }>photo_camera</span>
						: ''
				}</Cell>
				<Cell w="25%">{ user.firstName + (user.lastName ? ' ' + user.lastName : '') }</Cell>
				<Cell w="14%" className="divider-left">{ getRewardsCycleOrders(user) }</Cell>
				<Cell w="14%">{ getRewardsCycleValue(user) }</Cell>
				<Cell w="14%" className="divider-left">{ user.rank }</Cell>
				<Cell w="14%">{ user.allTimeOrderCount }</Cell>
				<Cell w="14%">{ utilities.formatPrice(user.allTimePoints, currencySymbol) }</Cell>
			</Row>
		});
	}

	return (
		<div className="pb20">

			<Pane id="customersReport" className="Pane-paginated animated fadeIn" role="table">
				<PaneHeader className={ rewardsStore.loading === 'FETCH_CUSTOMERLOYALTYSTATS_REWARDS_REQUEST' ? 'async' : '' }>
					<Col w="30%">Customer</Col><Col w="28%">Rewards</Col><Col>ALL TIME</Col>
				</PaneHeader>
				{ usersPoints?.length > 0
						?
							<PaneBody>
								<Row heading={ true }>
									<Col w="5%">Photo</Col>
									<Col w="25%">Name</Col>
									<Col w="14%" className="divider-left"># Orders</Col>
									<Col w="14%">Value</Col>
									<Col w="14%" className="divider-left">Rank</Col>
									<Col w="14%"># Orders</Col>
									<Col w="14%">Value</Col>
								</Row>
								{ renderBody() }
							</PaneBody>
						: 
							<PaneBody className="container">
								<ValidationMessage className="mb0" open={ true } message={ utilities.getText('TXT_NO_DATA', 'customer') }/>
							</PaneBody>
				}
				<PaneFooter className="toolbar">

					<div className="mode-info">
						Rewards mode: <strong>{ getMode() }</strong>
						<br/>
						Rewards trigger: <strong>{ getTrigger() }</strong>
					</div>

					<Paginator
						page={ page }
						hasNextPage={ loyaltyStats?.hasNextPage }
						fetchPage={ fetchPage }
					/>

				</PaneFooter>
			</Pane>

			<PhotoViewer show={ !!showPhoto } photoURL={ showPhoto } closeHandler={ () => setShowPhoto() }/>

		</div>
	);

}

export default Customers;