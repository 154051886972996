import { useEffect, useRef, useState } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { modifiersActionCreators } from 'store/modifiers';
import utilities from 'services/utilities';
import useForm from 'components/Form';
import Modifiers from './Modifiers';
import TaxCodeDialog from 'components/TaxCodeDialog';
import RouterPrompt from 'components/RouterPrompt';

const ModifiersContainer = props => {

	let { id } = useParams();
	id = id ? parseInt(id, 10) : null;
	const dispatch = useDispatch();
	const [salesTaxDialogShow, setSalesTaxDialogShow] = useState(false);
	const modifierStore = useSelector(state => state.modifiers);
	const venueStore = useSelector(state => state.venues);
	const taxCodeStore = useSelector(state => state.taxCodes);

	const formSave = () => {
		console.log('formSave', form.data);
		if(id) {
			dispatch(modifiersActionCreators.updateItem(form.data));
		} else {
			form.data.orderIndex = utilities.getNextOrderIndex(modifierStore.items);
			dispatch(modifiersActionCreators.addItem(form.data));
		}
	}

	const validationRules = {
		retailName: true,
		kitchenName: true
	}

	const form = useForm(formSave, validationRules, new ModifierItem());
	const resetRef = useRef(form.reset);

	// I am not sure exactly why I try loading the data on every page load - disabling for now - see also ModifierGroupsContainer
	// useEffect(() => {
	// 	// fetch data
	// 	dispatch(modifiersActionCreators.fetchModifiers());
	// }, [dispatch]);

	useEffect(() => {
		// handle current modifier changes
		if(id) {
			if(modifierStore.items && id) {
				const itemFromState = modifierStore.items.find(item => item.id === id);
				resetRef.current({ ...itemFromState }, false);	// if current item has just been deleted this will reset with {} and cause a redirect later in render
			}
		} else {
			resetRef.current(new ModifierItem(), false);
		}
	}, [modifierStore.items, id]);
	
	const onItemDelete = item => {
		dispatch(modifiersActionCreators.deleteItem(item));
	}

	const onAddClick = e => {
		// TODO check form is dirty first
		id = null;
		form.reset(new ModifierItem());
	}

	const onSalesTaxButtonClick = e => {
		setSalesTaxDialogShow(true);
	}

	const salesTaxDialogHide = e => {
		setSalesTaxDialogShow(false);
	}

	const onSalesTaxCodeSelect = code => {
		salesTaxDialogHide();
		form.onChange(null, 'salesTaxCode', code);
	}

	const shiftItem = isUp => {
		dispatch(modifiersActionCreators.shiftItem(form.data.id, isUp));
	}

	if(id && typeof form.data.id === 'undefined') {
		// looks like item has just been deleted so...
		return <Redirect to="./"/>
	} else {
		return (
			<div>

				<Modifiers
					form={ form }
					modifierStore={ modifierStore }
					currencySymbol={ venueStore.venue?.currencySymbol }
					onAddClick={ onAddClick }
					onSalesTaxButtonClick={ !venueStore.venue?.menuPricesIncludeVAT ? onSalesTaxButtonClick : null }	// no need to pass the flag too - the presence of the handler does this for us
					onItemSave={ form.onSave }
					onItemDelete={ onItemDelete }
					shiftItem={ shiftItem }
				/>

				<TaxCodeDialog
					codes={ taxCodeStore.data }
					show={ salesTaxDialogShow }
					onHide={ salesTaxDialogHide }
					onSalesTaxCodeSelect={ onSalesTaxCodeSelect }
				/>

				<RouterPrompt when={ form.isDirty }/>

			</div>

		);
	}
}

export default ModifiersContainer;

class ModifierItem {
	id = null;
	posId = null;
	omnivoreId = null;
	retailName = null;
	kitchenName = null;
	orderIndex = null;
	price = null;
	salesTaxCode = null;
};