import { useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import utilities from 'services/utilities';
import { actionTypes } from 'store/modifiers';
import ConfirmDialog from 'dialogs/ConfirmDialog';
import { IconButton } from 'components/buttons';
import { ItemPaneFooter, Pane, PaneBody, PaneHeader, PaneItem, PaneItemButton } from 'components/Pane';
import { FormInput, FormInputWithButton } from 'components/formControls';
import ValidationMessage from 'components/ValidationMessage';
import './Modifiers.scss';

const Modifiers = props => {

	let shiftData;
	const firstInput = useRef();
	const [itemDeleteDialogShow, setItemDeleteDialogShow] = useState(false);
	const menuPricesIncludeVAT = useSelector(state => state.venues.venue?.menuPricesIncludeVAT);

	const getModifierGroup = (id) => {
		return props.modifierStore.groups.find(group => {
			return group.id === id;
		});
	}

	const onAddClick = e => {
		props.onAddClick();
		const input = firstInput.current.childNodes[0];
		input.focus();
	}


	const onItemDelete = () => {
		setItemDeleteDialogShow(true);
	}

	const confirmDialogHide = response => {
		if(response.button === 'btnOk') props.onItemDelete(props.form.data);
		setItemDeleteDialogShow(false);
	}

	const renderItems = () => {
		if(!props.modifierStore.items) return null;
		const items = props.modifierStore.items;
		return items?.map((item, ix) => {
			if(item.id === props.form.data.id) {
				// this is the current item, so store data needed for disabling shift buttons
				shiftData = [items.length - 1, ix];
			}
			return (
				<li key={ item.id }>
					<NavLink className="Pane-item" to={ '' + item.id }>
						<div className="col-6">{ item.retailName }</div>
						<div className="col-4">{ item.kitchenName }</div>
						<div className="col-2 text-right">{ utilities.formatPrice(item.price, props.currencySymbol) }</div>
					</NavLink>
				</li>
			);
		});
	}

	const renderModifierGroups = () => {
		if(!props.form.data.id) return null;
		return props.form.data.modifierGroupIds.map((groupId, ix) => {
			const group = getModifierGroup(groupId);
			return (
				<PaneItem key={ ix } index={ ix }>
					<div>{ group.name }</div>
					<PaneItemButton to={ '../modifier-groups/' + groupId } index="0" icon="edit"/>
				</PaneItem>
			);
		});
	}

	const headerClassName = classNames('Pane-header_headings', {async: props.modifierStore.loading === actionTypes.fetchRequest });
	return (
		<div className="row gutters-5 gutters-xlu-15 animated fadeIn">

		<div className="col-lg-5">
			<Pane id="modifiersPane">

				<PaneHeader className={ headerClassName }>
					<div className="col-6">Modifier</div>
					<div className="col-4">Kitchen Name</div>
					<div className="col-2 text-right">Price</div>
				</PaneHeader>

				<PaneBody tag="ul" className="scrolling Pane-items Pane-items_p0">
					{ renderItems() }
				</PaneBody>

				<ItemPaneFooter shiftData={ shiftData } shiftElement={ props.shiftItem }>
					<IconButton to="./" title="add new item" icon="add" onClick={ onAddClick }/>
				</ItemPaneFooter>

			</Pane>
		</div>

		<div className="col-lg-7">
			<Pane>

				<PaneHeader>
					<h1>{ props.form.data.retailName || 'New modifier item' }</h1>
					<div className="Pane-toolbar">
						<IconButton
							async={ props.modifierStore.loading === actionTypes.deleteItemRequest }
							title="delete modifier item"
							disabled={ !props.form.data.id }
							icon="delete"
							onClick={ onItemDelete }
						/>
						<IconButton
							async={ props.modifierStore.loading === actionTypes.updateItemRequest }
							title="save modifier item"
							icon="save"
							onClick={ props.onItemSave }
						/>
					</div>
				</PaneHeader>

				<PaneBody className="container">
					<ValidationMessage open={ !!props.errors }/>

					<div className="form-row">
						<div className="col" ref={ firstInput }>
							<FormInput placeholder="Retail Description" form={ props.form } field="retailName"/>
						</div>
						<div className="col-4">
							<FormInput type="money" placeholder="Price" form={ props.form } field="price"/>
						</div>
					</div>
					<div className="form-row">
						<div className={ menuPricesIncludeVAT ? 'col' : 'col-7'}>
							<FormInput placeholder="Kitchen Description" form={ props.form } field="kitchenName"/>
						</div>
						{ !menuPricesIncludeVAT &&
							<div className="col-5">
								<FormInputWithButton
									placeholder="Sales Tax Code"
									icon="more_horiz"
									form={ props.form }
									field="salesTaxCode"
									disabled={ props.disabled }
									onButtonClick={ props.onSalesTaxButtonClick }
								/>
							</div>
						}
					</div>
					<div className="form-row">
						<div className="col-6">
							<FormInput placeholder="Omnivore ID" form={ props.form } field="omnivoreId"/>
						</div>
						<div className="col-6">
							<FormInput placeholder="POS ID" form={ props.form } field="posId"/>
						</div>
					</div>

					<div className="form-row">
						<div className="col">
							<Pane id="usedInGroups">
								<PaneHeader>Used in modifier groups</PaneHeader>
								<PaneBody tag="ul" className="Pane-items Pane-items_p0 scrolling">
									{ renderModifierGroups() }
								</PaneBody>
							</Pane>
						</div>
					</div>

				</PaneBody>

			</Pane>
		</div>

		<ConfirmDialog
			action="Delete Modifier Item"
			message="Are you sure you want to delete this modifier item?"
			show={ itemDeleteDialogShow }
			onHide={ confirmDialogHide }
		/>

	</div>
	);

}

export default Modifiers;