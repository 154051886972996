import React, { useRef, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import classNames from 'classnames';
import './Pane.scss';
import { IconButton } from './buttons';
import { useEffect } from 'react';

let role;

export const Pane = ({ hasMenu, children, className, ...props }) => {
	role = props.role;
	const classes = classNames('Pane', className, { clipped: hasMenu });
	return (
		<section className={ classes } { ...props }>	{ /* passing props supports Draggable etc */ }
			{ hasMenu && <div className="Pane-shadowMask"/> }
			{ children }
		</section>
	);

};

export const PaneHeader = ({ children, className, ...props }) => {
	const classes = classNames('Pane-header', className);
	return (
		<header className={ classes } { ...props } role={ role === 'table' ? 'row' : undefined }>
			{ typeof children === 'string'
				? <h1>{ children }</h1>
				: children
			}
		</header>
	);
}

export const PaneBody = ({ children, className, tag, ...props }) => {
	const classes = classNames('Pane-body', className);
	const Tag = tag || 'div';
	return (
		<Tag className={ classes } { ...props } role={ role === 'table' ? 'rowgroup' : undefined }>
			{ children }
		</Tag>
	);
}

export const PaneFooter = ({ children, className, ...props }) => {
	const classes = classNames('Pane-footer', className);
	return (
		<footer className={ classes } { ...props }>
			{ typeof children === 'string'
				? <h1>{ children }</h1>
				: children
			}
		</footer>
	);
}

// export const PaneMenu_prev = props => {

// 	const [open, setOpen] = useState(props.open);

// 	const node = useRef();

// 	const clickHandler = e => {
// 		// close the menu if an item or itemButton is selected
// 		e.stopPropagation();
// 		if(open) {
// 			toggle();
// 		}
// 	}

// 	const toggle = () => {
// 		// the following is a little naughty (manipulating the dom a little), but otherwise,
// 		// open state can't be maintained here if not "allowed" to add the menusPane shadow-mask to the outer pane
// 		let pane = node.current.parentNode.parentNode;
// 		if(pane.classList.contains('Pane')) {
// 			if(open) {
// 				pane.classList.remove('menu-open');
// 			} else {
// 				pane.classList.add('menu-open');
// 			}
// 		}
// 		setOpen(!open);
// 	}

// 	useEffect(() => {
// 		// if, say, venue changes with this open, we'll need to shut it
// 		if(props.loading && open) toggle();
// 		// eslint-disable-next-line react-hooks/exhaustive-deps
// 	}, [props.loading, open]);

// 	useEffect(() => {
// 		// if menu is open and we delete the last option, we want to close it
// 		if(props.children?.length === 0 && open) toggle();
// 		// eslint-disable-next-line react-hooks/exhaustive-deps
// 	}, [props.children, open]);

// 	return (
// 		<div ref={ node } className={ classNames('Pane-menu', { 'open': open }) }>
// 			<h1 className={ classNames({ async: props.loading }) }>{ props.label }</h1>
// 			<button type="button" disabled={ props.loading || props.disabled } className="btn btn-dropper" onClick={ toggle }>
// 				<span className="material-icons">{ open ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }</span>
// 			</button>
// 			<ul className="Pane-items" onClick={ clickHandler }>
// 				{ props.children }
// 			</ul>
// 		</div>
// 	);

// }

export const PaneMenu = props => {

	const [open, setOpen] = useState(props.open);

	const node = useRef();

	const clickHandler = e => {
		// close the menu if an item or itemButton is selected
		e.stopPropagation();
		if(open) {
			toggle();
		}
	}

	const toggle = () => {
		// the following is a little naughty (manipulating the dom a little), but otherwise,
		// open state can't be maintained here if not "allowed" to add the menusPane shadow-mask to the outer pane
		let pane = node.current.parentNode.parentNode;
		if(pane.classList.contains('Pane')) {
			if(open) {
				pane.classList.remove('menu-open');
			} else {
				pane.classList.add('menu-open');
			}
		}
		setOpen(!open);
	}

	useEffect(() => {
		// if, say, venue changes with this open, we'll need to shut it
		if(props.loading && open) toggle();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.loading, open]);

	useEffect(() => {
		// if menu is open and we delete the last option, we want to close it
		if(props.children?.length === 0 && open) toggle();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.children, open]);

	return (
		<div ref={ node } className={ classNames('Pane-menu', { 'open': open }) }>
			<button type="button" disabled={ props.loading || props.disabled } className={ classNames('Pane-menu_control', { async: props.loading }) } onClick={ toggle }>
				{ props.label }
				<span className="material-icons">{ open ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }</span>
			</button>
			<ul className="Pane-items" onClick={ clickHandler }>
				{ props.children }
			</ul>
		</div>
	);

}

export const PaneItem = ({ children, className, index, onClick, selected, to, ...props}) => {

	const classes = classNames('Pane-item', className);

	const clickHandler = e => {
		// e.preventDefault(); // not sure why this is here - stops MODIFIERS > ITEMS > default from working
		if(onClick) onClick(index, e);
	}

	if(to) {
		return (
			<li>
				<NavLink to={ to } className={ classes } { ...props }>
					{ children }
				</NavLink>
			</li>
		);
	
	} else {
		return (
			<li className={ classNames(classes, { 'active': selected }) } onClick={ clickHandler } { ...props }>
				{ children }
			</li>
		);
	}

}

export const PaneItemButton = props => {

	const classes = classNames('Pane-item-btn', { 'Pane-item-btn_floated': props.floated !== false }, props.className);

	const clickHandler = e => {
		e.preventDefault();
		if(!props.propagate) e.stopPropagation();
		if(props.onClick) {
			props.onClick(props.index, e);
		}
	}

	if(props.to) {
		return (
			<Link className={ classes } to={ props.to } title={ props.title }>
				{ props.children
					? props.children
					: <span className="material-icons">{ props.icon }</span>
				}
			</Link>
		);
	} else {
		return (
			<button type="button" className={ classes } disabled={ props.disabled } title={ props.title } onClick={ clickHandler }>
				{ props.children
					? props.children
					: <span className="material-icons">{ props.icon }</span>
				}
			</button>
		);
	}

}

export const ItemPaneFooter = props => {
	const upIcon = props.sideways ? 'arrow_back' : 'arrow_upward';
	const downIcon = props.sideways ? 'arrow_forward' : 'arrow_downward';

	const shiftElement = e => {
		const isUp = e.currentTarget.innerText === upIcon;
		props.shiftElement(isUp);
	}

	return (
		<footer className="Pane-footer toolbar">
			{ props.children }
			<IconButton
				className={ props.loading ? 'async' : '' }
				disabled={ props.loading || !props.shiftData || props.shiftData[0] === props.shiftData[1] }
				title="shift selection down"
				icon={ downIcon }
				onClick={ shiftElement }
			/>
			<IconButton
				className={ props.loading ? 'async' : '' }
				disabled={ props.loading || !props.shiftData || props.shiftData[1] === 0 }
				title="shift selection up"
				icon={ upIcon }
				onClick={ shiftElement }
			/>
		</footer>
	);
}