import useL10n from 'L10n';
import Notification from 'components/Notification';
import ConfirmDialog from './ConfirmDialog';

const LostConnectionDialog = props => {

	const _t = useL10n().getText;

	const hideConfirmReconnectDialogHandler = response => {
		if(response.button === 'btnOk') {
			// user confirms they want to attempt to reconnect to SSE service
			props.reset();
		}
		props.onHide();
	}

	return (
		<ConfirmDialog
			heading={ _t('HDG_LOST_CONNECTION', true) }
			message={ <Notification type="warning">{ _t('TXT_LOST_CONNECTION') }</Notification> }
			okText={ _t('BTN_RECONNECT', true) }
			show={ props.show }
			onHide={ hideConfirmReconnectDialogHandler }
		/>
	);
};

export default LostConnectionDialog;