// eslint-disable-next-line no-unused-vars
import findLastIndex from 'core-js/actual/array/find-last-index';
import differenceInMinutes from 'date-fns/differenceInMinutes';
import storeUtils from './storeUtils';

export const actionTypes = {
	ordersFail: 'ORDERS_FAIL',

	fetchOrdersRequest: 'FETCH_ORDERS_ORDERS_REQUEST',
	fetchOrdersSuccess: 'FETCH_ORDERS_ORDERS_SUCCESS',
	setOrdersSuccess: 'SET_ORDERS_ORDERS_SUCCESS',

	fetchOrderBalancesRequest: 'FETCH_ORDERBALANCES_ORDERS_REQUEST',
	fetchOrderBalancesSuccess: 'FETCH_ORDERBALANCES_ORDERS_SUCCESS',
	clearOrderBalances: 'CLEAR_ORDERBALANCES_ORDERS',

	updateOrderDistributionTypesRequest: 'UPDATE_ORDERDISTRIBUTIONTYPES_ORDERS_REQUEST',
	updateOrderDistributionTypesSuccess: 'UPDATE_ORDERDISTRIBUTIONTYPES_ORDERS_SUCCESS',

	updateOrderFlowRequest: 'UPDATE_ORDERFLOW_ORDERS_REQUEST',
	updateOrderFlowSuccess: 'UPDATE_ORDERFLOW_ORDERS_SUCCESS',

	cancelOrderRequest: 'CANCEL_ORDER_ORDERS_REQUEST',
	cancelOrderSuccess: 'CANCEL_ORDER_ORDERS_SUCCESS',

	updateOrderRequest: 'UPDATE_ORDER_ORDERS_REQUEST',
	updateOrderSuccess: 'UPDATE_ORDER_ORDERS_SUCCESS',
	
	bumpOrdersRequest: 'BUMP_ORDERS_ORDERS_REQUEST',
	bumpOrdersSuccess: 'BUMP_ORDERS_ORDERS_SUCCESS',

	undoBumpOrdersRequest: 'UNDO_BUMP_ORDERS_ORDERS_REQUEST',
	undoBumpOrderSuccess: 'UNDO_BUMP_ORDERS_ORDERS_SUCCESS'
};

const initialState = {
	orderData: null,
	orderBalances: null,
	orderDistributionTypes: null,
	status: null,
	loading: null,
	errors: null
};

export const ordersActionCreators = {

	fetchOrders: (payload, orderGroupingMinutes) => async dispatch => {
		dispatch({ type: actionTypes.fetchOrdersRequest });
		const data = await storeUtils.execute('orders', 'getOrdersByStatus', payload);
		if(data.errors) {
			dispatch({ type: actionTypes.ordersFail, errors: data.errors });
		} else {
			dispatch({ type: actionTypes.fetchOrdersSuccess, orderData: data, orderGroupingMinutes: orderGroupingMinutes, station: null, pagingOffset: payload.pagingOffset });
		}
	},
	
	setOrders: (payload, orderGroupingMinutes, station, pageSize) => {
		return { type: actionTypes.setOrdersSuccess, orderData: payload, orderGroupingMinutes: orderGroupingMinutes, station: station, pageSize: pageSize };
	},

	fetchOrderBalances: payload => async dispatch => {
		dispatch({ type: actionTypes.fetchOrderBalancesRequest });
		const response = await storeUtils.execute('orders', 'getOrderBalances', payload);
		if(response.errors) {
			dispatch({ type: actionTypes.ordersFail, errors: response.errors });
		} else {
			dispatch({ type: actionTypes.fetchOrderBalancesSuccess, response: response });
		}
	},

	clearOrderBalances: () => {
		return { type: actionTypes.clearOrderBalances }
	},

	updateOrderDistributionTypes: orderDistributionTypes => async dispatch => {
		dispatch({ type: actionTypes.updateOrderDistributionTypesRequest });
		const data = await storeUtils.execute('orders', 'updateOrderDistributionTypes', orderDistributionTypes);
		if(data.errors) {
			dispatch({ type: actionTypes.ordersFail, errors: data.errors });
		} else {
			dispatch({ type: actionTypes.updateOrderDistributionTypesSuccess, orderDistributionTypes: orderDistributionTypes });
		}
	},

	updateOrderFlow: usesStatus_ReadyForService => async dispatch => {
		dispatch({ type: actionTypes.updateOrderFlowRequest });
		const response = await storeUtils.execute('orders', 'updateOrderFlow', { usesStatus_ReadyForService: usesStatus_ReadyForService });
		if(response.errors) {
			dispatch({ type: actionTypes.ordersFail, errors: response.errors });
		} else {
			response.usesStatus_ReadyForService = usesStatus_ReadyForService;
			dispatch({ type: actionTypes.updateOrderFlowSuccess, response: response });
		}
	},

	cancelOrder: order => async dispatch => {
		dispatch({ type: actionTypes.cancelOrderRequest });
		const data = await storeUtils.execute('orders', 'cancelOrder', order);
		if(data.errors) {
			dispatch({ type: actionTypes.ordersFail, errors: data.errors });
		} else {
			dispatch({ type: actionTypes.cancelOrderSuccess, order: order });
		}
	},
	
	updateOrder: (order, newItems, options) => async dispatch => {
		// console.log(order, newItems, options);
		const payload = {
			externalOrderId: order.externalOrderId,
			cancellationReason: options.outOfStock ? 's' : 'm',
			cancellationNotes: options.cancellationNotes
		};
		const cancelledItems = order.orderItems.filter(item => item.delete).map(item => {
			return item.orderItemId
		});
		if(cancelledItems.length) payload.cancelledItems = cancelledItems;
		if(newItems.length) {
			payload.newItems = newItems.map(itemHolder => {
				const newItem = {
					qty: itemHolder.qty,
					menuId: itemHolder.item.menuId,
					menuItemId: itemHolder.item.menuItemId,
					modifierItems: itemHolder.item.modifierItems || []	// we don't need all the modifierItems data, but the api will ignore the stuff it doesn't need so not worth stripping out
				}
				return newItem;
			});
		}
		// console.log(payload);
		dispatch({ type: actionTypes.updateOrderRequest });
		const data = await storeUtils.execute('orders', 'cancelOrder', payload);
		if(data.errors) {
			dispatch({ type: actionTypes.ordersFail, errors: data.errors });
		} else {
			dispatch({ type: actionTypes.updateOrderSuccess, order: order });
		}
	},

	bumpOrders: (index, kitchenDisplayType) => async (dispatch, getState) => {
		// first we need to identify all orders in the same "group"...
		const orders = getState().orders.orderData.orders;
		const group = [];
		let order = orders[index];
		group.push(order.externalOrderId);
		if(order.grouped) {
			const clickedOrder = order;
			for(let i = index - 1; i >= 0; i--) {
				order = orders[i];
				if(order.waitstaffTarget && order.waitstaffTarget === clickedOrder.waitstaffTarget) {
					group.push(order.externalOrderId);
					} else {
					break;
				}
			}
		}
		dispatch({ type: actionTypes.bumpOrdersRequest });
		const data = await storeUtils.execute('orders', 'bumpOrders', {
			externalOrderIds: group,
			kitchenDisplayType: kitchenDisplayType
		});
		if(data.errors) {
			dispatch({ type: actionTypes.ordersFail, errors: data.errors });
		} else {
			dispatch({ type: actionTypes.bumpOrdersSuccess });
		}
	},
	
	undoBumpOrders: kitchenDisplayType => async dispatch => {
		dispatch({ type: actionTypes.undoBumpOrdersRequest });
		const data = await storeUtils.execute('orders', 'undoOrdersBump', { kitchenDisplayType: kitchenDisplayType });
		if(data.errors) {
			dispatch({ type: actionTypes.ordersFail, errors: data.errors });
		} else {
			dispatch({ type: actionTypes.undoBumpOrderSuccess });
		}
	}
};

export const reducer = (state = initialState, action) => {

	if(action.type.endsWith('ORDERS_REQUEST')) {
		return {
			...state,
			loading: action.type,
			status: null,
			errors: null
		}
	}

	switch(action.type) {

		case actionTypes.ordersFail: {
			return {
				...state,
				loading: null,
				errors: action.errors
			}
		}

		case actionTypes.setOrdersSuccess:
		case actionTypes.fetchOrdersSuccess: {
			if(action.pagingOffset && state.orderData?.hasNextPage && !action.orderData.hasNextPage && action.orderData.orders.length === 0) {
				// ok, so the backend thought there might be another page available, but there isn't so compensate...
				// console.log(action.pagingOffset, state.orderData, action.orderData);
				action.orderData.orders = state.orderData.orders;
				action.orderData.nextPageCancelled = true;
				return {
					...state,
					loading: null,
					orderData: action.orderData
				}
			} else {
				action.orderData.nextPageCancelled = false;	// just in case this is still set from a previous cycle
			}
			const orderData = { ...action.orderData };
			// we want to make sure orders from the same "party" (i.e. same table no) are grouped so they can be prepped together
			const orders = [];
			// 1st, sort the orders into date order (oldest first)...
			orderData.orders.sort((o1, o2) => o1.timestamp - o2.timestamp);
			// next, reset any previous grouping data...
			orderData.orders.forEach(order => delete(order.grouped));
			// next, group orders by table number where appropriate...
			orderData.orders.forEach(order => {
				// for OrdersList, only include existing orders in the store match the selected status options
				if(action.station !== 'all' || orderData.orderStatusFilter?.selectedOptions.includes(order.orderStatus)) {
					if(orders.length === 0 || order.distributionType !== distributionTypes.EAT_IN_WAITSTAFF || order.waitstaffTarget == null) {	// last test ignores orders w missing tables nos
						orders.push(order);
					} else {
						const ix = orders.findLastIndex(o => {
							// here's the "same party rule" - same table no and within specified minutes of each other...
							// console.log('DIFF', differenceInMinutes(order.timestamp, o.timestamp), action.orderGroupingMinutes);
							return o.distributionType === distributionTypes.EAT_IN_WAITSTAFF &&
								o.waitstaffTarget === order.waitstaffTarget &&
								differenceInMinutes(order.timestamp, o.timestamp) <= action.orderGroupingMinutes;
						});
						if(ix === -1) {
							orders.push(order);
						} else {
							// looks like we've found a "same party order" - so splice it in after the associated order...
							order.grouped = true;
							orders[ix].grouped = true;
							// console.log('Splicing:', order);
							orders.splice(ix + 1, 0, order);
						}
					}
				}
			});
			if(action.pageSize && (orders.length > action.pageSize)) {
				// looks like adding an order has pushed it over the pageSize so drop last one and set hasNextPage accordingly
				orderData.orders = orders.slice(0, action.pageSize);
				orderData.hasNextPage = true;
			} else {
				orderData.orders = orders;
			}
			return {
				...state,
				loading: null,
				orderData: orderData
			}
		}

		case actionTypes.fetchOrderBalancesSuccess: {
			if(state.orderBalances?.hasNextPage && !action.response.hasNextPage && action.response.orders.length === 0) {
				// ok, so the backend thought there might be another page available, but there isn't so compensate...
				action.response.orders = state.orderBalances.orders;
				action.response.nextPageCancelled = true;
			}
			return {
				...state,
				loading: null,
				orderBalances: action.response
			}
		}

		case actionTypes.clearOrderBalances: {
			return {
				...state,
				orderBalances: null
			}
		}

		case actionTypes.updateOrderDistributionTypesSuccess: {
			return {
				...state,
				loading: null
			}
		}

		case actionTypes.updateOrderFlowSuccess: {
			// note: other parts of this response are handled by the venue reducer
			return {
				...state,
				loading: null,
				status: storeUtils.processStatusMessages(action.response)
			}
		}

		case actionTypes.cancelOrderSuccess:
		case actionTypes.bumpOrdersSuccess:
		case actionTypes.updateOrderSuccess:
		case actionTypes.undoBumpOrderSuccess: {
			// none of these update state directly, so we just need to clear "loading"...
			return {
				...state,
				loading: null
			}
		}

		default: {
			return state;
		}
	}
}

export const distributionTypes = {
	EAT_IN_SELF_SERVICE: 1,
	EAT_IN_WAITSTAFF: 2,
	TAKE_OUT: 4,
	DELIVERY_RESTAURANT: 8,
	DELIVERY_PICTABITE: 16,
	ROOM_SERVICE: 32
}