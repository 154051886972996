import storeUtils from './storeUtils';

export const actionTypes = {
	posFail: 'POS_FAIL',

	syncRequest: 'SYNC_POS_REQUEST',
	syncSuccess: 'SYNC_POS_SUCCESS',

	fetchPOSinterfaceConfigRequest: 'GET_POSINTERFACECONFIG_POS_REQUEST',
	fetchPOSinterfaceConfigSuccess: 'GET_POSINTERFACECONFIG_POS_SUCCESS',
	updatePOSinterfaceConfigRequest: 'UPDATE_POSINTERFACECONFIG_POS_REQUEST',
	updatePOSinterfaceConfigSuccess: 'UPDATE_POSINTERFACECONFIG_POS_SUCCESS',
	deletePOSinterfaceConfigRequest: 'DELETE_POSINTERFACECONFIG_POS_REQUEST',
	deletePOSinterfaceConfigSuccess: 'DELETE_POSINTERFACECONFIG_POS_SUCCESS'
}

const initialState = { data: null, lastSynced: null, POSinterfaceConfig: null, status: null, loading: null, errors: null };

export const posActionCreators = {

	sync: () => async dispatch => {
		dispatch({ type: actionTypes.syncRequest });
		const data = await storeUtils.execute('posGateway', 'syncWithPOS');
		if(data.errors) {
			dispatch({ type: actionTypes.posFail, errors: data.errors });
		} else {
			dispatch({ type: actionTypes.syncSuccess, data: data, lastSynced: new Date() });
		}
	},

	fetchPOSinterfaceConfig: POSinterfaceConfig => async (dispatch, getState) => {
		const state = getState();
		if(state.pos.POSinterfaceConfig) return;
		dispatch({ type: actionTypes.fetchPOSinterfaceConfigRequest });
		const response = await storeUtils.execute('posGatewayManager', 'getPOSInterfaceConfig', POSinterfaceConfig);
		if(response.errors) {
			dispatch({ type: actionTypes.posFail, errors: response.errors });
		} else {
			dispatch({ type: actionTypes.fetchPOSinterfaceConfigSuccess, response: response });
		}
	},
	
	updatePOSinterfaceConfig: POSinterfaceConfig => async dispatch => {
		dispatch({ type: actionTypes.updatePOSinterfaceConfigRequest });
		const response = await storeUtils.execute('posGatewayManager', 'updatePOSInterfaceConfig', POSinterfaceConfig);
		if(response.errors) {
			dispatch({ type: actionTypes.posFail, errors: response.errors });
		} else {
			response.receivesOrdersInOwnPOS = POSinterfaceConfig.receivesOrdersInOwnPOS;	// this isn't needed by the pos reducer, but it is in the venues reducer (see PB-63)
			dispatch({ type: actionTypes.updatePOSinterfaceConfigSuccess, POSinterfaceConfig: POSinterfaceConfig, response: response });
		}
	},
	
	deletePOSinterfaceConfig: POSinterfaceConfig => async dispatch => {
		dispatch({ type: actionTypes.deletePOSinterfaceConfigRequest });
		const response = await storeUtils.execute('posGatewayManager', 'deletePOSInterfaceConfig', POSinterfaceConfig);
		if(response.errors) {
			dispatch({ type: actionTypes.posFail, errors: response.errors });
		} else {
			dispatch({ type: actionTypes.deletePOSinterfaceConfigSuccess, response: response });
		}
	}

};

export const reducer = (state = initialState, action) => {

	if(action.type.endsWith('POS_REQUEST')) {
		return {
			...state,
			loading: action.type,
			status: null,
			errors: null
		}
	}

	switch(action.type) {
		case actionTypes.posFail: {
			return {
				...state,
				loading: null,
				errors: action.errors
			}
		}

		case actionTypes.syncSuccess: {
			return {
				...state,
				loading: false,
				data: action.data,
				lastSynced: action.lastSynced,
				errors: null
			}
		}

		case actionTypes.fetchPOSinterfaceConfigSuccess: {
			return {
				...state,
				loading: null,
				POSinterfaceConfig: action.response.posInterfaceConfig
			}
		}

		case actionTypes.updatePOSinterfaceConfigSuccess: {
			// note: other parts of this response are handled by the venue reducer
			return {
				...state,
				loading: null,
				POSinterfaceConfig: action.POSinterfaceConfig,
				status: storeUtils.processStatusMessages(action.response)
			}
		}

		case actionTypes.deletePOSinterfaceConfigSuccess: {
			// note: other parts of this response are handled by the venue reducer
			return {
				...state,
				loading: null,
				POSinterfaceConfig: null
			}
		}

	default: return state;
	}
};