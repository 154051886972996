import { Fragment, useEffect } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { locales } from 'L10n';
import { l10nActionCreators } from 'store/l10n';
import { errorsActionCreators } from 'store/errors';
import ErrorDialog from 'dialogs/ErrorDialog';
import HomeContainer from './Home';
import Ext from 'Home/Ext';
import Page404 from './Page404';
import PageGeneric from './PageGeneric';
import VenuesContainer from './VenuesContainer';
import './App.scss';

const App = () => {
	const dispatch = useDispatch();
	const l10nStore = useSelector(state => state.l10n);
	const language = useSelector(state => state.settings.language);
	const errors = useSelector(state => state.errors.errors);

	useEffect(() => {
		if(language) {
			// if user changes the language, then we need to retrigger the next effect handler by clearing the store's locale
			dispatch(l10nActionCreators.setLocale(null));
		}
	}, [language, dispatch]);

	useEffect(() => {
		// console.log(language, navigator.language);
		let locale = language ? language : navigator.language;
		if(!l10nStore.locale) {
			// 1st, load English
			dispatch(l10nActionCreators.fetchTranslations('en'));
		} else if(l10nStore.locale === 'en') {
			let localeData = locales.find(loc => loc.value === locale);
			if(!localeData) {
				// this is not a locale in our list, but maybe we can fallback to a more generic version for this language?
				// ie use el when el-GR or el-CY is supplied (or, say, el_GR - does this ever happen?)
				const arr = locale.replace('_', '-').split('-');
				if(arr.length) locale = arr[0];
				localeData = locales.find(loc => loc.value === locale);
			}
			if(locale !== 'en' && localeData) {
				const fallback = localeData.fallback;
				if(fallback && fallback !== l10nStore.locale) {
					// 2nd, load preferred locale's fallback language if has one
					dispatch(l10nActionCreators.fetchTranslations(fallback));
				} else {
					// finally, load preferred locale
					dispatch(l10nActionCreators.fetchTranslations(locale));
				}
			}
		} else {
			let localeData = locales.find(loc => loc.value === locale);
			if(localeData && locale !== l10nStore.locale) {
				// finally, load preferred locale
				dispatch(l10nActionCreators.fetchTranslations(locale));
			}
		}
		// I need to know language but I don't want it to trigger when this changes (previous effect handler does this)... (what's the correct way to do this?)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [l10nStore.locale, dispatch]);

	const errorDialogHideHandler = () => {
		dispatch(errorsActionCreators.clearErrors());
	}

	return (
		<Fragment>

			<BrowserRouter getUserConfirmation={ () => {} }>
				<Switch>
					<Route path='/venues/'><VenuesContainer/></Route>
					<Route path='/app-:page.html'><Ext/></Route>
					<Route exact path='/'><HomeContainer/></Route>
					<Route exact path='/index.html'><HomeContainer/></Route>
					<Route path='/page/:page/:details'><PageGeneric/></Route>
					<Route path='/page/:page'><PageGeneric/></Route>
					<Route><Page404/></Route>
				</Switch>
			</BrowserRouter>

			<ErrorDialog errors={ errors } onHide={ errorDialogHideHandler } draggable={ true }/>

		</Fragment>
	);
}

export default App;