import { useEffect, useRef } from 'react';
import useForm from 'components/Form';
import ValidationMessage from 'components/ValidationMessage';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'components/Modal';
import useL10n from 'L10n';
import { FormInput, FormLabel, FormTypeahead } from 'components/formControls';

const ItemEditorDialog = props => {
	const _t = useL10n().getText;
	const menuSections = props.menuSections?.map(menuSection => menuSection.title);

	const formSave = () => {
		// console.log(form.data);
		props.onHide(form.data);
	}

	const form = useForm(formSave, validationRules);
	const formRef = useRef(form);

	useEffect(() => {
		if(props.show) {
			const data = {
				menuSectionTitle: props.form.data.menuSectionTitle,
				name: props.form.data.name,
				calories: props.form.data.calories
			}
			formRef.current.reset(data);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.show]);

	return (
		<Modal id="itemEditorDialog" show={ props.show } draggable={ true } onHide={ props.onHide }>
			<ModalHeader>{ _t('HDG_EDIT_MORE') }</ModalHeader>

			<ModalBody>

				<ValidationMessage open={ !!form.errors }/>

				<div className="form-row">
					<FormLabel htmlFor="menuSectionTitle" className="col-sm-3" label="_MENUSECTION"/>
					<div className="col-sm-9">
						<FormTypeahead
							form={ form }
							field="menuSectionTitle"
							data={ menuSections }
							maxMatches={ 5 }
							minLength={ 2 }
							disabled={ props.disabled }
							highlight={ true }
						/>
					</div>
				</div>

				<div className="form-row">
					<FormLabel htmlFor="name" className="col-sm-3" label="_MENUITEM"/>
					<div className="col-sm-9">
						<FormInput form={ form } field="name" disabled={ props.disabled }/>
					</div>
				</div>

				<div className="form-row">
					<FormLabel htmlFor="calories" className="col-sm-3" label="_CALORIES"/>
					<div className="col-sm-9">
						<FormInput form={ form } field="calories" disabled={ props.disabled }/>
					</div>
				</div>

			</ModalBody>

			<ModalFooter className="Modal-footer_buttons">

				<button className="btn btn-secondary" onClick={ () => props.onHide() } id="btnCancel">
					{ _t('BTN_CANCEL', true) }
				</button>
				<button className="btn btn-primary" onClick={ form.onSave } id="btnSave">
					{ _t('BTN_SAVE', true) }
				</button>

			</ModalFooter>

		</Modal>
	);
};

export default ItemEditorDialog;

const validationRules = {
	menuSectionTitle: true,
	name: true
}