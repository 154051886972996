import React, { useState } from 'react';
import { Checkbox, Radio, InputWithButton } from '../components/controls';
import DropdownButton from '../components/DropdownButton';
import useForm from '../components/Form';
import { FormDatePicker, FormInput } from '../components/formControls';
import { IconButton } from '../components/buttons';
import { Pane, PaneHeader, PaneBody } from '../components/Pane';
import Typeahead from '../components/Typeahead';
import states from './states.js';	// sample data for typeahead
import ValidationMessage from '../components/ValidationMessage';

export const Pane2 = props => {

	const [option, setOption] = useState();
	const [child, setChild] = useState();
	const [album, setAlbum] = useState();

	const options = [
		{ id: 0, title: 'First Option'},
		{ id: 1, title: 'Second Option'},
		{ id: 2, title: 'Third Option'},
		{ id: 3, title: 'Fourth Option'},
		{ id: 4, title: 'Last Option'}
	];

	const formSave = () => {
		console.log('formSave', form.data);
	}

	const initFormData = {
		item1: 'this is item 1'
	}

	const form = useForm(formSave, validationRules, initFormData);

	const onChange = (event, id, key, label) => {
		console.log('onChange:', event, id, key, label);
		switch(id) {
			case 'children': setChild(key); break;
			case 'options': setOption(parseInt(key, 10)); break;
			case 'albums': setAlbum(label); break;
			default: break;
		}
	}

	const deleteMenuItem = e => {
		let button = e.currentTarget;
		button.disabled = true;
		button.classList.add('async');
		// next bit simulates an api call taking 1 sec...
		window.setTimeout(() => {
			button.classList.remove('async');
			button.disabled = false;
		}, 1000);
	}

	// console.log(form);

	return (
		<Pane id="pane2">
			<PaneHeader className="Pane-toolBar">

				<h1>Pane with buttons</h1>
				<IconButton title="save menu item" icon="save" onClick={ form.onSave }/>
				<IconButton title="delete menu item" icon="delete" onClick={ deleteMenuItem }/>

			</PaneHeader>
			<PaneBody className="container">

				<ValidationMessage open={ !!form.errors }/>

				<div className="form-row">
					<div className="col">
						<Checkbox label="Checkbox"/>
					</div>
					<div className="col">
						<FormInput placeholder="Item 1" form={ form } field="item1"/>
					</div>
				</div>
				<div className="form-row">
					<div className="col">
						<Radio label="Radio" name="r1"/>
					</div>
					<div className="col">
						<FormInput placeholder="Item 2" form={ form } field="item2"/>
					</div>
				</div>
				<div className="form-row">
					<div className="col">
						<Radio label="Radio" name="r1"/>
					</div>
					<div className="col">
					<FormInput placeholder="Item 3" form={ form } field="item3"/>
					</div>
				</div>

				<div className="form-row">
					<div className="col">
						<FormDatePicker placeholder="YYYY-MM-DD" form={ form } field="startDate" onChange={ form.onChange }/>
					</div>
				</div>

				<div className="form-row">
					<div className="col">

						<Typeahead
							placeholder="US states"
							name="state"
							data={ states }
							keys="id"
							labels="display"
							maxMatches={ 5 }
							minLength={ 2 }
							highlight={ true }
							onChange={ onChange }
						/>

					</div>
				</div>

				<div className="form-row">
					<div className="col">

						<DropdownButton placeholder="children" name="children" value={ child } onChange={ onChange }>
							<option role="menuitem"></option>
							<option value="1">Peter</option>
							<option value="2">Susan</option>
							<option value="3">Edmund</option>
							<option value="4">Lucy</option>
						</DropdownButton>

					</div>
				</div>

				<div className="form-row">
					<div className="col">

						<DropdownButton
							placeholder="options"
							name="options"
							options={ options }
							keys="id"
							labels="title"
							value={ option }
							onChange={ onChange }
						/>

					</div>
				</div>

				<div className="form-row">
					<div className="col">

						<DropdownButton
							placeholder="Beatles albums"
							name="albums"
							options={ ['Please Please Me', 'With the Beatles', 'A Hard Day\'s Night', 'Beatles for Sale', 'Help!', 'Rubber Soul', 'Revolver', 'Sgt. Pepper\'s Lonely Hearts Club Band', 'Magical Mystery Tour', 'The White Album', 'Yellow Submarine', 'Abbey Road', 'Let It Be'] }
							value={ album }
							onChange={ onChange }
						/>

					</div>
				</div>

				<div className="form-row">
					<div className="col">
						<InputWithButton type="url" name="webUrl" placeholder="http://www.website.com" icon="open_in_new"/>
					</div>
				</div>

			</PaneBody>
		</Pane>
	);

}

export default Pane2;

const validationRules = {
	item1: true,
	item2: true,
	startDate: true
}