import utilities from '../services/utilities';
import { errorCodes } from './errors';
import storeUtils from './storeUtils';

export const actionTypes = {
	userFail: 'USER_FAIL',

	loginRequest: 'LOGIN_USER_REQUEST',
	loginSuccess: 'LOGIN_USER_SUCCESS',
	logoutRequest: 'LOGOUT_USER_REQUEST',
	logoutSuccess: 'LOGOUT_USER_SUCCESS',
	setUserSuccess: 'SET_USER_USER_SUCCESS',

	resetPasswordRequest: 'RESET_PASSWORD_USER_REQUEST',
	resetPasswordSuccess: 'RESET_PASSWORD_USER_SUCCESS',
	updatePasswordRequest: 'UPDATE_PASSWORD_USER_REQUEST',
	updatePasswordSuccess: 'UPDATE_PASSWORD_USER_SUCCESS',

	searchUsersRequest: 'SEARCH_USERS_USER_REQUEST',
	searchUsersSuccess: 'SEARCH_USERS_USER_SUCCESS',
	clearUsers: 'CLEAR_USERS_USER'
}

const initialState = { user: null, users: null, loading: null, errors: null };

export const userActionCreators = {
	
	
	login: (username, password) => async dispatch => {
		dispatch({ type: actionTypes.loginRequest });
		const credentials = `username=${ username }&password=${ password }`;
		const response = await storeUtils.execute('other', 'login', credentials);
		if(response.errors) {
			if(response.errors[0].code === errorCodes.GENERIC_API_ERROR) {
				// we need a "special" error here so sign-in can rerun with an appropriate message
				// unshift preserves the original error in errors[1]
				response.errors.unshift({
					code: errorCodes.LOGIN_FAILURE,
					msg: 'LOGIN_FAILURE'
				});
			}
			dispatch({ type: actionTypes.userFail, errors: response.errors });
		} else {
			dispatch({ type: actionTypes.loginSuccess, user: response.user });
		}
	},
	
	logout: user => async dispatch => {
		dispatch({ type: actionTypes.logoutRequest });
		const response = await storeUtils.execute('other', 'logout', user);
		if(response.errors) {
			dispatch({ type: actionTypes.userFail, errors: response.errors });
		} else {
			dispatch({ type: actionTypes.logoutSuccess });
		}
	},
	
	setUser: user => {
		return { type: actionTypes.setUserSuccess, user: user };
	},

	resetUser: () => {
		// see ./index.js rootReducer for how this resets
		return { type: actionTypes.logoutSuccess };
	},

	resetPassword: email => async dispatch => {
		dispatch({ type: actionTypes.resetPasswordRequest });
		const response = await storeUtils.execute('account', 'resetPassword', { email: email });
		if(response.errors) {
			dispatch({ type: actionTypes.userFail, errors: response.errors });
		} else {
			dispatch({ type: actionTypes.resetPasswordSuccess });
		}
	},

	updatePassword: (password, newPassword) => async dispatch => {
		dispatch({ type: actionTypes.updatePasswordRequest });
		const response = await storeUtils.execute('account', 'updateUserPassword', { password: password, newPassword: newPassword });
		if(response.errors) {
			dispatch({ type: actionTypes.userFail, errors: response.errors });
		} else {
			dispatch({ type: actionTypes.updatePasswordSuccess });
		}
	},

	searchUsers: search => async dispatch => {
		dispatch({ type: actionTypes.searchUsersRequest });
		const response = await storeUtils.execute('account', 'searchUsersByUsername', { email: search });
		if(response.errors) {
			dispatch({ type: actionTypes.userFail, errors: response.errors });
		} else {
			dispatch({ type: actionTypes.searchUsersSuccess, response: response });
		}
	},

	clearUsers: () => {
		return { type: actionTypes.clearUsers }
	}

};

export const reducer = (state = initialState, action) => {

	if(action.type.endsWith('USER_REQUEST')) {
		return {
			...state,
			loading: action.type,
			errors: null
		}
	}

	// check every response across the store for returned user data
	if(action.response?.AUTO_LOGIN_JSONUSER) {
		const user = action.response.AUTO_LOGIN_JSONUSER.user;
		user.emailVerified = !!user.emailVerified;
		user.hasPrivilegedAccess = !!user.hasPrivilegedAccess;
		state = {
			...state,
			user: user,
		}
	}

	switch(action.type) {
		case actionTypes.userFail: {
			return {
				...state,
				loading: null,
				errors: action.errors
			}
		}

		case actionTypes.loginSuccess: {
			action.user.emailVerified = !!action.user.emailVerified;
			action.user.hasPrivilegedAccess = !!action.user.hasPrivilegedAccess;
			return {
				...state,
				loading: null,
				user: action.user,
				errors: null
			}
		}

		case actionTypes.logoutSuccess: {
			localStorage.removeItem('pb_user');
			return {
				...state,
				loading: null,
				user: null,
				errors: null
			}
		}

		case actionTypes.setUserSuccess: {
			return {
				...state,
				loading: null,
				user: action.user,
				errors: null
			}
		}

		case actionTypes.searchUsersSuccess: {
			utilities.sortByField(action.response.users, 'email');
			return {
				...state,
				loading: null,
				users: action.response.users,
				errors: null
			}
		}

		case actionTypes.clearUsers: {
			return {
				...state,
				users: null
			}
		}

		default: return state;
	}
};