// this is used in both \Venue and \VenueMaintenance, hence props.children in the first col
// this means the consumer "page" determines the content of the first col
// Note: there's quite a bit of interaction with the store in this component - should I move this up to a container?
import { useDispatch, useSelector } from 'react-redux';
import { settingsActionCreators } from 'store/settings';
import LanguagePicker from 'components/LanguagePicker';
import { locales } from 'L10n';
import './VenueHeader.scss';

const VenueHeader = props => {
	const dispatch = useDispatch();
	const l10nStore = useSelector(state => state.l10n);

	const address = () => {
		if(!props.venue) return null;
		if(props.venue.address) return props.venue.address;
		if(props.venue.street) {
			let address = props.venue.street + format(props.venue.city) + format(props.venue.provinceName) + format(props.venue.countryName);
			return address;
		}
		return '';
	}

	const languageChangeHandler = (e, field, value, label) => {
		dispatch(settingsActionCreators.updateSetting('language', value));
	}

	const refreshClickHandler = e => {
		window.location.reload();
	}

	return (
		<header className="VenueHeader pageHeader">

			<div id="venueInfo" className="pageHeader-control" onClick={ props.venueInfoClickHandler }>
				<div>
					<h1>{ props.venue?.name }</h1>
					<h2>{ address() }</h2>
				</div>
				<button type="button" title="edit venue" className="btn btn-dropper">
					<span className="material-icons">keyboard_arrow_down</span>
				</button>
			</div>

			<button className="btn" onClick={ refreshClickHandler }>
				<span className="material-icons">refresh</span>
			</button>
			<LanguagePicker id="languagePicker" value={ l10nStore.locale } options={ locales } onChange={ languageChangeHandler }/>

		</header>
	);

}

export default VenueHeader;

function format(addressLine) {
	return addressLine ? ', ' + addressLine : '';
}