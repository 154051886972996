// utilities to handle Pictabite-specific endpoints
// import { store } from 'index';
import { config, store } from '.';

const services = {
	account: 'accountWSrvc',
	billing: 'venueBillingWSrvc',
	location: 'locationSrvc',
	menus: 'menusWSrvc',
	modifiers: 'modifiersWSrvc',
	orders: 'ordersWSrvc',
	photos: 'photosSrvc',	// see venues
	printers: 'printerSrvc',
	rewards: 'loyaltyRewardsWSrvc',
	stripe: 'stripeSrvc',
	tags: 'tagsWSrvc',
	venues: 'venuesWSrvc',
	venueOwners: 'venueOwnersWSrvc',
	posGateway: 'posGatewaySrvc',
	posGatewayManager: 'posGatewayManagerSrvc',
	other: 'other'
}

const getUrl = (service, name, fake) => {
	if(service === null) return name;	// assume full path passed and invoker knows what they are doing
	const url = config.fakeApis || fake ? `/api/${ service }/${ name }.json` : config.apiPath + `/${ services[service] }/${ name }`;
	return url;
}

const encodeJSON = json => {
		// passing a reducer to stringify to convert true/false to 1/0
		const encoded = 'json=' + encodeURIComponent(JSON.stringify(json, (key, value) => {
		return (typeof value === 'boolean') ? (value ? 1 : 0) : value;
	}));
	return encoded;
}

const getBody = payload => {
	if(typeof payload === 'string') {
		// assume the user is taking care of business
		return payload;
	} else {
		if(payload.code) {
			// this is supposed to force a return of supplied error code, but instead causes a fail due to CORS policy - not sure why
			return 'json=%7B%7D&json_sc=' + encodeURIComponent(JSON.stringify({ "test_forceServerErrorCode": payload.code }));
		} else {
			return encodeJSON(payload);
		}
	}
}

const getOptions = (body, service, operation, fake) => {
	if(config.requestLogging) console.log('request:', body);
	if(config.fakeApis || fake) {
		return {}
	} else {
		if(service === 'photos' && operation.substr(0, 6) === 'upload') {
			// uploading photos is a little different...
			return {
				credentials: 'include',
				method: 'POST',
				body: body
			}
		} else {
			return {
				credentials: 'include',
				method: 'POST',
				headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8' },
				body: getBody(body)
			};
		}

	}
}

const addSessionValidation = payload => {
	// add data to every request payload for server-side valid session verification
	if(typeof payload !== 'object' || payload instanceof FormData) return;
	// this is a little a little problematic as we access the store directly here which may turn out to be a bad idea
	// this is because we are now tightly coupled to the store created by the app on load here
	// however, can't see another way to get state outside of a component
	const state = store.getState();
	const venueId = state.venues.venue?.venueUUID || null;
	const userId = state.user.user?.userId || null;
	if(venueId && userId) {
		payload.sessValidation = {
			venueUUID: venueId,
			userId: userId
		}
	}
}

const getPhotoPayload = (payload, photo) => {
	// see getOptions above - this is not a form-style POST
	const formData = new FormData();
	addSessionValidation(payload);
	const body = JSON.stringify(payload);
	formData.append('json', body);
	formData.append('photo', photo);
	return formData;
}

const execute = async (service, operation, payload = {}, fake) => {
	// if fake = true, use local json regardless of config.fakeApis setting
	let data;
	// handy for simulating an api error...
	// if(operation === 'OP NAME GOES HERE') {
	// 	console.log(operation);
	// 	return { errors: [
	// 		{ code: 481, msg: 'INVALID_TOKEN' }
	// 	]};
	// }
	try {
		addSessionValidation(payload);
		const response = await fetch(getUrl(service, operation, fake), getOptions(payload, service, operation, fake));
		if(response.ok) {
			data = await response.json();
		} else {
			data = { errors: [
				{ code: response.status, msg: response.statusText }
			]};
		}
	} catch(ex) {
		if((config.fakeApis || fake) && ex.name === 'SyntaxError' && ex.message.startsWith('Unexpected token <')) {
			// for testing, assume this is an api call with no response payload
			data = {};
			// data = { errors: [
			// 	{ code: 400, msg: 'what on earth?' }
			// ]};
		} else {
			data = { errors: [ ex.message ] };
		}
	} finally {
		if(data.errors && data.errors[0].code && config.exceptionLogging) console.error(data);
		if(config.responseLogging) console.log('response:', data);
		return data;
	}
}

const shiftElement = (elements, shiftElement, isUp) => {
	const indexOffset = isUp ? -1 : 1;
	const shiftElementOrderIndex = shiftElement.orderIndex;
	if((isUp && shiftElementOrderIndex > elements[0].orderIndex) || (!isUp && shiftElementOrderIndex < elements.length)) {
		const prevElement = elements.find(element => element.orderIndex === shiftElementOrderIndex + indexOffset);
		shiftElement.orderIndex = prevElement.orderIndex;
		prevElement.orderIndex = shiftElementOrderIndex;
	}
}

const getShiftElementRequest = (elements, id, isUp, elementIdProperty, requestIdProperty) => {
	// this creates the request object needed for reorder<Things> api calls
	// this request object is used both in the action as the payload and then by the reducer to update the state
	if(!requestIdProperty) requestIdProperty = elementIdProperty;
	const request = {
		orderIndexes: elements.map(element => { return { [requestIdProperty]: element[elementIdProperty], orderIndex: element.orderIndex }})
	}
	// the following resolves the situation when orderIndexes become non-contiguous...
	request.orderIndexes.sort((i1, i2) => i1.orderIndex - i2.orderIndex);
	for(let i = 0, l = request.orderIndexes.length; i < l; i++) {
		request.orderIndexes[i].orderIndex = i + 1;
	}
	const element = request.orderIndexes.find(element => element[requestIdProperty] === id);
	shiftElement(request.orderIndexes, element, isUp);
	return request;
}

const deleteElement = (collection, id, idProperty) => {
	// filter out element to delete then fix the orderIndexes accordingly
	// console.log(collection, id, idProperty);
	if(id === undefined) {
		console.error('deleteElement: missing id');
		return collection;
	}
	return collection
		.filter(element => element[idProperty || 'id'] !== id)
		.map((element, ix) => {
			element.orderIndex = ix + 1;
			return element;
		});
}

const processStatusMessages = response => {
	// we need them to be prefixed with the appropriate "type" for l10n
	if(!response) return null;
	let status = null;
	if(response.errorKeys || response.warningKeys || response.infoKeys) {
		status = {
			errors: response.errorKeys?.map(key => 'ERR_' + key),
			warnings: response.warningKeys?.map(key => 'WRN_' + key),
			info: response.infoKeys?.map(key => 'INF_' + key)
		}
	}
	return status;
}

const processChildCollection = (entities, idField, id, ChildCollectionProperty, callback) => {
	// useful for adding, removing, etc an item from a child collection of an item in a collection
	// ie to add a menu item to menu 7 in a collection of menus: processChildCollection(menus, 'menuId', 7, 'items', addItemFunc);
	// this is a very common pattern in the store reducers
	const newEntities = entities.map(entity => {
		if(entity[idField] !== id) {
			return entity;
		} else {
			const newEntity = { ...entity };
			newEntity[ChildCollectionProperty] = callback(entity[ChildCollectionProperty]);
			return newEntity;
		}
	});
	return newEntities;
}

const fixEntityId = (entity, apiIdProperty) => {
	// in-memory entities will generally have an id called 'id', but often the api has a more descriptive id name
	const newEntity = { ...entity };
	newEntity[apiIdProperty] = entity.id;
	delete(newEntity.id);
	return newEntity;
}

export default {
	deleteElement,
	encodeJSON,
	execute,
	fixEntityId,
	getBody,
	getPhotoPayload,
	getShiftElementRequest,
	processChildCollection,
	processStatusMessages
};