import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import classNames from 'classnames';
import useL10n from 'L10n';
import FlyoutPage from './layout/FlyoutPage';
import './PageGeneric.scss';
import JsonViewer from 'components/JsonViewer';

const PageGeneric = props => {
	const { page, details } = useParams();
	const [showDetails, setShowDetails] = useState(false);
	let centred = false;
	const _t = useL10n().getText;
	const history = useHistory();

	const getPage = () => {
		switch(page) {
			case 'stripe-done': centred = true; return 'STRIPE_DONE';
			case 'stripe-fail': centred = true; return 'STRIPE_FAIL';
			default: history.push('/404');
		}
	}

	const getJson = () => {
		const text = decodeURIComponent(details.substring(5));
		const json = JSON.parse(text);
		console.log(text, json);
		return json;
	}

	return (
		<FlyoutPage>

			<div className="PageGeneric container-fluid">
				<header className="pageHeader pageHeader_centred">
					<h1>
						{ _t(`HDG_${ getPage() }`, true) }
					</h1>
				</header>
				<main className={ classNames({ 'text-center': centred })}>
					<p>{ _t(`TXT_${ getPage() }`) }</p>
					{ details
						? showDetails
								? <section className="PageGeneric-details">
										<h2>Details</h2>
										{ details.startsWith('json=')
											? <JsonViewer json={ getJson() } />
											: details
										}
									</section>
								: <button className="btn btn-primary mt10" onClick={ () => { setShowDetails(true)} }>DETAILS</button>
						: null
					}
					
				</main>
			</div>

		</FlyoutPage>
	);
};

export default PageGeneric;