import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { store } from './store/';
import App from './App';
// import registerServiceWorker from './registerServiceWorker';
import 'bootstrap/dist/css/bootstrap.css';
import 'pure-react-carousel/dist/react-carousel.es.css';
import 'animate.css';
import './index.scss';

let style = 'display: inline-block; color: #FFF; background-color: #E74C34; font-size: 16px; padding: 10px';
console.info('%c' + process.env.REACT_APP_ENV.toUpperCase() + ' v' + process.env.REACT_APP_VERSION, style);

ReactDOM.render((
	<Provider store={ store }>
		<App />
	</Provider>), document.getElementById('root')
);

// registerServiceWorker();