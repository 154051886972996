// some lightweight wrappers on 'regular' controls and components to make them work with Form.js
import { useState } from 'react';
import classNames from 'classnames';
import useL10n from 'L10n';
import dates from 'services/dates';
import utilities from 'services/utilities';
import DatePicker from './DatePicker';
import DropdownButton from './DropdownButton';
import ImageControl from './ImageControl';
import { InputWithButton, Checkbox, Radio } from './controls';
import Typeahead from './Typeahead';
import { IconButton } from './buttons';
import TimePicker from './TimePicker';

export const FormInput = ({ form, field, type, className, placeholder, ...props }) => {
	const _t = useL10n().getText;
	if(type === 'money') {
		return <FormMoneyInput form={ form } field={ field } className={ className } placeholder={ placeholder } { ...props }/>
	}
	const classes = classNames('form-control', className, { 'validation-fail': form.errors && form.errors[field] });
	const value = form.data[field] ?? '';
	if(placeholder && placeholder.startsWith('_')) {
		placeholder = translate('PLH' + placeholder, _t);
	}
	const title = form.errors && form.errors[field] ? (placeholder ? placeholder + ': ' : '') + form.errors[field] : placeholder;
	if(type === 'textarea') {
		return (
			<textarea
				placeholder={ placeholder }
				autoComplete="off"
				className={ classes }
				title={ title }
				name={ field }
				value={ value }
				{ ...props }
				onChange={ form.onChange }
			/>
		);
	} else {
		return (
			<input
				type={ type || 'text' }
				placeholder={ placeholder }
				autoComplete="off"
				className={ classes }
				title={ title }
				name={ field }
				value={ value }
				{ ...props }
				onChange={ form.onChange }
			/>
		);
	}
}

export const _FormInputWithButton = ({ form, field, ...props }) => {
	const classes = classNames({ 'validation-fail': form.errors && form.errors[field] });
	const title = form.errors && form.errors[field] ? props.placeholder + ': ' + form.errors[field] : props.placeholder;
	return (
		<InputWithButton
			className={ classes }
			autoComplete="off"
			title={ title }
			name={ field }
			value={ form.data[field] ?? '' }
			{ ...props }
			onChange={ form.onChange }
		/>
	);
}

// I wanted a version of this control that didn't rely on BS's input-group which just adds soooo much css that needs to be reset
// InputWithButton is still used thought in quite a few places - I may look to changing these to how this one works
export const FormInputWithButton = ({ form, field, icon, buttonDisabled, onButtonClick, ...props }) => {
	const classes = classNames({ 'validation-fail': form.errors && form.errors[field] });
	const title = form.errors && form.errors[field] ? props.placeholder + ': ' + form.errors[field] : props.placeholder;
	return (
		<div className="form-control_buttoned">
			<FormInput className={ classes } autoComplete="off" title={ title } form={ form } field={ field } { ...props} />
			<IconButton icon={ icon } disabled={ props.disabled || buttonDisabled } onClick={ onButtonClick }/>
		</div>
	);
}

export const _FormPassWord = props => {
	// this version maintains its own state but deprecated in favour
	// of version where state is maintained by the form
	const [show, setShow] = useState(false);

	const buttonClickHander = e => {
		setShow(!show);
	}

	return (
		<FormInputWithButton
			type={ show ? 'text' : 'password' }
			icon={ show ? 'visibility_off' : 'visibility' }
			onButtonClick={ buttonClickHander }
			{ ...props }
		/>
	);
}

export const FormPassWord = props => {
	const btnField = props.field + 'Show';
	const show = props.form.data[btnField];

	const buttonClickHander = e => {
		props.form.onChange(null, btnField, !show);
	}

	return (
		<FormInputWithButton
			type={ props.form.data[btnField] ? 'text' : 'password' }
			icon={ props.form.data[btnField] ? 'visibility_off' : 'visibility' }
			onButtonClick={ buttonClickHander }
			{ ...props }
		/>
	);
}

export const FormInputGroup = ({ className, disabled, prepend, append, ...inputProps }) => {
	const classes = classNames('input-group', className, { 'input-group_disabled': disabled });
	return (
		<div className={ classes }>
			{ prepend &&
				<div className="input-group-prepend">
					<span className="input-group-text">{ prepend }</span>
				</div>
			}
			<FormInput disabled={ disabled } { ...inputProps }/>
			{ append &&
				<div className="input-group-append">
					<span className="input-group-text">{ append }</span>
				</div>
			}
		</div>
	);
}

export const FormImageControl = ({ form, field, ...props }) => {
	return <ImageControl name={ field } value={ form.data[field] ?? '' } onChange={ form.onChange } { ...props }/>
}

export const FormCheckbox = ({ form, field, children, onChange, className, ...props }) => {
	const classes = classNames(className, { 'validation-fail': form.errors && form.errors[field] });
	return (
		<Checkbox
			id={ field }
			className={ classes }
			checked={ form.data[field] ?? '' }
			{ ...props }
			onChange={ onChange ? onChange : form.onChange }	// this allows implementer to call custom validation before directly invoking the form change handler
		>
			{ children }
		</Checkbox>
	);
}

export const FormRadio = ({ form, field, children, onChange, className, ...props }) => {
	const classes = classNames(className, { 'validation-fail': form.errors && form.errors[field] });
	return (
		<Radio
			name={ field }
			className={ classes }
			checked={ form.data[field] != null ? form.data[field].toString() === props.value : false }
			{ ...props }
			onChange={ onChange ? onChange : form.onChange }	// this allows implementer to call custom validation before directly invoking the form change handler
		>
			{ children }
		</Radio>
	);
}

export const FormDropdown = ({ form, field, children, options, placeholder, ...props }) => {
	const _t = useL10n().getText;
	const classes = classNames({ 'validation-fail': form.errors && form.errors[field] });
	if(placeholder && placeholder.startsWith('_')) {
		placeholder = translate('PLH' + placeholder, _t);
	}
	const title = form.errors && form.errors[field] ? placeholder + ': ' + form.errors[field] : placeholder;
	if(options && typeof options[0] === 'string' && options[0].startsWith('_')) {
		// convert an array of translation keys into options that will display the appropriate translation but return the key on selection
		// see OrderTypes for an example usage
		options = options.map(option => {
			return {
				value: option,
				label: utilities.getText('OPT' + option)
			}
		});
	}

	const onChange = (e, field, value, label) => {
		form.onChange(e, field, value, label);
		if(props.onChange) props.onChange(e, field, value, label);
	}

	return (
		<DropdownButton
			placeholder={ placeholder }
			className={ classes }
			title={ title }
			name={ field }
			value={ form.data[field] }
			options={ options }
			{ ...props }
			onChange={ onChange }
		>
			{ children }
		</DropdownButton>
	);
}

export const FormTypeahead = ({ form, field, className, ...props }) => {
	const classes = classNames(className, { 'validation-fail': form.errors && form.errors[field] });
	const title = form.errors && form.errors[field] ? props.placeholder + ': ' + form.errors[field] : props.placeholder;
	return (
		<Typeahead
			className={ classes }
			title={ title }
			name={ field }
			value={ form.data[field] }
			{ ...props }
			onChange={ form.onChange }
		/>
	);
}

export const FormTimePicker = ({ form, field, className, ...props }) => {
	const classes = classNames(className, { 'validation-fail': form.errors && form.errors[field] });
	const title = form.errors && form.errors[field] ? props.placeholder + ': ' + form.errors[field] : props.placeholder;
	return (
		<TimePicker
			className={ classes }
			title={ title }
			name={ field }
			value={ form.data[field] ?? '' }
			{ ...props }
			onChange={ form.onChange }
		/>
	);
}

export const FormDatePicker = ({ form, field, children, options, placeholder, formatter, ...props }) => {
	const classes = classNames({ 'validation-fail': form.errors && form.errors[field] });
	if(placeholder && placeholder.startsWith('_')) {
		placeholder = utilities.getText('PLH' + placeholder);
	}
	const title = form.errors && form.errors[field] ? placeholder + ': ' + form.errors[field] : placeholder;

	const onChange = (e, field, value, label) => {
		form.onChange(e, field, value, label);
		if(props.onChange) props.onChange(e, field, value, label);
	}

	if(!formatter) formatter = date => dates.format(date);	// provide a default locale specific formatter (w/o timeZone considerations)

	return (
		<DatePicker
			placeholder={ placeholder }
			className={ classes }
			title={ title }
			name={ field }
			date={ form.data[field] }
			formatter={ formatter }
			{ ...props }
			onChange={ onChange }
		>
			{ children }
		</DatePicker>
	);
}

const FormMoneyInput = ({ form, field, children, className, placeholder, ...props }) => {
	// react isn't great w currency fields if done as controlled input
	// maybe should consider formatting (etc) onblur instead?
	const onValueChange = e => {
		// since formatting above alters the value, this causes the cursor to jump to the end - the following "puts it back"
		// see https://stackoverflow.com/questions/35535688/stop-cursor-jumping-when-formatting-number-in-react
		const element = e.target;
		let caret = element.selectionStart;
		if(e.target.value.charAt(caret - 1) === '.') {
			// when . is typed move cursor to after .
			e.target.value = value;
			caret = value.length - 2;
		}
		// console.log('caret', caret, value, e.target.value, e.target.value.charAt(caret - 1));
		if(isNaN(parseFloat(e.target.value))) return;	// reject invalid inputs
		window.requestAnimationFrame(() => {
			element.selectionStart = caret;
			element.selectionEnd = caret;
		});
		form.onChange(e);
	}

	const classes = classNames('form-control', 'form-control_money', className, { 'validation-fail': form.errors && form.errors[field] });
	let value = utilities.formatPrice(form.data ? form.data[field] : null);
	if(isNaN(value)) value = '';

	if(placeholder && placeholder.startsWith('_')) {
		placeholder = utilities.getText('PLH' + placeholder);
	}
	const title = form.errors && form.errors[field] ? (placeholder ? placeholder + ': ' : '') + form.errors[field] : placeholder;
	return (
		<input
			type="text"
			placeholder={ placeholder }
			autoComplete="off"
			className={ classes }
			title={ title }
			name={ field }
			value={ value }
			onChange={ onValueChange }
			{ ...props }
		/>
	);
}

export const FormLabel = ({ className, label, ...props }) => {
	const _t = useL10n().getText;
	const classes = classNames('col-form-label', className);
	if(label && label.startsWith('_')) {
		label = translate('LBL' + label, _t);
	}
	return <label className={ classes } { ...props }>{ label }</label>;
}

export const FormValue = ({ form, field, className, type, currencySymbol, ...props }) => {
	const classes = classNames('form-value', { 'text-right': type === 'money' }, className);
	let value = form.data[field] ?? '';
	if(type === 'money') value = utilities.formatPrice(value, currencySymbol);
	// if(type === 'date' && value) {
	// 	// This was used on OrderDetailsDialog but has been designed out due to time zone requirements
	// 	const date = new Date(value);
	// 	value = dates.dateFormatter.format(date);
	// }
	// if(type === 'datetime' && value) {
	// 	// This was used on OrderDetailsDialog but has been designed out due to time zone requirements
	// 	const date = new Date(value);
	// 	value = dates.datetimeFormatter.format(date);
	// }
	return <div className={ classes } { ...props }>{ value }</div>;
}

const translate = (key, newFunc) => {
	let text = utilities.getText(key);
	if(text === key) text = newFunc(key);
	return text;
}