import { useEffect, useRef, useState } from "react";
import { actionTypes } from 'store/menus';
import { IconButton } from "components/buttons";
import { Pane, PaneBody, PaneHeader } from "components/Pane";
import MenuItem from "./MenuItem";
import './MenusSearchPane.scss';

const MenusSearchPane = props => {
	const [search, setSearch] = useState('');
	const [results, setResults] = useState([]);
	const inputRef = useRef();

	useEffect(() => {
		// set the focus on the search input on show
		inputRef.current.focus();
	}, [props.menuStore.menus.menus]);

	const changeHandler = e => {
		const term = e.target.value.toLowerCase();
		setSearch(term);
		if(term.length < 3) {
			setResults([]);
			return;
		}
		const menus = {};
		props.menuStore.menus.forEach(menu => {
			menu.menuSections.forEach(section => {
				const matches = section.menuItems.filter(item => item.name.toLowerCase().includes(term));
				if(matches.length) {
					const newSection = {
						title: section.title,
						menuItems: matches
					};
					let mnu = menus[menu.title];
					// create a bare-minimum menu if one doesn't already exist...
					if(!mnu) {
						mnu = {
							title: menu.title,
							id: menu.menuId,
							sections: []
						};
						menus[menu.title] = mnu;
					}
					mnu.sections.push(newSection);
				}
			});
		});
		setResults(menus);
	}

	const renderMenuItems = (section, menuId) => {
		return section.menuItems.map(item => {
			const className = props.activeItemId === item.menuItemId && props.menuStore.loading === actionTypes.fetchItemRequest ? 'async' : '';
			return <MenuItem key={ item.menuItemId } className={ className } item={ item } menuId={ menuId } currencySymbol={ props.currencySymbol }/>
		});
	}

	const renderResults = () => {
		return Object.keys(results).map((menuTitle, mix) => {
			const menu = results[menuTitle];
			return (
				menu.sections.map((section, ix) => {
					return (
						<div key={ ix }>
							<div className="searchSectionHeading">
								{ menu.title } <span className="material-icons">keyboard_arrow_right</span> { section.title }
							</div>
							<ul className="Pane-items">
								{ renderMenuItems(section, menu.id) }
							</ul>
						</div>
					);
				})
			);
		});
	}

	return (
		<Pane id="menusSearchPane">

			<PaneHeader className="Pane-toolBar">
				<input ref={ inputRef } placeholder="Enter Search Term" type="search" className="MenusSearchPane-input" value={ search } onChange={ changeHandler } />
				<IconButton title="cancel search" icon="close" onClick={ props.close }/>
			</PaneHeader>
			<PaneBody className="MenuItems scrolling">
				{ renderResults() }
			</PaneBody>

		</Pane>
	);
};

export default MenusSearchPane;