import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { config } from 'store';

const usePrinterEvents = lostConnectionHandler => {
	const venue = useSelector(state => state.venues.venue);
	const [isBusy, setIsBusy] = useState(true);
	const [data, setData] = useState();
	const [event, setEvent] = useState();
	const [resetValue, setResetValue] = useState(Math.random());
	const dataRef = useRef(data);
	const verbose = false;

	const setPrinterData = data => {
		// this gets around the stale state problem of event handlers instantiated via useEffect
		// see https://stackoverflow.com/questions/55265255/react-usestate-hook-event-handler-using-initial-state
		setData(data);
		setIsBusy(false);
		dataRef.current = data;
	}


	useEffect(() => {
		const log = (...args) => {
			if(verbose) console.log(...args);
		}
	
		const eventHandler = evt => {
			const event = {
				type: evt.type,
				data: JSON.parse(evt.data)
			}
			if(verbose) console.log(event);
			setEvent(event);
			switch(event.type) {
				case 'onSubscribe': {
					setPrinterData(event.data);
					break;
				}
				case 'updatePrinterStatus': {
					const newData = { ...dataRef.current };
					newData.printers = newData.printers.map(printer => printer.printerId === event.data.printerId ? event.data : printer);
					setPrinterData(newData);
					break;
				}
				case 'updatePrintJob': {
					const newData = { ...dataRef.current };
					newData.printJobs = newData.printJobs.map(printJob => printJob.printerId === event.data.printerId && printJob.externalOrderId === event.data.externalOrderId ? event.data : printJob);
					setPrinterData(newData);
					break;
				}
				case 'addPrintJob': {
					const newData = { printers: dataRef.current.printers };
					newData.printJobs = [...dataRef.current.printJobs];
					newData.printJobs.push(event.data);
					setPrinterData(newData);
					break;
				}
				case 'removePrintJob': {
					const newData = { printers: dataRef.current.printers };
					newData.printJobs = dataRef.current.printJobs.filter(printJob => printJob.printerId !== event.data.printerId || printJob.externalOrderId !== event.data.externalOrderId);
					setPrinterData(newData);
					break;
				}
				default: break;
			}
		}

		const events = ['onSubscribe', 'updatePrinterStatus', 'updatePrintJob', 'addPrintJob', 'removePrintJob'];
		let source;
		if(venue) {
			const url = config.ssePath + '/venue/subscribe';
			source = new EventSource(url, { withCredentials: true });
			setIsBusy(true);
			if(source) {
				log('readyState:', source.readyState, venue);
				source.onopen = event => log('open', event, 'readyState:', source.readyState);
				source.onmessage = event => log('message:', event);
				source.onerror = event => {
					log('error:', event);
					if(source.readyState === 2 && lostConnectionHandler) {
						// if connection is closed (2) then attempt to reconnect...
						lostConnectionHandler(true);
					}
				}
				events.forEach(eventType => source.addEventListener(eventType, eventHandler, false));
			} else {
				console.error('call to EventSource failed');
			}
		}
		return () => {
			if(source && venue) {
				events.forEach(eventType => source.removeEventListener(eventType, eventHandler, false));
				log('closing SSE connection...', venue);
				source.close();
				source = undefined;
			}
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [venue, resetValue, verbose]);

	const reset = () => {
		// consumers can call this to force reopening the existing connection
		setResetValue(Math.random());
	}

	return {
		data,
		event,
		isBusy,
		reset
	}
}

export default usePrinterEvents;