import React from 'react';
import classNames from 'classnames';

const PhotoViewer = ({ show, photoURL, closeHandler }) => {

	const maskClassName = classNames('PhotoViewer-mask', { 'PhotoViewer-mask_show': show });

	if(!photoURL) return '';

	return (
		<div className={ maskClassName }>
			<button type="button" className="btn btn-close" onClick={ closeHandler }>
				<i className="material-icons">close</i>
			</button>
			<div className="PhotoViewer" style={{ backgroundImage: `url(${ photoURL?.replace('xxxx', 'full') })` }}/>
		</div>
	);
};

export default PhotoViewer;