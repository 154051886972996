import React, { Fragment } from 'react';
import { distributionTypes } from 'store/orders';
// general utilities service

const capitalise = str => {
	return str.charAt(0).toUpperCase() + str.slice(1);
}

function nodeIndex(node) {
	// utility method for finding index of a child node
	return Array.prototype.indexOf.call(node.parentNode.children, node);
}

function findNearest(array, value) {
	// returns array element nearest to value
	return array.reduce((accum, current) => {
		return(Math.abs(current - value) < Math.abs(accum - value) ? current : accum);
	});
}

const updateArray = (elements, updates, field) => {
	let updated = false;
	const updatedElements = elements.map(element => {
		const updatedElement = updates.find(update => update[field] === element[field]);
		if(updatedElement) {
			updated = true;
			return updatedElement;
		} else {
			return element;
		}
	});
	if(updated) return updatedElements;
}

function formatPrice(price, currencySymbol) {
	// takes a price is in 'pennies' (hence the '/ 100') and formats to 2 dec places, prefixing a currency symbol if supplied
	// improvements: needs to be locale sensitive when it comes to prefixing postfixing symbol, plus comma instead of period in some locales?
	price = parseFloat(price);
	if(isNaN(price)) {
		return '';
	} else {
		price = (price / 100).toString();
		const periodPos = price.indexOf('.');
		price = (periodPos === -1) ? price + '.00' : (price + '0').slice(0, periodPos + 3);
		return (currencySymbol ?? '') + price;
	}
}

const getDistributionTypeKey = distributionType => {
	/* eslint-disable no-fallthrough */
	switch (distributionType) {
		case distributionTypes.EAT_IN_WAITSTAFF: return 'TXT_DIST_TYPE_TABLE_NO';
		case distributionTypes.DELIVERY_PICTABITE:
		case distributionTypes.DELIVERY_RESTAURANT: return 'TXT_DIST_TYPE_DELIVERY';
		case distributionTypes.TAKE_OUT: return 'TXT_DIST_TYPE_TAKE_OUT';
		case distributionTypes.EAT_IN_SELF_SERVICE: return 'TXT_DIST_TYPE_EAT_IN';
		case distributionTypes.ROOM_SERVICE: return 'TXT_DIST_TYPE_ROOM_SERVICE';
		default: return '';
	}
	/* eslint-enable no-fallthrough */
}

const getOrderStatusKey = paymentStatus => {
	switch(paymentStatus) {
		case 'a': return 'TXT_ORDERSTATUS_UNSUBMITTED';
		case 'b': return 'TXT_ORDERSTATUS_UNPAID';
		case 'c': return 'TXT_ORDERSTATUS_PENDING_APPROVAL';
		case 'd': return 'TXT_ORDERSTATUS_DECLINED';
		case 'e': return 'TXT_ORDERSTATUS_ACCEPTED';
		case 'f': return 'TXT_ORDERSTATUS_PREPARING';
		case 'g': return 'TXT_ORDERSTATUS_READY';
		case 'h': return 'TXT_ORDERSTATUS_SERVED';
		case 'i': return 'TXT_ORDERSTATUS_WAITING_FOR_PICKUP';
		case 'j': return 'TXT_ORDERSTATUS_ON_DELIVERY';
		case 'k': return 'TXT_ORDERSTATUS_WAITING_FOR_RETURN';
		case 'l': return 'TXT_ORDERSTATUS_ON_RETURN';
		case 'm': return 'TXT_ORDERSTATUS_RETURNED';
		case 'n': return 'TXT_ORDERSTATUS_COMPLETED';
		case 'o': return 'TXT_ORDERSTATUS_CANCELLED';
		default: return '';
	}
}

const getPaymentStatusKey = paymentStatus => {
	switch(paymentStatus) {
		case 'a': return 'TXT_PAYMENT_UNPAID';
		case 'b': return 'TXT_PAYMENT_ATTEMPTED';
		case 'c': return 'TXT_PAYMENT_DECLINED';
		case 'd': return 'TXT_PAYMENT_ACCEPTED';
		case 'e': return 'TXT_PAYMENT_CANCELLED';
		case 'f': return 'TXT_PAYMENT_FAILED';
		case 'g': return 'TXT_PAYMENT_PARTIALLY_REFUNDED';
		case 'h': return 'TXT_PAYMENT_FULLY_REFUNDED';
		case 'i': return 'TXT_PAYMENT_REFUND_FAILED';
		case 'A': return 'TXT_PAYOUT_CANCELLED';
		case 'B': return 'TXT_PAYOUT_FAILED';
		case 'C': return 'TXT_PAYOUT_RECEIVED';
		default: return '';
	}
}

function sortByField(data, field) {
	data.sort((entry1, entry2) => {
		let title1 = entry1[field].toLowerCase();
		let title2 = entry2[field].toLowerCase();
		if(title1 > title2) return 1;
		if(title1 < title2) return -1;
		return 0;
	});
}

function processFormControl(event, field, value, label) {
	if(field) {
		// preprocessed fields and values come from some controls (DateControl, DropdownControl)
		value = integerCheck(value);
	} else {
		field = event.target.name;
		if(event.target.type === 'checkbox') {
			value = event.target.checked;
		} else {
			value = typeCheck(field, event.target.value);
		}
	}
	return {
		field: field,
		value: value
	}
}

function typeCheck(field, value) {
	// identify fields that should contain numbers and process accordingly
	const numberFields = {
	}
	field = field.split('.')[0];
	if(numberFields[field]) {
		value = parseFloat(value);
	}
	return value;
}

function integerCheck(number) {
	// a dropdown's value is assumed to be an integer or a string
	return number === null || isNaN(number) ? number : parseInt(number, 10);
}

function getNextOrderIndex(list) {
	// list is a collection of objects that each have an orderIndex property
	let nextOrderIndex = 0;
	list.forEach(item => {
		nextOrderIndex = Math.max(nextOrderIndex, item.orderIndex);
	});
	return nextOrderIndex + 1;
}

function validate(data, rules) {
	let errors = null;
	Object.keys(rules).forEach(rule => {
		// only does "required" for now
		if(typeof rules[rule] === 'boolean') {
			if(!data[rule]) {
				if(!errors) errors = {};
				errors[rule] = 'this field is required';
			}
		}
	});
	return errors;
}

function parseParameters(params, defaultParams) {
	const ret = {
		menuId: null,
		itemId: null
	}
	if(!params) return defaultParams || ret;
	const parts = params.split(':');
	ret.menuId = parts[0] ? parseInt(parts[0], 10) : null;
	ret.itemId = parts[1] ? parseInt(parts[1], 10) : null;
	return ret;
}

// I don't think we need this now as covered by user.hasPrivilegedAccess
// function canSearchVenues(userType) {
// 	return userType === 'Admin' || userType === 'DataEntry' || (userType === 'Tester' && process.env.NODE_ENV !== 'production');
// }

function isInViewport(element) {
	const rect = element.getBoundingClientRect();
	return (
			rect.top >= 0
				&& rect.left >= 0
				&& rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
				&& rect.right <= (window.innerWidth || document.documentElement.clientWidth)
	);
}

const isPhoneOrStandalone = () => {
	// return false;
	return window.matchMedia("only screen and (max-width: 760px)").matches || navigator.standalone;
}

function getText(id, ...args) {
	// this is a start until I figure out L10n;
	/* eslint-disable no-template-curly-in-string, no-eval */
	const str = strings[id];
	try {
		if(str && str.indexOf('args') !== -1) {
			// weirdly, this makes the eval line below work - I think it has to do with how babel transpiles this (I'll de changing this at some point pre-live)
			// eslint-disable-next-line no-unused-vars
			const doNothing = args;
		}
		const text = eval('`' + (str || id) + '`');
		return text;
	} catch (error) {
		console.error(error);
		return id;
	}
}

function getTextWithJsx(id, ...args) {
	const str = strings[id];
	if(!str) {
		console.error('getText failed to find match for', id);
		return null;
	}
	const regex = /(.*?)\${\s*args\[(\d+)\]\s*}/gm;
	let match, lastLastIndex;
	const chunks = [];
	while((match = regex.exec(str)) !== null) {
		if(match.index === regex.lastIndex) regex.lastIndex++;
		chunks.push(match[1]);	// add text before the component
		chunks.push(args[match[2]]);	// add the "thing" passed as an argument (designed to be a jsx component but can be anything really)
		lastLastIndex = regex.lastIndex;
	}
	if(lastLastIndex !== str.length) chunks.push(str.substring(lastLastIndex));	// add any trailing text
	// now we need to return it in a way react can render it...
	return chunks.map((el, ix) => <Fragment key={ ix }>{ el }</Fragment>);
}

const download = (path, filename) => {
	// see https://attacomsian.com/blog/javascript-download-file
	const anchor = document.createElement('a');
	anchor.href = path;
	anchor.download = filename;
	document.body.appendChild(anchor);
	anchor.click();
	document.body.removeChild(anchor);
}

export default {
	capitalise,
	download,
	findNearest,
	updateArray,
	formatPrice,
	nodeIndex,
	processFormControl,
	sortByField,
	getNextOrderIndex,
	getDistributionTypeKey,
	getOrderStatusKey,
	getPaymentStatusKey,
	validate,
	parseParameters,
	// canSearchVenues,
	isInViewport,
	isPhoneOrStandalone,
	getText,
	getTextWithJsx
};

const strings = {
	// validation:
	TTP_VALIDATION_REQUIRED: 'this field is required',
	TTP_VALIDATION_MUST_BE_POSITIVE: 'this field must be greater than zero',

	TTP_DELETE: 'delete',
	TTP_ERROR: 'error',
	TTP_INFO: 'info',
	TTP_SAVE: 'save',
	TTP_WARNING: 'warning',
	LBL_CANCEL: 'Cancel',
	LBL_CLOSE: 'Close',
	LBL_DELETE: 'Delete',
	LBL_OK: 'Ok',
	LBL_SAVE: 'Save',
	HDG_CONFIRM_DELETE: 'Delete ${ args[0] }',
	TXT_CONFIRM_DELETE: 'Are you sure you want to delete this ${ args[0] }?',
	HDG_CONFIRM_REMOVE: 'Remove ${ args[0] }',
	TXT_CONFIRM_REMOVE: 'Are you sure you want to remove this ${ args[0] }?',
	HDG_CONFIRM_MAP_MOVED: 'Confirm Map Movement',
	TXT_CONFIRM_MAP_MOVED: 'The map has been recentered.<br/>if this was unintentional, select CANCEL to ignore changed map position.<br/>If the moved map pin more accurately represents your venue location, select OK.',	// ***

	TXT_VALIDATION: 'Please complete all the highlighted fields then try again.',
	TXT_NO_DATA: 'No ${ args[0] } data to display.',
	HDG_UNSAVED_CHANGES: 'Unsaved Changes',
	TXT_UNSAVED_CHANGES: 'Some changes have not been saved.<br/>Select OK to abandon changes or CANCEL to continue editing.',
	TXT_UNAUTHORISED_ACCESS: 'Unauthorized access',	// ***
	HDG_ERROR: 'Oops!',
	LBL_USERNAME: 'Username',
	LBL_PASSWORD: 'Password',
	LBL_REMEMBER_ME: 'Remember me',
	LBL_SIGNIN: 'Sign in',
	LBL_SIGNOUT: 'Sign out',
	TXT_CONFIRM_SIGNOUT: 'Are you sure you want to sign out?',
	HDG_RESET_PASSWORD: 'Reset Password',
	HDG_CHANGE_PASSWORD: 'Change Password',
	TXT_INCORRECT_PASSWORD: 'Incorrect password.',
	TXT_RESET_PASSWORD: 'If you\'ve forgotten your password, please use the SEND EMAIL button below to request a password reset email to be sent to the following email address:',
	LBL_SEND_EMAIL: 'Send Email',

	ERR_WRONG_EXTENSION: "'${ args[0] }' didn't upload – we can only accept .jpg or .jpeg files.",
	LBL_ZOOM: "zoom in / out",
	TXT_UPLOAD_LOGO: '<p>Branded search results are at least <strong>twice as effective</strong> at attracting new customers.</p><p>Listings with logos may appear first in searches.</p>',
	TXT_UPLOAD_VENUE_PHOTO: '<p>Upload interior and exterior photos to showcase your venue.</p><p>Restaurants with photos are <strong>three to four times more effective</strong> at attracting customers.</p>',
	TXT_UPLOAD_FEATURED_PHOTO: '<p>Upload a photo to represent your venue in search results.</p><p>Frame some of your signature dishes in the same shot to communicate to customers your particular style and cuisine.</p>',

	LBL_POS_TYPE: 'POS type',
	LBL_CARRYOUT_ORDER_TYPE_ID: 'Carryout order type id',
	LBL_DELIVERY_ORDER_TYPE_ID: 'Delivery order type id',
	LBL_INHOUSE_ORDER_TYPE_ID: 'In-house order type id',
	LBL_EMPLOYEE_ID: 'Employee id',
	LBL_LOCATION_ID: 'Location id',
	LBL_REVENUE_CENTER_ID: 'Revenue center id',	// ***
	LBL_POS_INTEGRATION_ENABLED: 'POS integration enabled',

	TTP_DELETE_SELECTED_PHOTO: 'delete selected photo',
	TTP_VIEW_SELECTED_PHOTO: 'view selected photo',
	TTP_MAKE_FEATURED_PHOTO: 'set selected as featured photo',

	// ORDERS > LIST

	TXT_EAT_IN: 'EAT IN',
	TXT_TAKE_OUT: 'TAKE OUT',
	TXT_DELIVERY: 'DELIVERY',
	LBL_TOGGLE_FULLSCREEN: 'maximize / minimize',	// ***
	LBL_TOGGLE_PRICES: 'show / hide price details',
	LBL_UNDO: 'Undo',
	LBL_ORDER_CANCEL: 'Cancel order',
	TXT_ORDER_CANCEL: 'Are you sure you want to cancel this order and refund the customer?',
	PLH_CUSTOMER_MSG: 'Message for Customer (optional)',
	LBL_ORDER_MODIFY: 'Modify order',
	LBL_ORDER_REPRINT: 'Reprint order',
	TXT_ORDER_MODIFY: 'The customer will be notified to review and modify any order changes on their device before resubmitting payment. Any prior changes for this order will be refunded.',
	LBL_ORDER_ITEM: 'Order item',
	TTP_ADD_ITEM: 'add item',
	LBL_MARK_ITEMS: 'Mark removed items as out of stock',
	TXT_MARK_HELP: 'Other customers won\'t be able to order those items until you mark them as \'In stock\' again (via MENU)',
	TXT_TAG: 'tag',
	TXT_CATEGORY: 'category',

	HDG_STATUS: 'Status',
		LBL_DISABLED: 'disabled',
		LBL_ENABLED: 'enabled',
		TXT_PROCESSED_BY: 'Payments processed by',
		TXT_ORDERS_WHEN: 'Orders will be accepted automatically during the configured <a href="${ args[0] }">Opening Hours</a>',
		TXT_MARK_OUT_OF_STOCK: 'Mark menu items as out of stock when required to avoid receiving orders that cannot be fulfilled',

	HDG_ORDERTYPES: 'Order Types',
		HDG_IN_RESTAURANT_ORDERS: 'In-restaurant orders',
		TXT_NO_DINING_IN: 'No facilities for dining in.',
		LBL_SELF_SERVICE: 'Self-service',
		TXT_SELF_SERVICE: 'No busser required. Patrons receive a notification when their order leaves the kitchen and collect it themselves over the counter.',
		LBL_WAITSTAFF: 'Waitstaff',
		TXT_WAITSTAFF: 'Patrons incude their table number with the order. Your staff busses the food at the table when ready.',
		HDG_TAKEOUT_ORDERS: 'Take-Out Orders (to go)',
		LBL_TAKEOUT_ORDERS: 'Take-out orders',
		TXT_TAKEOUT_ORDERS: 'Patrons receive a notification when their order leaves the kitchen and collect it themselves over the counter for consumption outside the restaurant.',
		HDG_DELIVERY: 'Delivery',
		TXT_NO_DELIVERY: 'Order delivery is not available.',
		LBL_PB_DELIVERS: 'Pictabite delivers',
		TXT_PB_DELIVERS: 'Orders are delivered by Pictabite or our partner network.',
		LBL_RESTAURANT_DELIVERS: 'Restaurant delivers',
		TXT_RESTAURANT_DELIVERS: 'Orders are delivered by the restaurant or restaurant partner.',
		OPT_FREE_DELIVERY: 'Free delivery',
		OPT_FREE_ABOVE_SIZE: 'Free above order size',
		OPT_FLAT_FEE: 'Flat fee',
		OPT_FLAT_FEE_4_SMALLER: 'Flat fee for smaller orders',
		OPT_UNAVAILABLE_4_SMALLER: 'Delivery unavailable for smaller orders',
		HDG_ROOM_SERVICE: 'Room Service (for Hotels)',
		LBL_ROOM_SERVICE: 'In-room service',
		TXT_ROOM_SERVICE: 'Guests include their room number with the order. Your staff busses the food to the room when ready.',
		// we've altered the design so conversion no longer necessary...
		// OPT_MILES: 'Miles (m)',
		// OPT_KM: 'Kilometres (km)',
		// PLH_DELIVERY_RANGE: 'Delivery Range',
		// PLH_SELECT_DELIVERY_RANGE: 'Select Delivery Range',
		LBL_SELECT_DELIVERY_RANGE: 'Maximum delivery range',
		PLH_SELECT_DELIVERY_CHARGE: 'Select Delivery Charge',
		PLH_MIN_ORDER_SIZE: 'Minimum Order Size',
		PLH_DELIVERY_CHARGE: 'Delivery Charge',
		PLH_ACTION_BELOW: 'Action when below delivery size',
		LBL_ESTD_DELIVERY_TIME: 'Estimated time to deliver (in minutes)',
		HDG_PLEASE_CONFIRM: 'Please confirm',
		TXT_DELIVERY_0_WARNING: 'A delivery range of zero will disable ordering - are you sure?',

	HDG_ORDERFLOW: 'Order Flow',
		LBL_CLOSE_ON_LEAVE_KITCHEN: 'Close orders when they leave the kitchen',
		TXT_COOKING: 'Cooking',
		TXT_SERVICE: 'Service',
		TXT_COMPLETED: 'Completed',
		TXT_SERVICE_NOT_TRACKED: 'Service to the table is not tracked; the order is marked completed as soon as it leaves the Kitchen screen.',
		LBL_CLOSE_LATER: 'Close orders after service / collection',
		TXT_SERVICE_TRACKED: 'Service to the table (or collection) is tracked explicitly. Orders are bumped from the Kitchen screen to the Service screen. The order is marked completed when it is bumped off the Service screen.',

	HDG_BILLING: 'Billing',
		LBL_PAYMENTS_BY_POS: 'Payments are processed by an integrated POS',
		TXT_PAYMENTS_BY_POS: 'Configurable in ORDERS > SETTINGS > POS Integration',
		LBL_PAYMENTS_BY_PICTABITE: 'Payments are processed by Pictabite',
		LBL_PRICES_INCLUDE_TAX: 'Menu prices include sales tax / VAT',
		LBL_STRIPE_ACCOUNT_ID: 'Stripe connected account id',
		LBL_CONNECT_STRIPE: 'Connect with Stripe',
		LBL_LEGAL_NAME: 'Legal name',
		LBL_TAX_ID: 'Tax id',
		// LBL_ACCOUNT_NO: 'Bank account number',
		// LBL_ROUTING_NO: 'Bank routing number',
		// LBL_ACCOUNT_LINKED_EMAIL: 'Account linked email address',
		// LBL_ACCOUNT_LINKED_MOBILE: 'Account linked mobile number',	// *** cell number for US?
		LBL_PERCENT_FEE: 'Pictabite % fee',
		LBL_FLAT_FEE: 'Pictabite flat fee',
		LBL_ON_PREM: 'On prem',
		LBL_OFF_PREM: 'Off prem',

		HDG_EXTRA_CHARGES: 'Extra Charges',
		PLH_ITEM_DESC: 'Item Description',
		PLH_SALES_TAX_CODE: 'Sales Tax Code',
		PLH_AMOUNT: 'Amount',
		LBL_FIXED_AMOUNT: 'Fixed ${ args[0] } amount',
		LBL_PERCENTAGE_OF_TOTAL: '% of the Order Items Total',
		LBL_IS_DISCOUNT: 'Discount',
		HDG_NEW_EXTRA_CHARGE: 'Add New Extra Charge',
		HDG_EDIT_EXTRA_CHARGE: 'Edit Extra Charge',
		TXT_NEG_4_DISCOUNTS: 'Use negative values for discounts',

	HDG_LOYALTY_REWARDS: 'Loyalty Rewards',
		LBL_NO_REWARDS: 'No Loyalty Rewards',
		TXT_NO_REWARDS: 'Enabling Loyalty Rewards significantly increases the volume of returning patrons who self-order, helping you reduce waitstaff costs and increase table turns.',
		LBL_ORDERCOUNT_REWARDS: 'Order-count Rewards',
			TXT_LOVE_FREE: 'patrons love the word free',
			TXT_SIMPLE: 'simple to understand',
			LBL_NEXT_IS_FREE: 'If you self-order ${ args[0] } times, your next order is free',
			LBL_REWARDS_WHEN_OVER: 'Only orders above ${ args[0] } count toward rewards',
			LBL_FREE_CAPPED: 'The discount of the free order is capped at ${ args[0] }',
		LBL_POINTBASED_REWARDS: 'Points-based Rewards',
			TXT_PROPORTONAL_2_AMOUNT: 'discount proportional to amount spent',
			LBL_ACCUMULATE_POINTS: 'Accumulate ${ args[0] } points by self-ordering to receive a discount of ${ args[1] } on your next self-order',
			LBL_POINTS_EARNED: 'One point is earned for every ${ args[0] } spent',
		LBL_MIXEDSTRATEGY_REWARDS: 'Mixed-strategy Rewards',
			TXT_HIGHEST_INCENTIVE: 'highest incentive to spend more per reward cycle',
			LBL_ACCUMULATE_DISCOUNT: 'Accumulate ${ args[0] } points by self-ordering to receive a discount of ${ args[1] } on your next self-order',
			LBL_DISCOUNT_WITH_SPEND: 'You earn ${ args[0] }1 of discount for every ${ args[1] } you spend',
			LBL_DISCOUNT_EXAMPLE: 'For example, an order of ${ args[0] }28 accumulates ${ args[1] } towards your next reward',
	HDG_POS_INTEGRATION: 'POS Integration',
	HDG_PRINTERS: 'Printers',
		TXT_PRINTER: 'printer',
		TTP_PRINTER_ADD: 'add printer',
		LBL_PRINTER_FAMILY: 'Printer family',
		LBL_PRINTER_MODEL: 'Printer model',
		LBL_PRINTER_ID: 'Printer id',
		LBL_PRINTER_NAME: 'Printer name',
		LBL_PRINTER_ENABLED: 'Enabled',
		LBL_PRINTER_TYPE: 'Printer type',
		LBL_PRINT_JOB_URL: 'Server 1 url',
		LBL_PRINT_INTERVAL: 'Interval (seconds)',
		LBL_PRINT_STATUS_URL: 'url',
		TXT_CONFIRM_DELETE_DELETE_PRINTER: '<p>You are permanently removing this printer from this venue.<br/>Any associated print jobs will also be deleted.</p><p>Are you sure you\'d like to continue?</p><strong>${ args[0] }</strong>',
		HDG_CONNECTIVITY_TIPS: 'Connectivity tips',
		TXT_CONNECTIVITY_TIPS: `
<ul class="bulletedList">
	<li>If a LAN cable is connected, then wireless LAN is disabled.</li>
	<li>Avoid using the same WiFi channel that is used in neighboring shops where Wireless LAN is used.</li>
	<li>Wireless LANs with a frequency band of 2.4 GHz interfere with Bluetooth communication.</li>
	<li>When using Bluetooth and WiFi at the same time, we recommend using 5 GHz.</li>
	<li>When using the printer in environments where kitchen microwave ovens or other devices that may interfere with radio waves are installed, observe the following points:
		<ul class="bulletedList">
			<li>Keep the printer away from devices (such as kitchen microwave ovens) that may cause radio wave interference.</li>
			<li>Use channels that are away from frequency bands that may cause radio wave interference.</li>
			<li>Place shields between the printer and any devices that may cause radio wave interference.</li>
			<li>Select either 2.4 GHz or 5 GHz, whichever is free from radio wave interference.</li>
			<li>In auto channel setting for the WiFi access point, do not select a channel in which the devices may cause radio wave interference.</li>
			<li>In the infrastructure mode, W53 and W56 channels are not available to connect to a stealth SSID access point.</li>
		</ul>
	</li>
</ul>`,
		HDG_ORDER: 'Order',
		HDG_JOB_STATUS: 'Job Status',
		HDG_STATUS_TIMESTAMP: 'Status Timestamp',
		HDG_RETRIES: 'Retries',
	HDG_WIFI: 'WiFi',

	// tokens for order setting api messages
	ERR_INCOMPLETE_VENUE_ADDRESS: 'The venue\'s address is not complete',
	ERR_MISSING_TIMEZONE: 'Timezone has not beed specified',
	ERR_MISSING_OPEN_HOURS: 'The Opening Hours have not been specified',
	ERR_MISSING_MENU: 'The venue has no menu',
	ERR_MISSING_ORDER_TYPES: 'The venue is not configured to accept any Order Type (eat-in, take-out, delivery)',
	ERR_MISSING_DELIVERY_RANGE: 'The venue is configured for self-managed delivery but the specified Delivery Range is \'zero miles\'',
	ERR_MISSING_DELIVERY_TIME: 'The venue is configured for self-managed delivery but the specified Delivery Time Estimate is \'zero\'',
	ERR_MISSING_POS_CONFIG: 'Missing POS Configuration',
	ERR_INCOMPLETE_BILLING_SETTINGS: 'Billing settings have not been completed',
	ERR_MISSING_AVATAX_ONBOARDING: 'The venue has not been onboarded to AvaTax',
	ERR_MISSING_STRIPE_ONBOARDING: 'The venue has not been onboarded to Stripe',
	ERR_MENU_ITEM_MISSING_PRICE_OR_TAXCODE: 'Some Menu Items are missing Sales Tax Codes or Prices',
	ERR_MODIF_ITEM_MISSING_PRICE_OR_TAXCODE: 'Some Modifier Items are missing Sales Tax Codes or Prices',
	ERR_FIXED_INV_ITEMS_MISSING_TAXCODE: 'Some Fixed Invoice Items are missing Sales Tax Codes',
	ERR_ORDERING_DISABLED: 'Ordering has been disabled for this venue',
	ERR_MISSING_PBFEE_ONPREMISE: 'Missing PBFee-onPremise configuration, please contact Customer Support',
	ERR_MISSING_PBFEE_OFFPREMISE: 'Missing PBFee-offPremise configuration, please contact Customer Support',
	ERR_MISSING_WIFI_SETTINGS: 'Missing WiFi Settings, please contact Customer Support',

	ERR_TODO_1: 'TODO: missing invoicing implementation for processing payments via 3rd-party POS',
	WRN_NO_PRINTER_ENABLED: 'There is no printer enabled for the venue',

	HDG_VENUE_SELECTOR: 'Venue Selector',
	HDG_VENUE_SEARCH: 'Venue Search',
	LBL_ADD_VENUE: 'Add New Venue',
	TXT_NO_VENUES: 'No venues available',

	HDG_MENU_PROVIDER: 'Menu Provider',
	LBL_PROVIDER: 'Provider:',
	LBL_ATTRIBUTION_LINK: 'Attribution Link:',
	LBL_ATTRIBUTION_TEXT: 'Attribution Text:',
	LBL_CLEAR_PROVIDER: 'Clear 3rd Party Provider',

	LBL_VENUES: 'Venues',
	LBL_OUTPUT: 'Output'

};