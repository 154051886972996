import { useState } from 'react';
import utilities from 'services/utilities';
import { Pane, PaneHeader, PaneBody, PaneItem } from 'components/Pane';
import MenuProvider from './settings/MenuProvider';

const Settings = props => {

	const [setting, setSetting] = useState(0);

	const onClick = ix => {
		setSetting(ix);
	}

	return (
		<div className="OrderSettings row gutters-5 gutters-xlu-15 animated fadeIn max-width-1200">

			<div className="col-md-3">

				<Pane id="menuSettings">
					<PaneHeader>Setting</PaneHeader>
					<PaneBody tag="ul" className="Pane-items mb5">
						{ options.map((option, ix) => {
							return <PaneItem key={ ix } index={ ix } selected={ ix === setting } onClick={ onClick }>{ utilities.getText(option) }</PaneItem>
						}) }
					</PaneBody>
				</Pane>

			</div>
			<div className="col-md-9">

				{ options[setting] === 'HDG_MENU_PROVIDER' && <MenuProvider/> }

			</div>
		</div>
	);

}

export default Settings;

const options = ['HDG_MENU_PROVIDER'];