// unresolved bug in that changing no of columns messes up the page size calculation - not sure why yet
import { Fragment, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import sound from 'services/sound';
import utilities from 'services/utilities';
import useOrderEvents from 'store/OrderEvents';
import { errorsActionCreators } from 'store/errors';
import { ordersActionCreators } from 'store/orders';
import { settingsActionCreators } from 'store/settings';
import { hasVenue, venuesActionCreators } from 'store/venues';
import useL10n from 'L10n';
import useForm from 'components/Form';
import ValidationMessage from 'components/ValidationMessage';
import LostConnectionDialog from 'dialogs/LostConnectionDialog';
import OrderCancelDialog from './dialogs/OrderCancelDialog';
import Orders from './Orders';
import OrdersFooter from './OrdersFooter';
import OrderModifyProcessContainer from './OrderModifyProcessContainer';

const OrdersContainer = props => {
	const _t = useL10n().getText;
	const containerRef = useRef();
	const [showLostConnectionDialog, setShowLostConnectionDialog] = useState();
	const orderStore = useSelector(state => state.orders);
	const settingStore = useSelector(state => state.settings);
	const venue = useSelector(state => state.venues.venue);
	const currencySymbol = venue?.currencySymbol;
	const timeZone = venue?.openingHours?.timezone.timezone;
	const station = /([a-z]+)\/$/.exec(window.location.pathname)[1];

	const [currentOrder, setCurrentOrder] = useState();
	const [showOrderCancelDialog, setShowOrderCancelDialog] = useState(false);
	const [orderModifyProcessRunning, setOrderModifyProcessRunning] = useState(false);
	const [pages, setPages] = useState([{ page: 1, offset: 0 }]);

	const dispatch = useDispatch();

	const form = useForm(null, null, {
		columns: settingStore.orderColumns[station] ?? 5,
		priority: settingStore.orderPriority[station] ?? 20,
		rush: settingStore.orderRush[station] ?? 30,
		page: 1
	});
	const formRef = useRef(form);

	const lostConnectionHandler = lost => {
		setShowLostConnectionDialog(true);
	}

	const setOrders = data => {
		dispatch(ordersActionCreators.setOrders(data, settingStore.orderGroupingMinutes, station));
	}

	const setErrors = errors => {
		dispatch(errorsActionCreators.setErrors(errors));
	}

	const orderEvents = useOrderEvents({
		station: station,
		settings: settingStore,
		orderData: orderStore.orderData,
		venue: venue,
		setOrders,
		setErrors,
		lostConnectionHandler
	});

	useEffect(() => {
		if(venue) {
			// reset to page 1 if venue changes
			formRef.current.onChange(null, 'page', 1);
		}
		if(!venue && hasVenue()) {
			// this page has no onload api call, so if the page is refreshed, we need to fetch the venue
			dispatch(venuesActionCreators.fetchVenue());
		}
	}, [venue, dispatch]);

	useEffect(() => {
		// start by clearing any existing orders in the store...
		dispatch(ordersActionCreators.setOrders({ orders: [] }, settingStore.orderGroupingMinutes));
	}, [dispatch, settingStore.orderGroupingMinutes]);

	useEffect(() => {
		if(orderStore.orderData) {
			orderEvents.setOrderData(orderStore.orderData);
		}
	}, [orderStore.orderData, orderEvents]);

	useEffect(() => {
		if(settingStore.soundEnabled) {
			sound.play(0, () => {
				dispatch(settingsActionCreators.updateSetting('soundEnabled', false));
			});
		}
		return () => {
			// sound.pause();
		}
	}, [settingStore, dispatch]);

	useEffect(() => {
		// keep pages array up-to-date based on what is rendered visibly onscreen
		if(orderStore.orderData?.orders.length && containerRef.current) {
			const children = containerRef.current.querySelectorAll('.order');
			const newPages = pages.slice(0, form.data.page);	// discard any previous data about "future" pages in case we get SSE updates, these can then be recalculated (note only recalculates when paging forward - paging back always relies on existing pages data as no way to guess what he 1st order to display should be)
			let pageSize = 0;
			children.forEach((node, ix) => {
				const isVisible = utilities.isInViewport(node, ix);
				if(isVisible) {
					pageSize++;
					// console.log(node.innerText.substr(0, 2), ix, pageSize);
				}
				if(pageSize < children.length) {
					// there are still orders to render, so check if we need to add another page
					if(!isVisible && newPages[newPages.length - 1].page === form.data.page) {
						newPages.push({
							page: form.data.page + 1,
							offset: newPages[newPages.length - 1].offset + pageSize
						});
					}
				}
			});
			setPages(newPages);
		}
	// mustn't run when pages state changes, so...
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [form.data.page, form.data.columns, orderStore.orderData]);

	// useEffect(() => {
	// 	// if columns changes, reset to page 1
	// 	form.onChange(null, 'page', 1);
	// // eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [form.data.columns]);

	const bumpHandler = index => {
		dispatch(ordersActionCreators.bumpOrders(index, orderStore.orderData.kitchenDisplayType));
	}

	const undoHandler = e => {
		dispatch(ordersActionCreators.undoBumpOrders(orderStore.orderData.kitchenDisplayType));
	}

	const hideOrderCancelDialog = payload => {
		// console.log('dialog returned:', payload);
		setShowOrderCancelDialog(false);
		if(payload) {
			payload.externalOrderId = currentOrder.externalOrderId;
			dispatch(ordersActionCreators.cancelOrder(payload));
		}
	}

	const orderCancelHandler = order => {
		setCurrentOrder(order);
		setShowOrderCancelDialog(true);
	}

	const orderModifyHandler = order => {
		// console.log('orderModifyHandler', order);
		setCurrentOrder(order);
		setOrderModifyProcessRunning(true);
	}

	return (
		<div className="animated fadeIn">

			{ timeZone
					?
						<Fragment>
							<Orders
								ref={ containerRef }
								orderData={ orderStore.orderData }
								form={ form }
								station={ station }
								pages={ pages }
								currencySymbol={ currencySymbol }
								timeZone={ timeZone }
								fullscreen={ settingStore.fullscreen }
								orderGroupingMinutes={ settingStore.orderGroupingMinutes }
								onBump={ bumpHandler }
								onOrderCancel={ orderCancelHandler }
								onOrderModify={ orderModifyHandler }
							/>

							<OrdersFooter
								form={ form }
								station={ station }
								pages={ pages }
								loading={ orderEvents.isBusy }
								fullscreen={ settingStore.fullscreen }
								undoHandler={ undoHandler }

								lastOrder={ orderStore.orderData?.orders[orderStore.orderData?.orders?.length - 1] }
							/>
						</Fragment>
					:
						<ValidationMessage
							open={ venue && !venue.openingHours?.timezone }
							message={ _t('TXT_TIMEZONE_MISSING', false, <Link to="/venues/locations/opening-hours">{ _t('TXT_OPENINGHOURS_LINK') }</Link>) }
						/>
			}

			<OrderModifyProcessContainer
				orderModifyProcessRunning={ orderModifyProcessRunning }
				order={ currentOrder }
				currencySymbol={ currencySymbol }
				setCurrentOrder={ setCurrentOrder }
				setOrderModifyProcessRunning={ setOrderModifyProcessRunning }
			/>

			<OrderCancelDialog
				orderId={ currentOrder?.externalOrderId }
				show={ showOrderCancelDialog }
				onHide={ hideOrderCancelDialog }
			/>

			<LostConnectionDialog
				show={ showLostConnectionDialog }
				onHide={ () => { setShowLostConnectionDialog(false ) }}
				reset={ orderEvents.reset }
			/>

		</div>
	);
};

export default OrdersContainer;