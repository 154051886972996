// note I am using useForm for menus and items but just working with section locally as it is just one field
// also, some controls have id defined otherwise their auto-assigned ids clash with MenuItemForm
import { useEffect, useRef, useState } from 'react';
import { Nav, NavItem, TabContent, TabPane } from 'reactstrap';
import MenuItem from 'models/MenuItem';
import utilities from 'services/utilities';
import useL10n from 'L10n';
import useForm from 'components/Form';
import { FormCheckbox, FormRadio, FormInput, FormTypeahead, FormInputWithButton, FormTimePicker } from 'components/formControls';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'components/Modal';
import ValidationMessage from 'components/ValidationMessage';
import UploaderPanel from 'components/UploaderPanel';
import { TextButton } from 'components/buttons';
import './AddEditDialog.scss';
import openingTimes from 'services/openingTimes';

const AddEditDialog = props => {
	const _t = useL10n().getText;
	const [activeTab, setActiveTab] = useState(0);
	const [confirmFooter, setConfirmFooter] = useState(false);
	const [section, setSection] = useState({ title: '' });
	const sections = props.menuStore.sections?.map(menuSection => menuSection.title);
	const itemFormContainer = useRef();

	const menuFormSave = () => {
		const payload = {};
		let datum;
		let el;
		try {
			Object.keys(menuForm.data).forEach(element => {
				el = element;	// in case there's an error
				switch(element) {
					case 'isAlwaysAvailable':
						if(menuForm.data.isAlwaysAvailable) {
							payload.availableFrom = 0;
							payload.availableUntil = 0;
						}
						break;
					case 'availableFromTime':
						if(menuForm.data.isAlwaysAvailable) break;
						datum = menuForm.data[element];
						if(datum) {
							datum = parseInt(openingTimes.formatTimeForPayload(datum));
							payload.availableFrom = datum;
						} else {
							payload.availableFrom = 0;
						}
						break;
					case 'availableUntilTime':
						if(menuForm.data.isAlwaysAvailable) break;
						datum = menuForm.data[element];
						if(datum) {
							datum = parseInt(openingTimes.formatTimeForPayload(datum));
							payload.availableUntil = datum;
						} else {
							payload.availableUntil = 0;
						}
						break;
					default: payload[element] = menuForm.data[element]; break;
				}
			});
			if((payload.availableFrom || payload.availableUntil) && (payload.availableUntil <= payload.availableFrom)) {
				throw Error('must be later than "From"');
			}
			props.saveEntity('menu', payload);
			props.onHide();
		} catch (error) {
			menuForm.setErrors({
				[el]: error
			});
		}
	}

	const menuForm = useForm(menuFormSave, menuValidationRules);
	const menuResetRef = useRef(menuForm.reset);
	
	const itemFormSave = () => {
		itemForm.data.isInStock = true;
		props.saveEntity('item', itemForm.data);
		let resetData = new MenuItem();
		const menuSectionTitle = itemForm.data.menuSectionTitle;
		const itemInputs = itemFormContainer.current.querySelectorAll('.form-control');
		if(menuSectionTitle) {
			resetData.menuSectionTitle = menuSectionTitle;	// preserve Menu Section for added usability
			itemInputs[1].focus();
		} else {
			itemInputs[0].focus();
		}
		// console.log('@@@@', itemForm.data, resetData);
		itemForm.reset(resetData);
	}

	const itemForm = useForm(itemFormSave, itemValidationRules);
	const itemFormRef = useRef(itemForm);

	useEffect(() => {
		if(props.salesTaxCode) {
			itemFormRef.current.onChange(null, 'salesTaxCode', props.salesTaxCode);
		}
	}, [props.salesTaxCode]);

	useEffect(() => {
		const formatTime = time => {
			time = '' + time;
			switch(time.length) {
				case 1: return '0:0' + time;
				case 2: return '00:' + time;
				case 3: return '0' + time.slice(0, 1) + ':' + time.slice(1);
				default: return time.slice(0, 2) + ':' + time.slice(2);
			}
		}

		if(props.show) {
			switch(props.details.tab) {
				case 'menu':
					setActiveTab(0);
					const isAlwaysAvailable = !(props.details.entity.availableFrom || props.details.entity.availableUntil);
					const menuData = {
						...props.details.entity,
						isAlwaysAvailable: isAlwaysAvailable,
						availableFromTime: isAlwaysAvailable ? '' : formatTime(props.details.entity.availableFrom),
						availableUntilTime: isAlwaysAvailable ? '' : formatTime(props.details.entity.availableUntil)
					};
					menuResetRef.current(menuData);
					break;
				case 'section':
					setActiveTab(1);
					setSection(props.details.entity);
					break;
				case 'item': setActiveTab(2); break;	// this currently never happens
				default: {	// 'all'
					// rather than setting the active tab, we let it open at whatever tab was last opened
					menuResetRef.current(menu);
					setSection({ title: '' });
					itemFormRef.current.reset(new MenuItem());
					if(props.menuStore.menus?.length === 0 && activeTab) {
						setActiveTab(0);
					}
					break;
				}
			}
		}
	// don't want this dependent on props.menuStore.menus.length or activeTab
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.show, props.details]);

	const tabSelect = e => {
		if(e.currentTarget.getAttribute('disabled') == null) {
			const tabIndex = utilities.nodeIndex(e.currentTarget);
			if(activeTab !== tabIndex) setActiveTab(tabIndex);
		}
	}

	const buttonClickHandler = e => {
		// add/update code goes here...
		switch(e.target.id) {
			case 'btnAdd':
			case 'btnUpdate':
				switch(activeTab) {
					case 0: menuForm.onSave(); break;
					case 1: props.saveEntity('section', section); props.onHide(); break;
					case 2: itemForm.onSave(); break;
					default: break;
				}
				break;
			case 'btnDelete':
				setConfirmFooter(true);
				break;
			case 'btnOk':
				switch(activeTab) {
					case 0: props.deleteEntity('menu', menuForm.data); break;
					case 1: props.deleteEntity('section', section); break;
					default: break;
				}
				setConfirmFooter(false);
				props.onHide();
				break;
			default:
				if(confirmFooter) {
					setConfirmFooter(false);
				} else {
					props.onHide(e.target.id);
				}
				break;
		}
	}

	const onSectionChange = e => {
		setSection({ ...props.details.entity, title: e.target.value });
	}

	return (
		<Modal className="AddEditDialog" show={ props.show } onHide={ props.onHide }>
			<ModalHeader className="Modal-header_nav">
				{ (props.details.tab === 'all' || props.details.tab === 'item') &&
					<Nav justified>
						<NavItem active={ activeTab === 0 } onClick={ tabSelect }>Add Menu</NavItem>
						<NavItem disabled={ props.menuStore.menus?.length === 0 } active={ activeTab === 1 } onClick={ tabSelect }>Add Menu Section</NavItem>
						<NavItem disabled={ props.menuStore.menus?.length === 0 } active={ activeTab === 2 } onClick={ tabSelect }>Add Menu Item</NavItem>
					</Nav>
				}
				{ props.details.tab === 'menu' &&
					<h1>Edit menu</h1>
				}
				{ props.details.tab === 'section' &&
					<h1>Edit section</h1>
				}
			</ModalHeader>
			<ModalBody padding={ true }>

				<TabContent activeTab={ '' + activeTab }>
					<TabPane tabId="0" className="animated fadeIn">

						<ValidationMessage open={ !!menuForm.errors }/>

						<div className="row mb10">
							<div className="col-sm-6">
								<FormInput form={ menuForm } field="title" placeholder="Menu Name"/>
						</div>
							<div className="col-sm-6">
								<FormRadio form={ menuForm } field="isVisible" value="1">
									Menu visible to customers
								</FormRadio>
								<FormRadio form={ menuForm } field="isVisible" value="0">
									Hide menu (out of season)
								</FormRadio>
							</div>
						</div>
						<div className="row mb10">
							<div className="col">
								<FormCheckbox form={ menuForm } field="displaysItemsOrderIndex">
									Menu displays dish no. <small>(for menus with numbered dishes)</small>
								</FormCheckbox>
								<FormCheckbox form={ menuForm } field="autoMarkItemsOutOfStock">
									Automatically mark menu items as unavailable when recipe ingredients are out of stock
								</FormCheckbox>
							</div>
						</div>

						<div className="form-row mb10">
							<div className="col-sm-auto">

								<FormCheckbox form={ menuForm } field="isAlwaysAvailable" disabled={ menuForm.isAlwaysAvailable }>
									Available all day
								</FormCheckbox>

							</div>
							<div className="col-sm">

								<FormTimePicker
									placeholder={ _t('PLH_FROM') }
									form={ menuForm }
									field="availableFromTime"
									disabled={ menuForm.data.isAlwaysAvailable }
									default="morning"
								/>

							</div>
							<div className="col-sm">

								<FormTimePicker
									placeholder={ _t('PLH_TO') }
									form={ menuForm }
									field="availableUntilTime"
									disabled={ menuForm.data.isAlwaysAvailable }
								/>

							</div>
						</div>

						<UploaderPanel id="menuUploader">
							Upload your menu (photos or PDFs); our system will attempt to extract the required information automatically
						</UploaderPanel>

					</TabPane>

					<TabPane tabId="1" className="animated fadeIn">

						<input placeholder="Section Title" className="form-control" value={ section.title } onChange={ onSectionChange }/>

					</TabPane>

					<TabPane tabId="2" className="animated fadeIn">

						<ValidationMessage open={ !!itemForm.errors }/>

						<div ref={ itemFormContainer } className="item-form">

							<FormTypeahead
								placeholder="Menu Section"
								form={ itemForm }
								field="menuSectionTitle"
								data={ sections }
								maxMatches={ 5 }
								minLength={ 2 }
								disabled={ props.disabled }
								highlight={ true }
							/>

							<FormInput placeholder="Menu Item" form={ itemForm } field="name" id="amiName" disabled={ props.disabled }/>

							{ !props.onSalesTaxButtonClick
									? <FormInput type="money" placeholder="Price" form={ itemForm } field="price" disabled={ props.disabled }/>
									: (
											<div className="row gutters-5">
												<div className="col">

													<FormInput type="money" placeholder="Price" form={ itemForm } field="price" disabled={ props.disabled }/>

												</div>
												<div className="col">

													<FormInputWithButton
														placeholder="Sales Tax Code"
														icon="more_horiz"
														form={ itemForm }
														field="salesTaxCode"
														id="amiSalesTaxCode"
														disabled={ props.disabled }
														onButtonClick={ props.onSalesTaxButtonClick }
													/>

												</div>
											</div>
										)
							}

							<FormInput placeholder="Calories" form={ itemForm } field="calories" id="amiCalories" disabled={ props.disabled }/>

							<FormInput type="textarea" placeholder="Description" form={ itemForm } field="dscr" disabled={ props.disabled }/>

						</div>

					</TabPane>
				</TabContent>

			</ModalBody>
			{ confirmFooter
				?
					<ModalFooter>

						<span className="Modal-footer-message">Are you sure you want to delete?</span>
						<TextButton id="btnCancel" className="btn-secondary focus" onClick={ buttonClickHandler }>Cancel</TextButton>
						<TextButton id="btnOk" className="btn-primary" onClick={ buttonClickHandler }>OK</TextButton>

					</ModalFooter>
				:
					<ModalFooter className="Modal-footer_buttons">

						<TextButton
							id="btnCancel"
							className="btn-secondary focus"
							async={ props.menuStore.loading }
							onClick={ buttonClickHandler }
						>
							Cancel
						</TextButton>

						<TextButton
							id="btnDelete"
							className="btn-primary"
							async={ props.menuStore.loading }
							hidden={ props.details.tab === 'all' || props.details.tab === 'item' }
							onClick={ buttonClickHandler }
						>
							Delete
						</TextButton>

						<TextButton
							id="btnUpdate"
							className="btn-primary"
							async={ props.menuStore.loading }
							hidden={ props.details.tab === 'all' || props.details.tab === 'item' }
							disabled={ props.details.tab === 'section' && !section.title }
							onClick={ buttonClickHandler }
						>
							Update
						</TextButton>

						<TextButton
							id="btnAdd"
							className="btn-primary"
							async={ props.menuStore.loading }
							hidden={ props.details.tab !== 'all' && props.details.tab !== 'item' }
							disabled={ activeTab === 1 && !section.title }
							onClick={ buttonClickHandler }
						>
							Add
							{ activeTab === 2 && '+NEXT' }
						</TextButton>

					</ModalFooter>
			}
		</Modal>
	);
};

export default AddEditDialog;

const itemValidationRules = {
	menuSectionTitle: true,
	name: true,
	price: true
}

const menuValidationRules = {
	title: true,
	isVisible: true
}

const menu = {
	title: null,
	isVisible: null,
	displaysItemsOrderIndex: 0,
	autoMarkItemsOutOfStock: 0,
	isAlwaysAvailable: true
}