import { useEffect } from 'react';
import useL10n from 'L10n';
import { FormInput, FormLabel } from 'components/formControls';
import useForm from 'components/Form';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'components/Modal';
import { AutoTextButton, TextButton } from 'components/buttons';
import utilities from 'services/utilities';

const StripeOnboardingDialog = props => {
	const _t = useL10n().getText;

	const formSave = () => {
		// props.onHide(form.data);
	}

	const form = useForm(formSave);

	useEffect(() => {
		if(props.show) {
			form.onChange(null, 'email', props.email);
		}
		// only want this to fire on props.show, so...
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.show]);

	return (
		<Modal id="stripeOnboardingDialog" show={ props.show } onHide={ props.onHide }>
			<ModalHeader>{ _t('HDG_EMAIL_LINK_DIALOG', true) }</ModalHeader>

			<ModalBody>

				<div className="form-row">
					<FormLabel htmlFor="email" className="col-sm-4" label="_EMAIL_ADDRESS"/>
					<div className="col-sm-8">
						<FormInput type="email" form={ form } field="email"/>
					</div>
					<p className="mt10 mb0">{ _t('TXT_SEND_ONBOARDING_EMAIL') }</p>
					{ !utilities.isPhoneOrStandalone() &&
						<p className="mt10 mb0">{ _t('TXT_OPEN_ONBOARDING_WINDOW') }</p>
					}
				</div>

			</ModalBody>

			<ModalFooter>

				<AutoTextButton className="btn-secondary focus" name="BTN_CANCEL" onClick={ props.onHide }/>
				<TextButton className="btn-primary" disabled={ !form.data.email } onClick={ () => props.onHide('BTN_SEND_EMAIL', form.data.email) }>{ _t('BTN_SEND_EMAIL') }</TextButton>
				{ !utilities.isPhoneOrStandalone() &&
					<AutoTextButton className="btn-primary" name="BTN_OPEN_STRIPE" onClick={ props.onHide }/>
				}

			</ModalFooter>

		</Modal>
	);
};

export default StripeOnboardingDialog;