import Pane1 from './Pane1';
import Pane2 from './Pane2';
import Pane3 from './Pane3';
import Pane4 from './Pane4';
import './Panes.scss';

const Panes = props => {
	return (
		<div id="panesContainer" className="animated fadeIn">

			<div className="row gutters-5 gutters-xlu-15">

				<div className="col-md"><Pane1/></div>

				<div className="col-md"><Pane2/></div>

				<div className="col-md"><Pane3/></div>

			</div>

			<div className="row">

				<div className="col-md"><Pane4/></div>

				<div className="col-md"><Pane4/></div>

				<div className="col-md"><Pane4/></div>

			</div>

		</div>
	);
};

export default Panes;