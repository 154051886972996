import React, { useEffect, useRef, useState } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { errorsActionCreators } from 'store/errors';
import { modifiersActionCreators } from 'store/modifiers';
import utilities from 'services/utilities';
import useForm from 'components/Form';
import RouterPrompt from 'components/RouterPrompt';
import AddModifierItemDialog from './AddModifierItemDialog';
import ModifierGroups from './ModifierGroups';

class ModifierGroup {
	id = null;
	name = '';
	retailName = '';
	orderIndex = null;
	modifierItems = [];
};

const ModifierGroupsContainer = props => {

	let { id } = useParams();
	id = id ? parseInt(id, 10) : null;
	const [showAddItemDialog, setShowAddItemDialog] = useState(false);
	const dispatch = useDispatch();
	const modifierStore = useSelector(state => state.modifiers);
	const venueStore = useSelector(state => state.venues);

	const formSave = () => {
		if(id) {
			dispatch(modifiersActionCreators.updateGroup(form.data));
		} else {
			form.data.orderIndex = utilities.getNextOrderIndex(modifierStore.groups);
			dispatch(modifiersActionCreators.addGroup(form.data));
		}
	}

	const validationRules = {
		name: true,
		retailName: true
	}

	const form = useForm(formSave, validationRules, new ModifierGroup());
	const resetRef = useRef(form.reset);

	// I am not sure exactly why I try loading the data on every page load - disabling for now - see also ModifiersContainer
	// useEffect(() => {
	// 	// fetch data
	// 	dispatch(modifiersActionCreators.fetchModifiers());
	// }, [dispatch]);

	useEffect(() => {
		// handle current group changes
		if(id) {
			if(modifierStore.groups && id) {
				const groupState = modifierStore.groups.find(group => group.id === id);
				// this gets complicated as if the id changes then we want to clear isDirty but if we've changed an item default we've already persisted this so it shouldn't affect the form - this is NOT a good design as why does changing the default get persisted?
				resetRef.current({ ...groupState }, form.data.id === id ? form.isDirty : false);
			}
		} else {
			resetRef.current(new ModifierGroup(), false);
		}
		// shouldn't be dependent on changes to form.data.id or form.isDirty
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [modifierStore.groups, id]);

	const onGroupDelete = group => {
		dispatch(modifiersActionCreators.deleteGroup(group));
	}

	const onAddClick = sourceId => {
		// TODO check form is dirty first
		if(sourceId === 'newGroup') {
			id = null;
			form.reset(new ModifierGroup());
		} else {
			setShowAddItemDialog(true);
		}
	}

	const shiftGroup = isUp => {
		dispatch(modifiersActionCreators.shiftGroup(form.data.id, isUp));
	}

	const onAddItemDialogHide = newItem => {
		setShowAddItemDialog(false);
		if(newItem) {
			if(form.data.modifierItems.findIndex(item => item.id === newItem.modifierItemId) === -1) {
				newItem.orderIndex = utilities.getNextOrderIndex(form.data.modifierItems);
				newItem.modifierGroupId = id;
				dispatch(modifiersActionCreators.addGroupItem(newItem));
			} else {
				const item = modifierStore.items.find(item => item.id === newItem.modifierItemId);
				dispatch(errorsActionCreators.setErrors(['"' + item.retailName + '" has already been added']));
			}
		}
	}

	const toggleItemDefault = item => {
		dispatch(modifiersActionCreators.updateGroupItem(form.data, item, false));
	}

	const itemRemoveHandler = itemId => {
		// console.log(itemId);
		const groupItem = {
			modifierGroupId: id,
			modifierItemId: itemId
		}
		dispatch(modifiersActionCreators.deleteGroupItem(groupItem));
	}

	const shiftItem = (id, isUp) => {
		dispatch(modifiersActionCreators.shiftGroupItem(form.data, id, isUp));
	}

	if(id && typeof form.data.id === 'undefined') {
		// looks like group has just been deleted so...
		return <Redirect to="./"/>
	} else {
		return (
			<div>

				<ModifierGroups
					form={ form }
					modifierStore={ modifierStore }
					currencySymbol={ venueStore.venue?.currencySymbol }
					onAddClick={ onAddClick }
					onGroupSave={ form.onSave }
					onGroupDelete={ onGroupDelete }
					onItemRemove={ itemRemoveHandler }
					shiftGroup={ shiftGroup }
					shiftItem={ shiftItem }
					toggleItemDefault={ toggleItemDefault }
				/>

				<AddModifierItemDialog show={ showAddItemDialog } modifierStore={ modifierStore } onHide={ onAddItemDialogHide }/>

				<RouterPrompt when={ form.isDirty }/>

			</div>
		);
	}
};

export default ModifierGroupsContainer;