import storeUtils from './storeUtils';
import utilities from '../services/utilities';

export const actionTypes = {
	billingFail: 'BILLING_FAIL',

	enableOrderingRequest: 'ENABLE_ORDERING_BILLING_REQUEST',
	enableOrderingSuccess: 'ENABLE_ORDERING_BILLING_SUCCESS',

	disableOrderingRequest: 'DISABLE_ORDERING_BILLING_REQUEST',
	disableOrderingSuccess: 'DISABLE_ORDERING_BILLING_SUCCESS',

	getOrderingStatusRequest: 'GET_ORDERINGSTATUS_BILLING_REQUEST',
	getOrderingStatusSuccess: 'GET_ORDERINGSTATUS_BILLING_SUCCESS',

	fetchBillingSettingsRequest: 'FETCH_BILLINGSETTINGS_BILLING_REQUEST',
	fetchBillingSettingsSuccess: 'FETCH_BILLINGSETTINGS_BILLING_SUCCESS',
	addUpdateBillingSettingsRequest: 'ADDUPDATE_BILLINGSETTINGS_BILLING_REQUEST',
	addUpdateBillingSettingsSuccess: 'ADDUPDATE_BILLINGSETTINGS_BILLING_SUCCESS',

	fetchFixedInvoiceItemsRequest: 'FETCH_FIXEDINVOICEITEM_BILLING_REQUEST',
	fetchFixedInvoiceItemsSuccess: 'FETCH_FIXEDINVOICEITEM_BILLING_SUCCESS',
	addFixedInvoiceItemRequest: 'ADD_FIXEDINVOICEITEM_BILLING_REQUEST',
	addFixedInvoiceItemSuccess: 'ADD_FIXEDINVOICEITEM_BILLING_SUCCESS',
	updateFixedInvoiceItemRequest: 'UPDATE_FIXEDINVOICEITEM_BILLING_REQUEST',
	updateFixedInvoiceItemSuccess: 'UPDATE_FIXEDINVOICEITEM_BILLING_SUCCESS',
	deleteFixedInvoiceItemRequest: 'DELETE_FIXEDINVOICEITEM_BILLING_REQUEST',
	deleteFixedInvoiceItemSuccess: 'DELETE_FIXEDINVOICEITEM_BILLING_SUCCESS',
	reorderFixedInvoiceItemsRequest: 'REORDER_FIXEDINVOICEITEMS_BILLING_REQUEST',
	reorderFixedInvoiceItemsSuccess: 'REORDER_FIXEDINVOICEITEMS_BILLING_SUCCESS',

	fetchSepaDirectDebitSetupKeyRequest: 'FETCH_SEPADIRECTDEBITSETUPKEY_BILLING_REQUEST',
	fetchSepaDirectDebitSetupKeySuccess: 'FETCH_SEPADIRECTDEBITSETUPKEY_BILLING_SUCCESS'
}

const initialState = { billingSettings: null, fixedInvoiceItems: null, status: null, SepaDirectDebitSetupKey: null, loading: null, errors: null };

export const billingActionCreators = {

	enableOrdering: () => async dispatch => {
		dispatch({ type: actionTypes.enableOrderingRequest });
		const response = await storeUtils.execute('billing', 'enableOrdering');
		if(response.errors) {
			dispatch({ type: actionTypes.billingFail, errors: response.errors });
		} else {
			// const fakeData = { errorKeys: [], warningKeys: [], orderingEnabled: 1 }
			dispatch({ type: actionTypes.enableOrderingSuccess, response: response });
		}
	},

	disableOrdering: () => async dispatch => {
		dispatch({ type: actionTypes.disableOrderingRequest });
		const response = await storeUtils.execute('billing', 'disableOrdering');
		if(response.errors) {
			dispatch({ type: actionTypes.billingFail, errors: response.errors });
		} else {
			response.orderingEnabled = false;
			response.preserveStatus = true;	// just a little trick so we don't lose the current status
			dispatch({ type: actionTypes.disableOrderingSuccess, response: response });
		}
	},

	getOrderingStatus: () => async dispatch => {
		dispatch({ type: actionTypes.getOrderingStatusRequest });
		const response = await storeUtils.execute('billing', 'getOrderingStatus');
		// handling from here is a little different as we have 2 very different types of errors possible - this is messy :(
		if(response.errors) {
			dispatch({ type: actionTypes.billingFail, errors: response.errors });
		} else {
			dispatch({ type: actionTypes.getOrderingStatusSuccess, response: response });
		}
	},

	fetchBillingSettings: (reload = false) => async (dispatch, getState) => {
		const billingState = getState().billing;
		if(!reload && billingState.billingSettings) {
			return;
		}
		dispatch({ type: actionTypes.fetchBillingSettingsRequest });
		const response = await storeUtils.execute('billing', 'getBillingSettings');
		if(response.errors) {
			dispatch({ type: actionTypes.billingFail, errors: response.errors });
		} else {
			// response.sepaMandateStatus = 'p';
			dispatch({ type: actionTypes.fetchBillingSettingsSuccess, response: response });
		}
	},

	addUpdateBillingSettings: billingSettings => async dispatch => {
		dispatch({ type: actionTypes.addUpdateBillingSettingsRequest });
		const response = await storeUtils.execute('billing', 'addUpdateBillingSettings', billingSettings);
		if(response.errors) {
			dispatch({ type: actionTypes.billingFail, errors: response.errors });
		} else {
			dispatch({ type: actionTypes.addUpdateBillingSettingsSuccess, billingSettings: billingSettings, response: response });
		}
	},

	fetchFixedInvoiceItems: () => async dispatch => {
		dispatch({ type: actionTypes.fetchFixedInvoiceItemsRequest });
		const response = await storeUtils.execute('billing', 'getFixedInvoiceItems');
		if(response.errors) {
			dispatch({ type: actionTypes.billingFail, errors: response.errors });
		} else {
			dispatch({ type: actionTypes.fetchFixedInvoiceItemsSuccess, response: response });
		}
	},

	addFixedInvoiceItem: fixedInvoiceItem => async (dispatch, getState) => {
		const billingState = getState().billing;
		dispatch({ type: actionTypes.addFixedInvoiceItemRequest });
		fixedInvoiceItem.orderIndex = utilities.getNextOrderIndex(billingState.fixedInvoiceItems);
		const response = await storeUtils.execute('billing', 'addFixedInvoiceItem', fixedInvoiceItem);
		if(response.errors) {
			dispatch({ type: actionTypes.billingFail, errors: response.errors });
		} else {
			fixedInvoiceItem.itemId = response.itemId;
			dispatch({ type: actionTypes.addFixedInvoiceItemSuccess, fixedInvoiceItem: fixedInvoiceItem, response: response });
		}
	},

	updateFixedInvoiceItem: fixedInvoiceItem => async dispatch => {
		dispatch({ type: actionTypes.updateFixedInvoiceItemRequest });
		const response = await storeUtils.execute('billing', 'updateFixedInvoiceItem', fixedInvoiceItem);	// there's a small design issue with this endpoint that means we need to pass an array for now
		if(response.errors) {
			// orderingEnabled can be set to 0 here - how to handle?
			dispatch({ type: actionTypes.billingFail, errors: response.errors });
		} else {
			dispatch({ type: actionTypes.updateFixedInvoiceItemSuccess, fixedInvoiceItem: fixedInvoiceItem, response: response });
		}
	},

	deleteFixedInvoiceItem: itemId => async dispatch => {
		dispatch({ type: actionTypes.deleteFixedInvoiceItemRequest });
		const response = await storeUtils.execute('billing', 'deleteFixedInvoiceItem', { itemId: itemId });
		if(response.errors) {
			dispatch({ type: actionTypes.billingFail, errors: response.errors });
		} else {
			dispatch({ type: actionTypes.deleteFixedInvoiceItemSuccess, itemId: itemId, response: response });
		}
	},

	shiftFixedInvoiceItem: (id, isUp) => async (dispatch, getState) => {
		const fixedInvoiceItems = getState().billing.fixedInvoiceItems;
		const request = storeUtils.getShiftElementRequest(fixedInvoiceItems, id, isUp, 'itemId');
		dispatch({ type: actionTypes.reorderFixedInvoiceItemsRequest });
		const response = await storeUtils.execute('billing', 'reorderFixedInvoiceItem', request);
		if(response.errors) {
			dispatch({ type: actionTypes.billingFail, errors: response.errors });
		} else {
			dispatch({ type: actionTypes.reorderFixedInvoiceItemsSuccess, orderIndexes: request.orderIndexes, response: response });
		}
	},

	fetchSepaDirectDebitSetupKey: () => async dispatch => {
		dispatch({ type: actionTypes.fetchSepaDirectDebitSetupKeyRequest });
		const response = await storeUtils.execute('billing', 'getSEPADirectDebitSetupKey');
		if(response.errors) {
			dispatch({ type: actionTypes.billingFail, errors: response.errors });
		} else {
			dispatch({ type: actionTypes.fetchSepaDirectDebitSetupKeySuccess, response: response });
		}
	}
};

export const reducer = (state = initialState, action) => {

	if(action.type.endsWith('BILLING_REQUEST')) {
		return {
			...state,
			loading: action.type,
			status: null,
			errors: null
		}
	}

	switch(action.type) {
		case actionTypes.billingFail: {
			return {
				...state,
				loading: null,
				errors: action.errors
			}
		}

		case actionTypes.disableOrderingSuccess:
		case actionTypes.enableOrderingSuccess: {
			// note: other parts of this response are handled by the venue reducer
			return {
				...state,
				loading: null,
				status: action.response.preserveStatus ? state.status : storeUtils.processStatusMessages(action.response)
			}
		}

		case actionTypes.getOrderingStatusSuccess: {
			// note: other parts of this response are handled by the venue reducer
			return {
				...state,
				loading: null,
				status: storeUtils.processStatusMessages(action.response)
			}
		}

		case actionTypes.addUpdateBillingSettingsSuccess: {
			// note: other parts of this response are handled by the venue reducer
			return {
				...state,
				loading: null,
				billingSettings: action.billingSettings,
				status: storeUtils.processStatusMessages(action.response)
			}
		}

		case actionTypes.fetchBillingSettingsSuccess: {
			return {
				...state,
				loading: null,
				billingSettings: action.response
			}
		}

		case actionTypes.fetchFixedInvoiceItemsSuccess: {
			const fixedInvoiceItems = action.response.fixedInvoiceItems.sort((el1, el2) => el1.orderIndex - el2.orderIndex);
			return {
				...state,
				loading: null,
				fixedInvoiceItems: fixedInvoiceItems,
				status: storeUtils.processStatusMessages(action.response)
			}
		}

		case actionTypes.addFixedInvoiceItemSuccess: {
			const fixedInvoiceItems = [...state.fixedInvoiceItems, action.fixedInvoiceItem];
			return {
				...state,
				loading: null,
				fixedInvoiceItems: fixedInvoiceItems,
				status: storeUtils.processStatusMessages(action.response)
			}
		}

		case actionTypes.updateFixedInvoiceItemSuccess: {
			const fixedInvoiceItems = state.fixedInvoiceItems.map(fixedInvoiceItem => fixedInvoiceItem.itemId === action.fixedInvoiceItem.itemId ? action.fixedInvoiceItem : fixedInvoiceItem);
			return {
				...state,
				loading: null,
				fixedInvoiceItems: fixedInvoiceItems,
				status: storeUtils.processStatusMessages(action.response)
			}
		}

		case actionTypes.deleteFixedInvoiceItemSuccess: {
			const fixedInvoiceItems = storeUtils.deleteElement(state.fixedInvoiceItems, action.itemId, 'itemId');
			return {
				...state,
				loading: null,
				fixedInvoiceItems: fixedInvoiceItems,
				status: storeUtils.processStatusMessages(action.response)
			}
		}

		case actionTypes.reorderFixedInvoiceItemsSuccess: {
			const fixedInvoiceItems = state.fixedInvoiceItems.map(fixedInvoiceItem => {
				const newFixedInvoiceItem = { ...fixedInvoiceItem };
				newFixedInvoiceItem.orderIndex = action.orderIndexes.find(fixedInvoiceItem => fixedInvoiceItem.itemId === newFixedInvoiceItem.itemId).orderIndex;
				return newFixedInvoiceItem;
			});
			fixedInvoiceItems.sort((el1, el2) => el1.orderIndex - el2.orderIndex);
			return {
				...state,
				loading: null,
				fixedInvoiceItems: fixedInvoiceItems,
				status: storeUtils.processStatusMessages(action.response)
			}
		}

		case actionTypes.fetchSepaDirectDebitSetupKeySuccess: {
			return {
				...state,
				loading: null,
				SepaDirectDebitSetupKey: action.response
			}
		}

		default: {
			return state;
		}
	}
}