import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import useL10n from 'L10n';
import { Modal, ModalBody, ModalHeader } from 'components/Modal';
import PaymentSetupForm from './PaymentSetupForm';
import './StripeDialog.scss';

const StripeDialog = props => {
	const _t = useL10n().getText;
	const [stripe, setStripe] = useState();

	useEffect(() => {
		if(props.show) {
			props.fetchSettings();
		}
		// return () => {}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.show]);

	useEffect(() => {
		if(props.settings?.stripePublishableKey && !stripe) {
			loadStripe(props.settings.stripePublishableKey).then(e => setStripe(e));
		}
	}, [props.settings, stripe]);

	return (
		<Modal id="stripeDialog" show={ props.show } onHide={ props.onHide }>
			<ModalHeader className={ classNames({ async: !stripe }) }>{ _t('HDG_STRIPE_DIALOG', true) }</ModalHeader>

			<ModalBody>

				{/* <ValidationMessage open={ !!error } message={ error }/> */}
				{/* <ValidationMessage open={ !!form.errors }/> */}

				{ stripe &&
					<Elements stripe={ stripe }>
						<PaymentSetupForm
							show={ props.show }
							onHide={ props.onHide }
							settings={ props.settings }
						/>
						{/* <IbanForm
							form={ form }
							settings={ props.settings }
						/> */}
					</Elements>
				}

			</ModalBody>

		</Modal>
	);
};

export default StripeDialog;