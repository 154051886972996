import { actionTypes } from 'store/menus';
import { FormImageControl } from 'components/formControls';
import { IconButton } from 'components/buttons';
import { Pane, PaneHeader, PaneBody, PaneFooter } from 'components/Pane';
import ValidationMessage from 'components/ValidationMessage';
import CustomerRating from './CustomerRating';
import MenuItemForm from './MenuItemForm';
import ItemModifierGroups from './ItemModifierGroups';
import UploaderPane from './UploaderPane';
import ItemTagList from './ItemTagList';
import './MenuItemPane.scss';

const MenuItemPane = props => {

	const modifierGroupSelect = group => {
		props.setCurrentModifierGroup(group);
	}

	return (
		<Pane id="menuItemPane">

			<PaneHeader className="Pane-toolBar">

				<h1>{ props.form.data.name ? props.form.data.name : 'Menu item' }</h1>
				<IconButton
						title="more edit item options"
						disabled={ !props.form.data.menuItemId }
						icon="more_horiz"
						onClick={ props.editPlus }
					/>
				{ props.user?.hasPrivilegedAccess &&
					<IconButton
						title="edit tags"
						disabled={ !props.form.data.menuItemId }
						icon="bookmark"
						onClick={ props.editTags }
					/>
				}
				<IconButton
					title="delete menu item"
					disabled={ !props.form.data.menuItemId }
					async={ props.menuStore.loading === actionTypes.deleteItemRequest }
					icon="delete"
					onClick={ props.onDeleteItem }
				/>
				<IconButton
					title="save menu item"
					disabled={ !props.form.data.menuItemId }
					async={ props.menuStore.loading === actionTypes.updateItemRequest || props.menuStore.loading === actionTypes.addItemRequest }
					icon="save"
					onClick={ props.form.onSave }
				/>
			</PaneHeader>

			<PaneBody className="container scrolling">

				<ValidationMessage open={ !!props.errors }/>

				<div className="form-row">
					<div className="col-sm">

						<UploaderPane
							disabled={ !props.form.data.menuItemId }
							loading={ props.menuStore.loading }
							photos={ props.form.data.photos }
							onPhotoDrop={ props.onPhotoDrop }
							onPhotoDelete={ props.onPhotoDelete }
						/>

						<ItemModifierGroups
							form={ props.form }
							group={ props.group }
							editModifierGroup={ props.editModifierGroup }
							modifierGroupSelect={ modifierGroupSelect }
							onAddModifierGroup={ props.onAddModifierGroup }
							onDeleteModifierGroup={ props.onDeleteModifierGroup }
							shiftGroup={ props.shiftGroup }
						/>

					</div>
					<div className="col-sm">

						<MenuItemForm
							form={ props.form }
							disabled={ !props.form.data.menuItemId }
							showDishNo={ props.menu?.displaysItemsOrderIndex }
							menuSections={ props.menuSections }
							onSalesTaxButtonClick={ props.onSalesTaxButtonClick }
						/>

						<ItemTagList
							tags={ props.form.data.tags }
							disabled={ !props.form.data.menuItemId }
							onClick={ props.editTags }
						/>

						<Pane id="itemSettings" className="mt10">
							<PaneHeader>Settings</PaneHeader>
							<PaneBody>
								<div className="row gutters-5">
									<div className="col">
											<FormImageControl type="checkbox" form={ props.form } field="dairyFree" label="Dairy Free" disabled={ !props.form.data?.menuItemId }/>
											<FormImageControl type="checkbox" form={ props.form } field="glutenFree" label="Gluten Free" disabled={ !props.form.data?.menuItemId }/>
											<FormImageControl type="checkbox" form={ props.form } field="nutSafe" label="Nut Safe" disabled={ !props.form.data?.menuItemId }/>
									</div>
									<div className="col">
											<FormImageControl type="input" form={ props.form } field="veggieStatus" label="Veggie" options={ ['n', 'a', 'b'] } disabled={ !props.form.data?.menuItemId }/>
											<FormImageControl type="input" form={ props.form } field="spicyStatus" label="Spicy" options={ [0, 1, 2, 3] } disabled={ !props.form.data?.menuItemId }/>
											{/*
											These are hidden for now - see PB-56
											<FormCheckbox className="mb20" form={ props.form } field="deliveryOK" disabled={ !props.form.data.menuItemId }>Delivery OK</FormCheckbox>
											<FormCheckbox form={ props.form } field="carryOutOK" disabled={ !props.form.data.menuItemId }>Carry out OK</FormCheckbox>
											*/}
									</div>
								</div>
							</PaneBody>
							<PaneFooter id="customerSatisfaction">
								<label disabled={ !props.form.data.menuItemId }>Customer satisfaction</label>
								<CustomerRating item={ props.form.data }/>
							</PaneFooter>
						</Pane>

					</div>
				</div>

			</PaneBody>

		</Pane>
	);

};

export default MenuItemPane;