import { Fragment, useEffect, useState } from 'react';
import useL10n from 'L10n';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'components/Modal';
import { SearchControl } from 'components/SearchControl';

const OwnersDialog = props => {
	const [showCreateButton, setShowCreateButton] = useState();
	const _t = useL10n().getText;

	useEffect(() => {
		if(props.show) {
			setShowCreateButton(false);
		}
	}, [props.show]);

	const searchUsers = () => {
		props.searchUsers();
		setShowCreateButton(true);
	}

	const renderItem = user => {
		return (
			<Fragment>
				{ user.firstName + (user.lastName ? ' ' + user.lastName : '') }<br/>{ user.email }
			</Fragment>
		);
	}

	return (
		<Modal id="ownersDialog" show={ props.show }>
			<ModalHeader>{ _t('HDG_SEARCH_EXISTING_USERS', true) }</ModalHeader>
			<ModalBody>
				<SearchControl
					placeholder={ _t('PLH_EMAIL_ADDRESS') }
					className="primary"
					focus={ true }
					value={ props.search }
					isLoading={ !!props.userStore.loading }
					onChange={ props.onChange }
					menuItems={ props.userStore.users }
					onSearchClick={ searchUsers }
					onSearchItemClick={ props.setOwner }
					renderItem={ renderItem }
				/>
			</ModalBody>
				<ModalFooter>
					<button type="button" id="btnCancel" className="btn btn-secondary focus" onClick={ props.onHide }>{ _t('BTN_CANCEL', true) }</button>
					{ showCreateButton &&
						<button type="button" id="btnCreate" className="btn btn-primary" onClick={ props.onHide }>{ _t('BTN_CREATE_NEW_OWNER', true) }</button>
					}
				</ModalFooter>
			</Modal>
	);
};

export default OwnersDialog;