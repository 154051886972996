import { useDispatch, useSelector } from 'react-redux';
import useL10n from 'L10n';
import { settingsActionCreators } from 'store/settings';
import sound from 'services/sound';
import DropdownButton from 'components/DropdownButton';
import { IconButton } from 'components/buttons';
import { Pane, PaneBody, PaneHeader } from 'components/Pane';

const Notifications = props => {
	const _t = useL10n().getText;
	const settingStore = useSelector(state => state.settings);
	const dispatch = useDispatch();

	const playSound = () => {
		sound.play(settingStore.soundFile);
	}
	
	const soundChangeHandler = (e, field, value, label) => {
		console.log(field, value, label);
		dispatch(settingsActionCreators.updateSetting('soundFile', parseInt(value, 10)));
	}

	return (
		<div>
			<Pane className="animated fadeIn max-width-400">
				<PaneHeader>{ _t('HDG_NOTIFICATIONS', true) }</PaneHeader>

				<PaneBody className="container">

					<div className="form-row">
						<div className="col">
							<DropdownButton
								field="soundFile"
								placeholder={ _t('PLH_NOTIFICATION_SOUND') }
								value={ settingStore.soundFile }
								options={ options }
								onChange={ soundChangeHandler }
							/>
						</div>
						<div className="col-auto">
							<IconButton
								title={ _t('TTP_PLAY_SOUND') }
								icon="play_arrow"
								className="btn-primary"
								disabled={ settingStore.soundFile == null }
								onClick={ playSound }
							/>
						</div>
					</div>

				</PaneBody>
			</Pane>

			<footer className="Venues-footer Venues-footer_message">{ _t('TXT_NOTIFICATIONS_FOOTER') }</footer>

		</div>
	);
};

export default Notifications;

const options = [
	{ value: 1, label: 'service bell (shorter)' },
	{ value: 2, label: 'hand bell (longer)' }
];