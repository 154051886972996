import classNames from 'classnames';
import { IconButton } from 'components/buttons';
import { Checkbox } from 'components/controls';
import { Pane, PaneHeader, PaneBody, ItemPaneFooter, PaneItemButton } from 'components/Pane';
import { Cell, Col, Row } from 'components/Table';
import './ItemModifierGroups.scss';

const ItemModifierGroups = props => {

	let shiftData;

	return (
		<Pane className="ItemModifierGroups Table_selectable mt10" role="table">
			<PaneHeader>
				<Col>Modifiers</Col>
				<Col w="40px" className="text-center">REQ</Col>
				<Col w="40px" className="text-center">MIN</Col>
				<Col w="40px" className="text-center">MAX</Col>
				<Col w="32px"/>
			</PaneHeader>
			<PaneBody className="scrolling">

				{ props.form.data.modifierGroups && props.form.data.modifierGroups.map((group, ix) => {
					if(props.group) {
						if(props.group.modifierGroupId === group.modifierGroupId) {
							shiftData = [props.form.data.modifierGroups.length - 1, ix];
						}
					}
					return (
						<Row
							key={ group.modifierGroupId }
							className={ classNames({ 'active': group.modifierGroupId === props.group?.modifierGroupId }) }
							onClick={ () => props.modifierGroupSelect(group) }
						>
							<Cell>{ group.name }</Cell>
							<Cell><Checkbox disabled readOnly checked={ group.required }/></Cell>
							<Cell>{ group.min }</Cell>
							<Cell>{ group.max || '–' }</Cell>
							<Cell w="32px" className="p-0">
								<PaneItemButton floated={ false } index={ props.group } icon="edit" onClick={ () => props.editModifierGroup(group) }/>
							</Cell>
						</Row>
					)})
				}

			</PaneBody>

			<ItemPaneFooter shiftData={ shiftData } shiftElement={ props.shiftGroup }>
				<IconButton disabled={ !props.form.data.menuItemId } title="add new modifier group" icon="add" onClick={ props.onAddModifierGroup }/>
				<IconButton disabled={ !props.group } title="delete selected modifier group" icon="delete" onClick={ props.onDeleteModifierGroup }/>
			</ItemPaneFooter>

		</Pane>
	)

};

export default ItemModifierGroups;