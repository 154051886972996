import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import { InputWithButton } from './controls';
import { IconButton } from './buttons';
import './TimePicker.scss';

const TimePicker = props => {
	const [open, setOpen] = useState(false);
	const [isMorning, setIsMorning] = useState(props.default === 'morning');

	const toggle = e => {
		e.preventDefault();
		setOpen(!open);
	}

	const onHeaderButtonClick = e => {
		setIsMorning(e.currentTarget.id === 'btnAM');
	}

	const onTimeClick = e => {
		const value = e.target.textContent;
		props.onChange(e, (props.name ? props.name : props.id), value);
		setOpen(false);
	}

	const onKeyUp = e => {
		if(e.key === 'Enter') {
			if(e.target.innerText === 'schedule') {
				toggle(e);
			} else {
				onTimeClick(e);
			}
		}
	}

	// Note: props.className gets applied to the InputWithButton and not to the container (TimePicker)
	// if you need to style TimePicker, you need to think of another way (wrap it, specificity, etc)
	return (
		<Dropdown className="TimePicker" isOpen={ open } toggle={ toggle }>

			<InputWithButton
				className={ props.className }
				placeholder={ props.placeholder }
				name={ props.name }
				id={ props.id }
				title={ props.title }
				value={ props.value }
				disabled={ props.disabled }
				icon="schedule"
				onButtonClick={ toggle }
				onButtonKeyUp={ onKeyUp }
				onChange={ props.onChange }
			/>
			<DropdownToggle className="btn btn-block" tag="button"/>

			<DropdownMenu>
				{ props.headerControls &&
					<header className="TimePicker-header toolbar">
						<IconButton id="btnAM" disabled={ isMorning } icon="chevron_left" onClick={ onHeaderButtonClick }/>
						<IconButton id="btnPM" disabled={ !isMorning } icon="chevron_right" onClick={ onHeaderButtonClick }/>
					</header>
				}
				<div className="TimePicker-body">

					{ [...Array(12)].map((el, n) => {
						const hour = ('0' + (n + (isMorning ? 0 : 12))).substr(-2);
						return <Fragment key={ n }>
							<button className="TimePicker-time" onClick={ onTimeClick } onKeyUp={ onKeyUp }>{ hour + ':00' }</button>
							<button className="TimePicker-time" onClick={ onTimeClick } onKeyUp={ onKeyUp }>{ hour + ':30' }</button>
						</Fragment>
					}) }

				</div>
				{ !props.headerControls &&
					<footer className="TimePicker-footer toolbar">
					<IconButton id="btnAM" disabled={ isMorning } icon="chevron_left" onClick={ onHeaderButtonClick }/>
					<IconButton id="btnPM" disabled={ !isMorning } icon="chevron_right" onClick={ onHeaderButtonClick }/>
				</footer>
				}
			</DropdownMenu>

		</Dropdown>
	);

}

TimePicker.propTypes = {
	className: PropTypes.string,
	placeholder: PropTypes.string,
	name: PropTypes.string,
	value: PropTypes.string,
	disabled: PropTypes.bool,
	onChange: PropTypes.func
};

export default TimePicker;