import React, { Component, Fragment } from 'react';
import { Collapsible, CollapserButton } from '../components/Collapsible';
import { IconButton } from '../components/buttons';
import { Pane, PaneHeader, PaneMenu, PaneBody, ItemPaneFooter, PaneItem, PaneItemButton } from '../components/Pane';

export default class Pane1 extends Component {

	state = {	// UI state only
		collapseCollapsibles: false,
		menuOptionsOpen: false,
		currentMenuIndex: null,
		currentMenuItemId: null
	};

	// shouldComponentUpdate(nextProps, nextState) {
	// 	// only allow update if collapser button clicked - what happens when we need other state on a pane?
	// 	return typeof nextState.collapseCollapsibles === 'boolean' && nextState.collapseCollapsibles !== this.state.collapseCollapsibles;
	// }

	render() {
		return (
			<Pane id="pane1" hasMenu>
				<PaneHeader>
					<PaneMenu label="Very Very Long Menu Names" open={ this.state.menuOptionsOpen }>
						<PaneItem index="0" selected={ this.state.currentMenuIndex === '0' } onClick={ this.onPaneItemClick }>
							<PaneItemButton index="0" icon="settings" onClick={ this.onPaneItemButtonClick }/>
							<span>Option 1</span>
						</PaneItem>
						<PaneItem index="1" selected={ this.state.currentMenuIndex === '1' } onClick={ this.onPaneItemClick }>
							<PaneItemButton index="1" icon="settings" onClick={ this.onPaneItemButtonClick }/>
							<span>Option 2</span>
						</PaneItem>
						<PaneItem index="2" selected={ this.state.currentMenuIndex === '2' } onClick={ this.onPaneItemClick }>
							<PaneItemButton index="2" icon="settings" onClick={ this.onPaneItemButtonClick }/>
							<span>Option 3</span>
						</PaneItem>
						<PaneItem index="3" selected={ this.state.currentMenuIndex === '3' } onClick={ this.onPaneItemClick }>
							<PaneItemButton index="3" icon="settings" onClick={ this.onPaneItemButtonClick }/>
							<span>Option 4</span>
						</PaneItem>
					</PaneMenu>
					<div className="Pane-toolbar">
						<IconButton title="save menu item" icon="search"/>
					</div>
				</PaneHeader>
				<PaneBody className="scrolling">
					<Collapsible className="Pane-items_p0 Pane-items_indexed"
						header={(
							<Fragment>
								<h1>Party Food</h1>
								<PaneItemButton icon="edit"/>
							</Fragment>
						)}
						collapse={ this.state.collapseCollapsibles }
					>
						<PaneItem>
							<div className="col-auto">1</div>
							<div className="col">Cheese</div>
							<div className="col-auto">$3.99</div>
						</PaneItem>
						<PaneItem disabled>
							<div className="col-auto">2</div>
							<div className="col">Wine</div>
							<div className="col-auto">$9.99</div>
						</PaneItem>
					</Collapsible>
					<Collapsible className="Pane-items_p0 Pane-items_indexed"
						header={(
							<Fragment>
								<h1>Snacks</h1>
								<PaneItemButton icon="edit"/>
							</Fragment>
						)}
						collapse={ this.state.collapseCollapsibles }
					>
						<PaneItem>
							<div className="col-auto">3</div>
							<div className="col">Coffee</div>
							<div className="col-auto">$2.99</div>
						</PaneItem>
						<PaneItem>
							<div className="col-auto">4</div>
							<div className="col">Cake</div>
							<div className="col-auto">$2.99</div>
						</PaneItem>
						<PaneItem>
							<div className="col-auto">15</div>
							<div className="col">Biscuits</div>
							<div className="col-auto">$3.99</div>
						</PaneItem>
					</Collapsible>
				</PaneBody>
				<ItemPaneFooter shiftData={ [3, 0] }>
					<IconButton title="add new item" icon="add"/>
					<CollapserButton open={ !this.state.collapseCollapsibles } onClick={ this.toggleAllcollapseCollapsibles }/>
				</ItemPaneFooter>
			</Pane>
		);
	}

	onPaneItemButtonClick = (ix, e) => {
		// console.log('onPaneItemButtonClick', ix);
		this.setState({ currentMenuIndex: ix });
	}

	onPaneItemClick = (ix, e) => {
		console.log('onPaneItemClick', ix);
		this.setState({ currentMenuIndex: ix });
	}

	onMenuClick = (ix, e) => {
		this.setState({ currentMenuIndex: ix });
	}
	
	onMenuItemClick = (id, e) => {
		this.setState({ currentMenuItemId: id });
	}

	toggleAllcollapseCollapsibles = e => {
		this.setState({ collapseCollapsibles: !this.state.collapseCollapsibles });
	}

}