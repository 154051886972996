// I think the previous version used to automatically select the venue
// if say a venue owner only had one (probably a common scenario)
// however this is not yet implemented
import { Fragment } from 'react';
import utilities from 'services/utilities';
import DropdownButton from 'components/DropdownButton';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'components/Modal';
import Notification from 'components/Notification';
import { SearchControl } from 'components/SearchControl';

const VenuesDialog = props => {

	const type = props?.user?.hasPrivilegedAccess ? 'Search' : 'Selector';
	
	const options = type === 'Selector' ? props.venueStore.venues?.map(venue => {
		return { value: venue.venueUUID, label: venue.name };
	}) : null;

	const venueClickHandler = (e, id, value, label) => {
		e.preventDefault();
		let venueId;
		const source = e.currentTarget;
		if(source.tagName === 'A') {
			venueId = source.getAttribute('data-id');
		} else {
			venueId = value;
		}
		props.setVenue(venueId);
	}

	const clickHandler = e => {
		props.onHide(e.currentTarget.id);
	}

	const renderItem = venue => {
		return (
			<Fragment>
				{ venue.name }<br/>{ venue.address }
			</Fragment>
		);
	}

	const searchItemClickHandler = venue => {
		props.setVenue(venue.venueUUID);
	}

	return (
		<Modal id="venuesDialog" show={ props.show }>
			<ModalHeader>{ utilities.getText('HDG_VENUE_' + type.toUpperCase()) }</ModalHeader>
			<ModalBody>
				{ type === 'Selector'
					?
						options?.length === 0
							?
								<Notification type="error" design="outline">{ utilities.getText('TXT_NO_VENUES') }</Notification>
							:
								<DropdownButton
									placeholder="Select Venue"
									className={ options?.length ? '' : 'async' }
									options={ options }
									disabled={ !options }
									value={ props.venueStore.venue?.venueUUID }
									onChange={ venueClickHandler }
								/>
					:
						<SearchControl
							placeholder="Venue Name"
							className="primary"
							focus={ true }
							value={ props.search }
							isLoading={ !!props.venueStore.loading }
							onChange={ props.onChange }
							menuItems={ props.venueStore.venues }
							onSearchClick={ props.fetchVenues }
							onSearchItemClick={ searchItemClickHandler }
							renderItem={ renderItem }
						/>
				}
			</ModalBody>
				<ModalFooter>
					<button type="button" id="btnCancel" className="btn btn-secondary focus" onClick={ clickHandler }>{ utilities.getText('LBL_CANCEL') }</button>
					{ props.user?.hasPrivilegedAccess &&
						<button type="button" id="btnAddNew" className="btn btn-primary" onClick={ clickHandler }>{ utilities.getText('LBL_ADD_VENUE') }</button>
					}
				</ModalFooter>
			</Modal>
	);
};

export default VenuesDialog;