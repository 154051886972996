// ideally, I'd like this to work like MenusPane - when user selects an option the
// async indicator is on the selection, not the results pane
// however, implementing this is non-trivial since so many different stores are involved
import { useDispatch, useSelector } from 'react-redux';
import { Route, useLocation } from 'react-router';
import useL10n from 'L10n';
import { hasVenue, venuesActionCreators } from 'store/venues';
import { Pane, PaneHeader, PaneBody, PaneItem } from '../components/Pane';
import Pos from './settings/Pos';
import Status from './settings/Status';
import OrderTypes from './settings/OrderTypes';
import OrderFlow from './settings/OrderFlow';
import Notifications from './settings/Notifications';
// import LoyaltyRewards from './settings/LoyaltyRewards';
import ExtraCharges from './settings/ExtraCharges';
import Billing from './settings/Billing';
import Printers from './settings/Printers';
import { useEffect } from 'react';

const Settings = props => {
	const _t = useL10n().getText;
	const venue = useSelector(state => state.venues.venue);
	const dispatch = useDispatch();

	const location = useLocation();
	const path = '/venues/orders/settings/';
	const page = location.pathname.slice(path.length);

	useEffect(() => {
		// depending on the settings page if the page is refreshed, we may need to fetch the venue
		if(!venue && hasVenue()) {
			switch(page) {
				case '':
				case 'ordertypes':
				case 'orderflow':
				case 'notifications':
					dispatch(venuesActionCreators.fetchVenue());
					break;
				default: break;
			}
		}
	}, [venue, dispatch, page]);

	return (
		<div className="OrderSettings row animated fadeIn max-width-1200">

			<div className="col-md-3">

				<Pane id="orderSettings">
					<PaneHeader>Setting</PaneHeader>
					<PaneBody tag="ul" className="Pane-items mb5">
						{ options.map((option, ix) => {
							const route = '/venues/orders/settings/' + option.substr(4).replace('_', '-').toLowerCase();
							return <PaneItem key={ ix } to={ route }>{ _t(option) }</PaneItem>
						}) }
					</PaneBody>
				</Pane>

			</div>
			<div className="col-md-9">

				<Route path={ path + 'status' }><Status/></Route>
				<Route path={ path + 'ordertypes' }><OrderTypes/></Route>
				<Route path={ path + 'orderflow' }><OrderFlow/></Route>
				<Route path={ path + 'billing' }><Billing/></Route>
				<Route path={ path + 'extra-charges' }><ExtraCharges/></Route>
				<Route path={ path + 'pos-integration' }><Pos/></Route>
				<Route path={ path + 'printers/:id?' }><Printers/></Route>
				{/* <Route path={ path + 'wifi' }><Wifi/></Route> */}
				<Route path={ path + 'notifications' }><Notifications/></Route>

			</div>
		</div>
	);

}

export default Settings;

const options = ['HDG_STATUS', 'HDG_ORDERTYPES', 'HDG_ORDERFLOW', 'HDG_BILLING', 'HDG_EXTRA_CHARGES', 'HDG_POS_INTEGRATION', 'HDG_PRINTERS', 'HDG_NOTIFICATIONS'];