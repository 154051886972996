import PropTypes from 'prop-types';
import { FormInput } from 'components/formControls';
import { Radio } from 'components/controls';

const PriceOptions = props => {

	return (
		<div className="PriceOptions controls-stacked">
			<div className="controls-stacked_row">
				<Radio className="form-control" name={ props.name } value={ props.config[0].value } checked={ props.which === props.config[0].value } disabled={ props.disabled } onChange={ props.form.onChange }>{ props.config[0].label }</Radio>
				<FormInput type="money" form={ props.form } field={ props.config[0].field } disabled={ props.disabled }/>
			</div>
			<div className="controls-stacked_row">
				<Radio className="form-control" name={ props.name } value={ props.config[1].value } checked={ props.which === props.config[1].value } disabled={ props.disabled } onChange={ props.form.onChange }>{ props.config[1].label }</Radio>
				<FormInput type="money" form={ props.form } field={ props.config[1].field } disabled={ props.disabled }/>
			</div>
		</div>
	);

};

PriceOptions.propTypes = {
	form: PropTypes.object.isRequired,
	config: PropTypes.array.isRequired
};

export default PriceOptions;