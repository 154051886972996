import { useState } from 'react';
import classNames from 'classnames';
import Flyout from './Flyout';
import './FlyoutPage.scss';

const FlyoutPage = props => {

	const [collapsed, setCollapsed] = useState(window.innerWidth < 1200);	// was 768 but increased so ipad starts collapsed

	const toggle = e => {
		setCollapsed(!collapsed);
	}

	const className = classNames('FlyoutPage', { 'collapsed': collapsed}, { 'fullscreen': props.fullscreen }, props.className);
	return (

		<div className={ className }>
			<div
				id="ipad"
				style={{
					display: 'none',
					left: '64px',
					top: '51px',
					width: '960px',
					height: '696px',
					position: 'absolute',
					backgroundColor: '#DDD'
				}}
			/>

			<Flyout onClick={ toggle } collapsed={ collapsed } signInOutHandler={ props.signInOutHandler }/>
			{ props.children }
		</div>
	);

}

export default FlyoutPage;