// This is shared between ORDERS > LIST & REPORTS > PAYOUTS
import { Fragment, useEffect, useRef } from 'react';
import dates from 'services/dates';
import utilities from 'services/utilities';
import useL10n from 'L10n';
import useForm from 'components/Form';
import { FormLabel, FormValue } from 'components/formControls';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'components/Modal';

const OrderDetailsDialog = props => {
	const { getText, getDistributionType } = useL10n();
	const form = useForm();
	const formRef = useRef(form);

	useEffect(() => {
		if(props.show) {
			if(props.order) {
				// massage the order data into a format more useful for the dialog...
				const order = { ...props.order };
				order.name = order.firstName + (order.lastName ? ' ' + order.lastName: '');	// this is currently very western focussed
				order.phone = order.deliveryAddress?.phone;
				order.paymentStatusText = getText(utilities.getPaymentStatusKey(order.paymentStatus));
				if(order.deliveryAddress) {
					const adr = order.deliveryAddress;
					order.address = adr.street?.replace(/, /g, '||');
					if(adr.aptNumber) order.address = getText('TXT_APT_NO') + adr.aptNumber + (order.address ? '||' + order.address : '');
					if(adr.businessName) order.address = adr.businessName + (order.address ? '||' + order.address : '');
				}
				formRef.current.reset(order);
			}
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.show, props.order]);


	const clickHandler = e => {
		props.onHide(e.target.id);
	}

	const renderAddress = () => {
		// this takes the data produced by the effect above and renders it in a human-friendly but XSS-attack-unfriendly way
		if(!form.data.address) return null;
		const parts = form.data.address.split('||');
		return parts.map((part, ix) => <div key={ ix }>{ part }</div>);
	}

	// if(!props.order) return null;
	return (
		<Modal id="orderDetailsDialog" show={ props.show } size="medium" onHide={ props.onHide }>
			<ModalHeader>{ getText('HDG_ORDER') }</ModalHeader>
			<ModalBody>

				<div className="row">
					<div className="col-md-6">

						<fieldset className="form-fieldset">
							<div className="form-row">
								<FormLabel className="col-sm-6 left-label" label="_ORDER_ID"/>
								<div className="col-sm-6 left-control">
									<FormValue className="text-right" form={ form } field="externalOrderId"/>
								</div>
							</div>
							<div className="form-row">
								<FormLabel className="col-sm-6 left-label" label="_ORDER_DATE"/>
								<div className="col-sm-6 left-control">
									<div className="form-value text-right">{ dates.format(form.data.timestamp, dates.styles.DATETIME, props.timeZone) }</div>
								</div>
							</div>
							<div className="form-row">
								<FormLabel className="col-sm-6 left-label" label="_DISTRIBUTION"/>
								<div className="col-sm-6 left-control">
									<div className="form-value text-right">{ getDistributionType(form.data) }</div>
								</div>
							</div>
							<div className="form-row">
								<FormLabel className="col-sm-6 left-label" label="_ORDER_STATUS"/>
								<div className="col-sm-6 left-control">
									<div className="form-value text-right">{ form.data.orderStatus ? getText(utilities.getOrderStatusKey(form.data.orderStatus)) : ''}</div>
								</div>
							</div>
							<div className="form-row">
								<FormLabel className="col-sm-6 left-label" label="_PAYMENT_STATUS"/>
								<div className="col-sm-6 left-control">
									<FormValue className="text-right" form={ form } field="paymentStatusText"/>
								</div>
							</div>
						</fieldset>

						<fieldset className="form-fieldset">
							<div className="form-row">
								<FormLabel className="col-sm-6 left-label" label="_PATRON"/>
								<div className="col-sm-6 left-control">
									<FormValue className="text-right" form={ form } field="name"/>
								</div>
							</div>
							<div className="form-row">
								<FormLabel className="col-sm-6 left-label" label="_PHONE"/>
								<div className="col-sm-6 left-control">
									<FormValue className="text-right" form={ form } field="phone"/>
								</div>
							</div>
							<div className="form-row">
								<FormLabel className="col-sm-12" label="_ADDRESS"/>
								<div className="col-sm-12">
									<div>
										{ renderAddress() }
									</div>
								</div>
							</div>
							{ form.data.deliveryAddress?.courierNotes &&
								<div className="form-row">
									<FormLabel className="col-sm-12" label="_COURIER_NOTES"/>
									<div className="col-sm-12">
										<div>{ form.data.deliveryAddress.courierNotes }</div>
									</div>
								</div>
							}
						</fieldset>

					</div>
					<div className="col-md-6">

						<fieldset className="form-fieldset">
							<div className="form-row">
								<FormLabel className="col-sm-8 right-label" label="_ITEMS_COST"/>
								<div className="col-sm-4 right-control">
									<FormValue type="money" form={ form } field="itemsCost" currencySymbol={ props.currencySymbol }/>
								</div>
							</div>
							<div className="form-row">
								<FormLabel className="col-sm-8 right-label" label="_REWARDS_DISCOUNT"/>
								<div className="col-sm-4 right-control">
									<FormValue type="money" form={ form } field="reward" currencySymbol={ props.currencySymbol }/>
								</div>
							</div>
							<div className="form-row">
								<FormLabel className="col-sm-8 right-label" label="_OTHER_DISCOUNTS"/>
								<div className="col-sm-4 right-control">
									<FormValue type="money" form={ form } field="otherDiscounts" currencySymbol={ props.currencySymbol }/>
								</div>
							</div>
							<div className="form-row">
								<FormLabel className="col-sm-8 right-label" label="_AFTER_DISCOUNTS"/>
								<div className="col-sm-4 right-control">
									<FormValue type="money" form={ form } field="itemsCostAfterDiscounts" currencySymbol={ props.currencySymbol }/>
								</div>
							</div>
							<div className="form-row">
								<FormLabel className="col-sm-8 right-label" label="_OTHER_CHARGES"/>
								<div className="col-sm-4 right-control">
									<FormValue type="money" form={ form } field="otherCharges" currencySymbol={ props.currencySymbol }/>
								</div>
							</div>
							<div className="form-row">
								<FormLabel className="col-sm-8 right-label" label="_DELIVERY_FEE"/>
								<div className="col-sm-4 right-control">
									<FormValue type="money" form={ form } field="deliveryFee" currencySymbol={ props.currencySymbol }/>
								</div>
							</div>
							<div className="form-row">
								<FormLabel className="col-sm-8 right-label" label="_SUBTOTAL"/>
								<div className="col-sm-4 right-control">
									<FormValue type="money" form={ form } field="subTotal" currencySymbol={ props.currencySymbol }/>
								</div>
							</div>
							<div className="form-row">
								<FormLabel className="col-sm-8 right-label" label="_SALES_TAX"/>
								<div className="col-sm-4 right-control">
									<FormValue type="money" form={ form } field="salesTaxAmount" currencySymbol={ props.currencySymbol }/>
								</div>
							</div>
							{ utilities.getPaymentStatusKey(form.data.paymentStatus) === 'TXT_PAYMENT_UNPAID'
									?
										<div className="form-row">
											<FormLabel className="col-sm-7 right-label" label="_TOTAL"/>
											<div className="col-sm-2 order_unpaid">CASH</div>
											<div className="col-sm-3 right-control">
												<FormValue type="money" form={ form } field="total" currencySymbol={ props.currencySymbol }/>
											</div>
										</div>
									:
										<div className="form-row">
											<FormLabel className="col-sm-8 right-label" label="_TOTAL"/>
											<div className="col-sm-4 right-control">
												<FormValue type="money" form={ form } field="total" currencySymbol={ props.currencySymbol }/>
											</div>
										</div>
							}
							<div className="form-row">
								<FormLabel className="col-sm-8 right-label" label="_TIP"/>
								<div className="col-sm-4 right-control">
									<FormValue type="money" form={ form } field="tips" currencySymbol={ props.currencySymbol }/>
								</div>
							</div>
							<div className="form-row">
								<FormLabel className="col-sm-8 right-label" label="_TOTAL_BILLED"/>
								<div className="col-sm-4 right-control">
									<FormValue type="money" form={ form } field="totalBilled" currencySymbol={ props.currencySymbol }/>
								</div>
							</div>
							{ props.privileged &&
								<div className="form-row">
									<FormLabel className="col-sm-8 right-label" label="_TRANSACTION_FEE"/>
									<div className="col-sm-4 right-control">
										<FormValue type="money" form={ form } field="transactionFee" currencySymbol={ props.currencySymbol }/>
									</div>
								</div>
							}
							{ props.privileged &&
								<div className="form-row">
									<FormLabel className="col-sm-8 right-label" label="_NET_FOR_VENUE"/>
									<div className="col-sm-4 right-control">
										<FormValue type="money" form={ form } field="netAmountForResto" currencySymbol={ props.currencySymbol }/>
									</div>
								</div>
							}
							<div className="form-row">
								<FormLabel className="col-sm-8 right-label" label="_PAYOUT_AVAILABLE_ON"/>
								<div className="col-sm-4 right-control">
									<div className="form-value text-right">{ dates.format(form.data.netAmountAvailableOn, dates.styles.DATE, props.timeZone) }</div>
								</div>
							</div>
						</fieldset>

					</div>

				</div>

			</ModalBody>
			<ModalFooter>

				{ props.from === 'OrdersList' &&
					<Fragment>
						<button
							className="btn btn-secondary"
							id="btnCancel"
							disabled={ !props.order?.isCancellable }
							onClick={ clickHandler }
						>
							{ getText('LBL_ORDER_CANCEL') }
						</button>
						<button
							className="btn btn-secondary"
							id="btnModify"
							disabled={ !props.order?.isCancellable }
							onClick={ clickHandler }
						>
							{ getText('BTN_ORDER_MODIFY') }
						</button>
						<button className="btn btn-secondary" id="btnReprint" onClick={ clickHandler }>
							{ getText('BTN_ORDER_REPRINT') }
						</button>
					</Fragment>
				}
				<button className="btn btn-primary focus" id="btnClose" onClick={ clickHandler }>
					{ getText('BTN_CLOSE') }
				</button>

			</ModalFooter>
		</Modal>
	);
};

export default OrderDetailsDialog;