/* *** TODO ***
See final comments in PB-63:

"Check the return value of orderingEnabled to update the Status control and to display warnings accordingly:
menusWSrvc/addMenuItem
menusWSrvc/updateMenuItem
menusWSrvc/updateMenuItemModifierGroup"

*/
import utilities from '../services/utilities';
import storeUtils from './storeUtils';

export const actionTypes = {
	menusFail: 'MENUS_FAIL',

	fetchMenusRequest: 'FETCH_MENUS_MENUS_REQUEST',
	fetchMenusSuccess: 'FETCH_MENUS_MENUS_SUCCESS',
	fetchOrderMenusRequest: 'FETCH_ORDERMENUS_MENUS_REQUEST',
	fetchOrderMenusSuccess: 'FETCH_ORDERMENUS_MENUS_SUCCESS',

	addMenuRequest: 'ADD_MENU_MENUS_REQUEST',
	addMenuSuccess: 'ADD_MENU_MENUS_SUCCESS',
	updateMenuRequest: 'UPDATE_MENU_MENUS_REQUEST',
	updateMenuSuccess: 'UPDATE_MENU_MENUS_SUCCESS',
	deleteMenuRequest: 'DELETE_MENU_MENUS_REQUEST',
	deleteMenuSuccess: 'DELETE_MENU_MENUS_SUCCESS',
	reorderMenusRequest: 'REORDER_MENUS_MENUS_REQUEST',
	reorderMenusSuccess: 'REORDER_MENUS_MENUS_SUCCESS',

	addSectionRequest: 'ADD_SECTION_MENUS_REQUEST',
	addSectionSuccess: 'ADD_SECTION_MENUS_SUCCESS',
	updateSectionRequest: 'UPDATE_SECTION_MENUS_REQUEST',
	updateSectionSuccess: 'UPDATE_SECTION_MENUS_SUCCESS',
	deleteSectionRequest: 'DELETE_SECTION_MENUS_REQUEST',
	deleteSectionSuccess: 'DELETE_SECTION_MENUS_SUCCESS',
	reorderSectionsRequest: 'REORDER_SECTIONS_MENUS_REQUEST',
	reorderSectionsSuccess: 'REORDER_SECTIONS_MENUS_SUCCESS',

	fetchItemRequest: 'FETCH_ITEM_MENUS_REQUEST',
	fetchItemSuccess: 'FETCH_ITEM_MENUS_SUCCESS',
	setItemSuccess: 'SET_ITEM_MENUS_SUCCESS',
	addItemRequest: 'ADD_ITEM_MENUS_REQUEST',
	addItemSuccess: 'ADD_ITEM_MENUS_SUCCESS',
	updateItemRequest: 'UPDATE_ITEM_MENUS_REQUEST',
	updateItemSuccess: 'UPDATE_ITEM_MENUS_SUCCESS',
	deleteItemRequest: 'DELETE_ITEM_MENUS_REQUEST',
	deleteItemSuccess: 'DELETE_ITEM_MENUS_SUCCESS',
	reorderItemsRequest: 'REORDER_ITEMS_MENUS_REQUEST',
	reorderItemsSuccess: 'REORDER_ITEMS_MENUS_SUCCESS',

	updateItemModifierGroupRequest: 'UPDATE_ITEMMODIFIERGROUP_MENUS_REQUEST',
	updateItemModifierGroupSuccess: 'UPDATE_ITEMMODIFIERGROUP_MENUS_SUCCESS',
	deleteItemModifierGroupRequest: 'DELETE_ITEMMODIFIERGROUP_MENUS_REQUEST',
	deleteItemModifierGroupSuccess: 'DELETE_ITEMMODIFIERGROUP_MENUS_SUCCESS',
	reorderItemModifierGroupsRequest: 'REORDER_ITEMMODIFIERGROUPS_MENUS_REQUEST',
	reorderItemModifierGroupsSuccess: 'REORDER_ITEMMODIFIERGROUPS_MENUS_SUCCESS',

	uploadMenuItemPhotoRequest: 'UPLOAD_MENUITEMPHOTO_MENUS_REQUEST',
	uploadMenuItemPhotoSuccess: 'UPLOAD_MENUITEMPHOTO_MENUS_SUCCESS',
	deleteMenuItemPhotoRequest: 'DELETE_MENUITEMPHOTO_MENUS_REQUEST',
	deleteMenuItemPhotoSuccess: 'DELETE_MENUITEMPHOTO_MENUS_SUCCESS'
}

const initialState = { orderMenus: null, orderItems: null, menus: null, sections: null, item: null, loading: null, errors: null };

export const menusActionCreators = {

	fetchMenus: (reload = false) => async (dispatch, getState) => {
		const state = getState();
		if(!reload && state.menus.menus) {
			return;
		}
		dispatch({ type: actionTypes.fetchMenusRequest });
		const response = await storeUtils.execute('menus', 'getMenus');
		if(response.errors) {
			dispatch({ type: actionTypes.menusFail, errors: response.errors });
		} else {
			dispatch({ type: actionTypes.fetchMenusSuccess, response: response });
		}
	},

	fetchOrderMenus: (reload = false) => async (dispatch, getState) => {
		const state = getState();
		if(!reload && state.menus.orderMenus) {
			return;
		}
		dispatch({ type: actionTypes.fetchOrderMenusRequest });
		const data = await storeUtils.execute('menus', 'getMenus', { includeModifiers: 1 });
		if(data.errors) {
			dispatch({ type: actionTypes.menusFail, errors: data.errors });
		} else {
			dispatch({ type: actionTypes.fetchOrderMenusSuccess, orderMenus: data.menus });
		}
	},

	addMenu: menu => async (dispatch, getState) => {
		dispatch({ type: actionTypes.addMenuRequest });
		const menus = getState().menus.menus;
		menu.orderIndex = utilities.getNextOrderIndex(menus);
		const data = await storeUtils.execute('menus', 'addMenu', menu);
		if(data.errors) {
			dispatch({ type: actionTypes.menusFail, errors: data.errors });
		} else {
			menu.menuId = data.menuId;
			dispatch({ type: actionTypes.addMenuSuccess, menu: menu });
		}
	},
	
	updateMenu: menu => async dispatch => {
		dispatch({ type: actionTypes.updateMenuRequest });
		const data = await storeUtils.execute('menus', 'updateMenu', menu);
		if(data.errors) {
			dispatch({ type: actionTypes.menusFail, errors: data.errors });
		} else {
			dispatch({ type: actionTypes.updateMenuSuccess, menu: menu });
		}
	},
	
	deleteMenu: menu => async dispatch => {
		dispatch({ type: actionTypes.deleteMenuRequest });
		const data = await storeUtils.execute('menus', 'deleteMenu', { menuId: menu.menuId });
		if(data.errors) {
			dispatch({ type: actionTypes.menusFail, errors: data.errors });
		} else {
			dispatch({ type: actionTypes.deleteMenuSuccess, menu: menu });
		}
	},
	
	shiftMenu: (id, isUp) => async (dispatch, getState) => {
		dispatch({ type: actionTypes.reorderMenusRequest });
		const menus = getState().menus.menus;
		const request = storeUtils.getShiftElementRequest(menus, id, isUp, 'menuId');
		const data = await storeUtils.execute('menus', 'reorderMenus', request);
		if(data.errors) {
			dispatch({ type: actionTypes.menusFail, errors: data.errors });
		} else {
			dispatch({ type: actionTypes.reorderMenusSuccess, orderIndexes: request.orderIndexes });
		}
	},

	addSection: (menu, section) => async dispatch => {
		dispatch({ type: actionTypes.addSectionRequest });
		const payload = {};
		payload.menuSectionTitle = section.title;
		payload.menuId = menu.menuId;
		payload.orderIndex = utilities.getNextOrderIndex(menu.menuSections);
		const data = await storeUtils.execute('menus', 'addMenuSection', payload);
		if(data.errors) {
			dispatch({ type: actionTypes.menusFail, errors: data.errors });
		} else {
			section.title = data.menuSectionTitle;	// title may be adjusted by api
			section.menuSectionId = data.menuSectionId;
			section.orderIndex = payload.orderIndex;
			dispatch({ type: actionTypes.addSectionSuccess, menu: menu, section: section });
		}
	},
	
	updateSection: (menu, section) => async dispatch => {
		dispatch({ type: actionTypes.updateSectionRequest });
		const payload = {
			menuId: menu.menuId,
			menuSectionId: section.menuSectionId,
			title: section.title
		}
		const data = await storeUtils.execute('menus', 'updateMenuSection', payload);
		if(data.errors) {
			dispatch({ type: actionTypes.menusFail, errors: data.errors });
		} else {
			dispatch({ type: actionTypes.updateSectionSuccess, menu: data.menu });
		}
	},
	
	deleteSection: (menu, section) => async dispatch => {
		dispatch({ type: actionTypes.deleteSectionRequest });
		const payload = {
			menuId: menu.menuId,
			menuSectionId: section.menuSectionId
		}
		const data = await storeUtils.execute('menus', 'deleteMenuSection', payload);
		if(data.errors) {
			dispatch({ type: actionTypes.menusFail, errors: data.errors });
		} else {
			dispatch({ type: actionTypes.deleteSectionSuccess, menu: menu, section: section });
		}
	},
	
	shiftSection: (menu, id, isUp) => async (dispatch, getState) => {
		dispatch({ type: actionTypes.reorderSectionsRequest });
		const request = storeUtils.getShiftElementRequest(menu.menuSections, id, isUp, 'menuSectionId');
		request.menuId = menu.menuId;
		const data = await storeUtils.execute('menus', 'reorderMenuSections', request);
		if(data.errors) {
			dispatch({ type: actionTypes.menusFail, errors: data.errors });
		} else {
			dispatch({ type: actionTypes.reorderSectionsSuccess, menu: menu, orderIndexes: request.orderIndexes });
		}
	},

	fetchItem: (menuId, menuItemId) => async (dispatch, getState) => {
		const menus = getState().menus.menus;
		if(!menus) return;	// don't attempt to load an item before the menus have loaded
		const menu = menus.find(menu => menu.menuId === menuId);
		const menuItem = findMenuItem(menu, menuItemId);
		if(!menuItem) {
			// looks like item has been deleted
			return 'ITEM_DELETED';
		}
		// check to see if full-fat item is already lazyloaded (menuSectionId will be populated if previously loaded)...
		if(menuItem.menuSectionId) {
			// ok, already there so just need to update item state and exit
			dispatch({ type: actionTypes.setItemSuccess, item: menuItem });
			return;
		}
		// ok, not already lazyloaded so go fetch...
		dispatch({ type: actionTypes.fetchItemRequest });
		const data = await storeUtils.execute('menus', 'getMenuItem', { menuId: menuId, menuItemId: menuItemId });
		// const data = await storeUtils.execute('menus', 'getMenuItem', { code: 481 });	this doesn't work so a few lines below
		if(data) {
			if(data.errors) {
				dispatch({ type: actionTypes.menusFail, errors: data.errors });
			} else {
				// dispatch({ type: actionTypes.menusFail, errors: [ { code: 403, msg: 'testing a 403 error' } ] });
				data.menuItem.menuItemId = menuItemId;	// this is a fiddle so that when in dev & using local "json api", the menuItemId is correct
				dispatch({ type: actionTypes.fetchItemSuccess, menu: menu, item: data.menuItem });
			}
		}
	},

	setItem: item => {
		return { type: actionTypes.setItemSuccess, item: item };
	},

	addItem: (menu, item) => async dispatch => {
		dispatch({ type: actionTypes.addItemRequest });
		const section = menu.menuSections.find(section => section.title === item.menuSectionTitle);
		const newItem = {};	// here, we're working with a subset of all an item's fields so...
		newItem.orderIndex = section?.menuItems ? utilities.getNextOrderIndex(section.menuItems) : 1;
		newItem.menuId = menu.menuId;
		newItem.menuSectionTitle = item.menuSectionTitle;
		newItem.name = item.name;
		newItem.price = item.price;
		newItem.salesTaxCode = item.salesTaxCode;
		newItem.dscr = item.dscr;
		newItem.calories = item.calories;
		newItem.isInStock = item.isInStock;	// not needed by the api but I need it for the reducer
		const response = await storeUtils.execute('menus', 'addMenuItem', newItem);
		if(response.errors) {
			// this needs to support success BUT with potential errors and/or warnings - how?
			dispatch({ type: actionTypes.menusFail, errors: response.errors });
		} else {
			dispatch({ type: actionTypes.addItemSuccess, menu: menu, item: newItem, response: response });
		}
	},

	updateItem: (menu, item) => async dispatch => {
		dispatch({ type: actionTypes.updateItemRequest });
		const request = { ...item, menuId: menu.menuId };
		const data = await storeUtils.execute('menus', 'updateMenuItem', request);
		if(data.errors) {
			dispatch({ type: actionTypes.menusFail, errors: data.errors });
		} else {
			dispatch({ type: actionTypes.updateItemSuccess, menu: menu, item: item, response: data });
		}
	},

	deleteItem: (menu, item) => async dispatch => {
		dispatch({ type: actionTypes.deleteItemRequest });
		const data = await storeUtils.execute('menus', 'deleteMenuItem', { menuId: menu.menuId, menuItemId: item.menuItemId });
		if(data.errors) {
			dispatch({ type: actionTypes.menusFail, errors: data.errors });
		} else {
			dispatch({ type: actionTypes.deleteItemSuccess, menu: menu, item: item });
		}
	},

	shiftItem: (menu, item, isUp) => async dispatch => {
		dispatch({ type: actionTypes.reorderItemsRequest });
		const section = menu.menuSections.find(section => section.menuSectionId === item.menuSectionId);
		const request = storeUtils.getShiftElementRequest(section.menuItems, item.menuItemId, isUp, 'menuItemId');
		request.menuId = menu.menuId;
		const data = await storeUtils.execute('menus', 'reorderMenuItems', request);
		if(data.errors) {
			dispatch({ type: actionTypes.menusFail, errors: data.errors });
		} else {
			dispatch({ type: actionTypes.reorderItemsSuccess, menu: menu, section: section, orderIndexes: request.orderIndexes });
		}
	},

	updateItemModifierGroup: (menu, item, group) => async dispatch => {
		const request = {
			...group,
			menuId: menu.menuId,
			menuItemId: item.menuItemId
		}
		dispatch({ type: actionTypes.updateItemModifierGroupRequest });
		const data = await storeUtils.execute('menus', 'updateMenuItemModifierGroup', request);
		if(data.errors) {
			dispatch({ type: actionTypes.menusFail, errors: data.errors });
		} else {
			dispatch({ type: actionTypes.updateItemModifierGroupSuccess, menu: menu, item: item, group: group });
		}
	},

	deleteItemModifierGroup: (menu, item, group) => async dispatch => {
		dispatch({ type: actionTypes.deleteItemModifierGroupRequest });
		const request = {
			menuId: menu.menuId,
			menuItemId: item.menuItemId,
			modifierGroupId: group.modifierGroupId
		};
		const data = await storeUtils.execute('menus', 'deleteMenuItemModifierGroup', request);
		if(data.errors) {
			dispatch({ type: actionTypes.menusFail, errors: data.errors });
		} else {
			dispatch({ type: actionTypes.deleteItemModifierGroupSuccess, menu: menu, item: item, group: group });
		}
	},

	shiftItemModifierGroup: (menu, item, group, isUp) => async dispatch => {
		dispatch({ type: actionTypes.reorderItemModifierGroupsRequest });
		const itemModifierGroups = item.modifierGroups;
		const request = storeUtils.getShiftElementRequest(itemModifierGroups, group.modifierGroupId, isUp, 'modifierGroupId');
		request.menuId = menu.menuId;
		request.menuItemId = item.menuItemId;
		const data = await storeUtils.execute('menus', 'reorderMenuItemModifierGroups', request);
		if(data.errors) {
			dispatch({ type: actionTypes.menusFail, errors: data.errors });
		} else {
			dispatch({ type: actionTypes.reorderItemModifierGroupsSuccess, menu: menu, item: item, orderIndexes: request.orderIndexes });
		}
	},

	uploadMenuItemPhoto: (menu, item, menuItemPhoto) => async dispatch => {
		dispatch({ type: actionTypes.uploadMenuItemPhotoRequest });
		const data = await storeUtils.execute('photos', 'uploadMenuItemPhoto',
			storeUtils.getPhotoPayload({ menuId: menu.menuId, menuItemId: item.menuItemId }, menuItemPhoto));
		if(data.errors) {
			dispatch({ type: actionTypes.menusFail, errors: data.errors });
		} else {
			dispatch({ type: actionTypes.uploadMenuItemPhotoSuccess, menu: menu, item: item, menuItemPhoto: data.photo });
		}
	},

	deleteMenuItemPhoto: (menu, item, menuItemPhoto) => async dispatch => {
		dispatch({ type: actionTypes.deleteMenuItemPhotoRequest });
		const data = await storeUtils.execute('photos', 'deletePhoto', { photoType: 'i', photoId: menuItemPhoto.photoId });
		if(data.errors) {
			dispatch({ type: actionTypes.menusFail, errors: data.errors });
		} else {
			dispatch({ type: actionTypes.deleteMenuItemPhotoSuccess, menu: menu, item: item, menuItemPhoto: menuItemPhoto });
		}
	},

};

export const reducer = (state = initialState, action) => {

	if(action.type.endsWith('_MENUS_REQUEST')) {
		return {
			...state,
			loading: action.type,
			errors: null
		}
	}

	switch(action.type) {
		case actionTypes.menusFail: {
			return {
				...state,
				loading: null,
				errors: action.errors
			}
		}

		case actionTypes.fetchMenusSuccess: {
			// need to sort at every level as no guarantee anything will come pre-sorted
			// at the same time, build / store a list of all available de-dup'd menu sections as needed a lot
			const menus = action.response.menus.sort((el1, el2) => el1.orderIndex - el2.orderIndex);
			const sections = getSections(menus);
			return {
				...state,
				loading: null,
				menus: menus,
				sections: sections,
				item: null
			}
		}

		case actionTypes.fetchOrderMenusSuccess: {
			// no need (at present) for invisible menus so drop em...
			const orderMenus = action.orderMenus.filter(menu => menu.isVisible === 1);
			// need to sort at every level as no guarantee anything will come pre-sorted
			orderMenus.sort((el1, el2) => el1.orderIndex - el2.orderIndex);
			// let's get all the items for the add order item search functionality while we're at it...
			const orderItems = [];
			orderMenus.forEach(menu => {
				menu.menuSections.forEach(section => {
					section.menuItems && section.menuItems.sort((el1, el2) => el1.orderIndex - el2.orderIndex);
					section.menuItems.forEach(item => {
						if(item.isHidden !== 1) {
							item.menuId = menu.menuId;
							item.menuSectionId = section.menuSectionId;
							// many things need sorting now...
							item.modifierGroups.sort((el1, el2) => el1.orderIndex - el2.orderIndex);
							item.modifierGroups.forEach(group => {
								group.modifierItems.sort((el1, el2) => el1.orderIndex - el2.orderIndex);
							});
							orderItems.push(item);
						}
					});
				});
				menu.menuSections.sort((el1, el2) => el1.orderIndex - el2.orderIndex);
				utilities.sortByField(orderItems, 'name');
			});
			return {
				...state,
				loading: null,
				orderMenus: orderMenus,
				orderItems: orderItems
			}
		}

		case actionTypes.addMenuSuccess: {
			action.menu.menuSections = [];
			const menus = [...state.menus, action.menu];
			return {
				...state,
				loading: null,
				menus: menus
			}
		}

		case actionTypes.updateMenuSuccess: {
			const menus = state.menus.map(menu => menu.menuId === action.menu.menuId ? action.menu : menu);
			return {
				...state,
				loading: null,
				menus: menus
			}
		}

		case actionTypes.deleteMenuSuccess: {
			const menus = storeUtils.deleteElement(state.menus, action.menu.menuId, 'menuId');
			return {
				...state,
				loading: null,
				menus: menus
			}
		}

		case actionTypes.reorderMenusSuccess: {
			const menus = state.menus.map(menu => {
				const newMenu = { ...menu };
				newMenu.orderIndex = action.orderIndexes.find(menu => menu.menuId === newMenu.menuId).orderIndex;
				return newMenu;
			});
			menus.sort((el1, el2) => el1.orderIndex - el2.orderIndex);
			return {
				...state,
				loading: null,
				menus: menus
			}
		}

		case actionTypes.addSectionSuccess: {
			const menus = storeUtils.processChildCollection(state.menus, 'menuId', action.menu.menuId, 'menuSections', sections => {
				return [...sections, action.section];
			});
			// we now need to update state.sections with the new section...
			const sections = [ ...state.sections, { ...action.section }];
			utilities.sortByField(sections, 'title');
			return {
				...state,
				loading: null,
				menus: menus,
				sections: sections
			}
		}

		case actionTypes.updateSectionSuccess: {
			// the api response payload contains the entire menu rebuilt with the updated section, so we need to store...
			const menus = state.menus.map(menu => menu.menuId === action.menu.menuId ? action.menu : menu);
			// ...and update the store sections too
			const sections = getSections(menus);
			return {
				...state,
				loading: null,
				menus: menus,
				sections: sections
			}
		}

		case actionTypes.deleteSectionSuccess: {
			const menus = storeUtils.processChildCollection(state.menus, 'menuId', action.menu.menuId, 'menuSections', sections => {
				return storeUtils.deleteElement(sections, action.section.menuSectionId, 'menuSectionId');
			});
			const sections = storeUtils.deleteElement(state.sections, action.section.menuSectionId, 'menuSectionId');
			return {
				...state,
				loading: null,
				menus: menus,
				sections: sections
			}
		}

		case actionTypes.reorderSectionsSuccess: {
			const menus = storeUtils.processChildCollection(state.menus, 'menuId', action.menu.menuId, 'menuSections', sections => {
				const newSections = sections.map(section => {
					const newSection = { ...section };
					newSection.orderIndex = action.orderIndexes.find(section => section.menuSectionId === newSection.menuSectionId).orderIndex;
					return newSection;
				});
				return newSections.sort((el1, el2) => el1.orderIndex - el2.orderIndex);
			});
			return {
				...state,
				loading: null,
				menus: menus
			}
		}

		case actionTypes.fetchItemSuccess: {
			action.item.modifierGroups.sort((el1, el2) => el1.orderIndex - el2.orderIndex);

			// populate the menuSectionTitle from the available menu sections...
			const section = state.sections.find(section => section.menuSectionId === action.item.menuSectionId);
			action.item.menuSectionTitle = section.title;

			// replace the relevant menu's minimal item with the newly fetched full-fat item...
			const menus = updateItem(state.menus, action.menu, action.item)
			return {
				...state,
				loading: null,
				menus: menus,
				item: action.item
			}
		}

		case actionTypes.setItemSuccess: {
			return {
				...state,
				loading: null,
				item: action.item
			}
		}

		case actionTypes.addItemSuccess: {
			const newItem = { ...action.item,
												menuItemId: action.response.menuItemId,
												menuSectionId: action.response.menuSectionId
											};	// the response contains data needed to round out item
			const [newMenus, newSections] = addItem(state.menus, state.sections, action.menu, newItem, action.response);
			// console.log(action, newItem);
			return {
				...state,
				loading: null,
				menus: newMenus,
				sections: newSections,
				item: newItem
			}
		}

		case actionTypes.updateItemSuccess: {
			// console.log(action);
			if(action.response.tags) {
				// get rid of temporary data...
				delete(action.item.tagIds);
				delete(action.item.tagNames);
			}
			let newMenus, newSections = state.sections;
			if(action.item.menuSectionId === action.response.menuSectionId) {
				// the simplest path
				newMenus = updateItem(state.menus, action.menu, action.item);
			} else {
				// while editing, the section was changed, so more complicated processing needed...
				// 1st delete original item from old section...
				newMenus = deleteItem(state.menus, action.menu, action.item);
				// now we need the item to have the new section details...
				action.item.menuSectionTitle = action.response.menuSectionTitle;
				action.item.menuSectionId = action.response.menuSectionId;
				// then finally, add the item to the new section...
				[newMenus, newSections] = addItem(newMenus, state.sections, action.menu, action.item, action.response);
			}
			return {
				...state,
				loading: null,
				menus: newMenus,
				sections: newSections,
				item: action.item
			}
		}

		case actionTypes.deleteItemSuccess: {
			const newMenus = deleteItem(state.menus, action.menu, action.item);
			return {
				...state,
				loading: null,
				menus: newMenus,
				item: null
			}
		}

		case actionTypes.reorderItemsSuccess: {
			const menus = storeUtils.processChildCollection(state.menus, 'menuId', action.menu.menuId, 'menuSections', sections => {
				return storeUtils.processChildCollection(sections, 'menuSectionId', action.section.menuSectionId, 'menuItems', items => {
					const newItems = items.map(item => {
						const newItem = { ...item };
						newItem.orderIndex = action.orderIndexes.find(item => item.menuItemId === newItem.menuItemId).orderIndex;
						return newItem;
					});
					newItems.sort((el1, el2) => el1.orderIndex - el2.orderIndex);
					return newItems;
				});
			});
			return {
				...state,
				loading: null,
				menus: menus
			}
		}

		case actionTypes.updateItemModifierGroupSuccess: {
			const item = { ...action.item };
			let update = false;
			if(item.modifierGroups) {
				item.modifierGroups = item.modifierGroups.map(group => {
					if(group.modifierGroupId === action.group.modifierGroupId) {
						update = true;
						return action.group;
					} else {
						return group;
					}
				});
			} else {
				// this must be a newly added item so let's give it an empty modifierGroups array
				item.modifierGroups = [];
			}
			if(!update) item.modifierGroups.push(action.group);	// if not an update then append new
			return {
				...state,
				loading: null,
				menus: updateItem(state.menus, action.menu, item),
				item: item
			}
		}

		case actionTypes.deleteItemModifierGroupSuccess: {
			const item = { ...action.item };
			item.modifierGroups = storeUtils.deleteElement(item.modifierGroups, action.group.modifierGroupId, 'modifierGroupId');
			return {
				...state,
				loading: null,
				menus: updateItem(state.menus, action.menu, item),
				item: item
			}
		}

		case actionTypes.reorderItemModifierGroupsSuccess: {
			const item = { ...action.item };
			item.modifierGroups = item.modifierGroups.map(group => {
				const newGroup = { ...group };
				newGroup.orderIndex = action.orderIndexes.find(group => group.modifierGroupId === newGroup.modifierGroupId).orderIndex;
				return newGroup;
			});
			item.modifierGroups.sort((el1, el2) => el1.orderIndex - el2.orderIndex);
			return {
				...state,
				loading: null,
				menus: updateItem(state.menus, action.menu, item),
				item: item
			}
		}

		case actionTypes.uploadMenuItemPhotoSuccess: {
			const item = { ...action.item };
			if(!item.photos) item.photos = [];
			item.photos = [...item.photos, action.menuItemPhoto];
			return {
				...state,
				loading: null,
				menus: updateItem(state.menus, action.menu, item),
				item: item
			}
		}

		case actionTypes.deleteMenuItemPhotoSuccess: {
			const item = { ...action.item };
			const ix = item.photos.findIndex(photo => photo.photoId === action.menuItemPhoto.photoId);
			const newPhotos = [...item.photos];
			newPhotos.splice(ix, 1);
			item.photos = newPhotos;
			return {
				...state,
				loading: null,
				menus: updateItem(state.menus, action.menu, item),
				item: item
			}
		}

		default: {
			return state;
		}
	}
}


// various helper functions...

function findMenuItem(menu, id) {
	for(const section of menu.menuSections) {
		const item = section.menuItems?.find(item => item.menuItemId === id);
		if(item) return item;
	}
	return null;
}

function updateItem(stateMenus, actionMenu, actionItem) {
	const menus = storeUtils.processChildCollection(stateMenus, 'menuId', actionMenu.menuId, 'menuSections', sections => {
		return storeUtils.processChildCollection(sections, 'menuSectionId', actionItem.menuSectionId, 'menuItems', items => {
			return items.map(item => item.menuItemId === actionItem.menuItemId ? actionItem : item);
		});
	});
	return menus;
}

function getSections(menus) {
	let sections = [];
	menus.forEach(menu => {
		menu.menuSections.forEach(section => {
			sections[section.menuSectionId] = { title: section.title, orderIndex: section.orderIndex };
			section.menuItems && section.menuItems.sort((el1, el2) => el1.orderIndex - el2.orderIndex);
		});
		menu.menuSections.sort((el1, el2) => el1.orderIndex - el2.orderIndex);
	});
	// now build list of unique sections
	sections = sections.map((section, ix) => {
		return { ...section, menuSectionId: ix };
	}).filter(Boolean);	// little trick to remove nulls
	utilities.sortByField(sections, 'title');
	return sections;
}

function addItem(stateMenus, stateSections, menu, item, response) {
	let addedToExistingSection = false;
	let newSections = stateSections;
	let newMenus = storeUtils.processChildCollection(stateMenus, 'menuId', menu.menuId, 'menuSections', sections => {
		return storeUtils.processChildCollection(sections, 'menuSectionId', response.menuSectionId, 'menuItems', items => {
			addedToExistingSection = true;
			return items ? [...items, item] : [item];
		});
	});
	if(!addedToExistingSection) {
		const newSection = {
			menuSectionId: response.menuSectionId,
			title: response.menuSectionTitle,
			menuItems: [item]
		};
		newMenus = storeUtils.processChildCollection(stateMenus, 'menuId', menu.menuId, 'menuSections', sections => {
			newSection.orderIndex = utilities.getNextOrderIndex(sections);
			return [...sections, newSection];
		});
		// we now need to update state.sections with the new section...
		newSections = [ ...stateSections, {
			menuSectionId: newSection.menuSectionId,
			title: newSection.title,
			orderIndex: newSection.orderIndex
		}];
		utilities.sortByField(newSections, 'title');
	}
	return [newMenus, newSections];
}

function deleteItem(stateMenus, menu, item) {
	const newMenus = storeUtils.processChildCollection(stateMenus, 'menuId', menu.menuId, 'menuSections', sections => {
		return storeUtils.processChildCollection(sections, 'menuSectionId', item.menuSectionId, 'menuItems', items => {
			return storeUtils.deleteElement(items, item.menuItemId, 'menuItemId');
		});
	});
	return newMenus;
}