import { Fragment, useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { IconButton } from "components/buttons";
import { PaneBody, PaneFooter } from "components/Pane";
import utilities from "services/utilities";
import useL10n from "L10n";
import { actionTypes } from "store/menus";
import ConfirmDialog from "dialogs/ConfirmDialog";
import PhotoViewer from "components/PhotoViewer";
import './UploaderPane.scss';

const UploaderPane = props => {
	const _t = useL10n().getText;
	const [photoNumber, setPhotoNumber] = useState(0);
	const [showFullscreen, setShowFullscreen] = useState();
	const [showConfirmDialog, setShowConfirmDialog] = useState(false);
	const photosCount = props.photos?.length;
	const currentPhoto = photosCount ? props.photos[photoNumber] : null;

	useEffect(() => {
		setPhotoNumber(0);
	}, [props.photos]);

	const preventDefaults = e => {
		e.preventDefault();
		e.stopPropagation();
	}

	const addClass = e => {
		const dropZone = uploaderRef.current;
		if(dropZone.classList.contains('UploaderPane_disabled')) return;
		dropZone.classList.add('UploaderPane_dragover');
		e.dataTransfer.dropEffect = 'copy';
	}

	const removeClass = e => {
		const dropZone = uploaderRef.current;
		if(dropZone.classList.contains('UploaderPane_disabled')) return;
		dropZone.classList.remove('UploaderPane_dragover');
	}

	const uploaderRef = useRef();

	useEffect(() => {
		const onDrop = e => {
			if(uploaderRef.current.classList.contains('UploaderPane_disabled')) return;
			const dt = e.dataTransfer;
			const { files } = dt;
			props.onPhotoDrop(files);
		}
	
		// console.log('initialise drag and drop...');
		const dropZone = uploaderRef.current;
		['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => dropZone.addEventListener(eventName, preventDefaults, false));
		['dragenter', 'dragover'].forEach(eventName => dropZone.addEventListener(eventName, addClass, false));
		['dragleave', 'drop'].forEach(eventName => dropZone.addEventListener(eventName, removeClass, false));
		dropZone.addEventListener('drop', onDrop, false);

		return () => {
			// console.log('deactivate drag and drop...');
			['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => dropZone.removeEventListener(eventName, preventDefaults, false));
			['dragenter', 'dragover'].forEach(eventName => dropZone.removeEventListener(eventName, addClass, false));
			['dragleave', 'drop'].forEach(eventName => dropZone.removeEventListener(eventName, removeClass, false));
			dropZone.removeEventListener('drop', onDrop, false);
		}
	}, [props]);

	const setPhoto = inc => {
		if(inc === -1 && photoNumber > 0) setPhotoNumber(photoNumber - 1);
		if(inc === 1 && photoNumber < props.photos.length - 1) setPhotoNumber(photoNumber + 1);
	}

	const buttonClickHandler = e => {
		switch(e.currentTarget.innerText) {
			case 'keyboard_arrow_left': setPhoto(-1); break;
			case 'keyboard_arrow_right': setPhoto(1); break;
			case 'delete': setShowConfirmDialog(true); break;
			default: break;
		}
	}

	const hideConfirmDialogHander = response => {
		if(response.button === 'btnOk') {
			props.onPhotoDelete(props.photos[photoNumber]);
		}
		setShowConfirmDialog(false);
	}

	const getBackgroundStyle = () => {
		if(props.photos && props.photos[photoNumber]) {
			const photo = props.photos[photoNumber];
			let photoUrl = photo.photoURL;
			const width = utilities.findNearest(photo.widths, 182);
			photoUrl = photoUrl.replace('xxxx', width);
			const style = { backgroundImage: `url(${ photoUrl })` };
			return style;
		}
	}

	const loading = props.loading === actionTypes.uploadMenuItemPhotoRequest || props.loading === actionTypes.deleteMenuItemPhotoRequest;
	const classes = classNames('Pane clipped UploaderPane', props.className, { 'UploaderPane_disabled': props.disabled }, { 'async': loading });
	return (
		<Fragment>

			<section ref={ uploaderRef } className={ classes }>

				<PaneBody>

					<div className="UploaderPane-photo" style={ getBackgroundStyle() } onClick={ () => setShowFullscreen(true) }/>

				</PaneBody>

				<PaneFooter className={ classNames('toolbar', { 'async': loading }) }>

					<button
						className="btn ph10"
						disabled={ !photosCount }
						onClick={ buttonClickHandler }
					>
						<span>{ _t('BTN_UPLOAD', true) }</span>
					</button>
					<IconButton
						icon="delete"
						disabled={ photosCount < 2 }
						onClick={ buttonClickHandler }
					/>
					<IconButton
						icon="keyboard_arrow_left"
						disabled={ !photosCount || photoNumber === 0}
						onClick={ buttonClickHandler }
					/>
					<IconButton
						icon="keyboard_arrow_right"
						disabled={ !photosCount || photoNumber === photosCount - 1}
						onClick={ buttonClickHandler }
					/>

				</PaneFooter>

			</section>

			<PhotoViewer show={ showFullscreen } photoURL={ currentPhoto?.photoURL } closeHandler={ () => setShowFullscreen(false) }/>

			<ConfirmDialog
				heading={ _t('HDG_CONFIRM_DELETE', true, _t('TXT_PHOTO')) }
				message={ _t('TXT_CONFIRM_DELETE', false, _t('TXT_PHOTO')) }
				show={ showConfirmDialog }
				onHide={ hideConfirmDialogHander}
			/>

		</Fragment>
	);
};

export default UploaderPane;