import classNames from 'classnames';
import { FormInput, FormInputWithButton } from 'components/formControls';
import { Pane, PaneHeader, PaneBody } from 'components/Pane';
import TagList from 'components/TagList';

const VenueInfo = props => {

	const openUrl = e => {
		window.open(props.form.data.webUrl, 'external');
	}

	return (
		<Pane id="venueInfo" className="Pane_inner">
			<PaneHeader>Venue Info</PaneHeader>
			<PaneBody className="container">

				<div className="form-row">
					<div className="col-sm">
						<div className="form-row">
							<div className="col">

								<FormInput placeholder="Name" form={ props.form } field="name"/>

							</div>
						</div>
						<div className="form-row">
							<div className="col">

								<FormInput placeholder="Phone" form={ props.form } field="phone"/>

							</div>
						</div>
						<div className="form-row">
							<div className="col">

								<FormInputWithButton
									type="url"
									placeholder="Website"
									form={ props.form }
									field="webUrl"
									icon="open_in_new"
									buttonDisabled={ !props.form.data?.webUrl }
									onButtonClick={ openUrl }
								/>

							</div>
						</div>
					</div>
					<div className="col-sm">

						<FormInput type="textarea" className="form-control_3h" placeholder="Short Intro or Description" form={ props.form } field="dscr"/>

					</div>
				</div>
				<div className="form-row">
					<div className="col">

						<TagList
							className={ classNames({ 'validation-fail': props.form.errors?.cuisineTypes }) }
							placeholder="add cuisine type"
							name="cuisineTypes"
							list={ props.form.data.cuisineTypes }
							data={ props.cuisineTypes }
							maxMatches={ 5 }
							minLength={ 2 }
							highlight={ true }
							onChange={ props.form.onChange }
						/>

					</div>
				</div>

			</PaneBody>
		</Pane>
	);
};

export default VenueInfo;