import { Fragment, useEffect, useRef, useState } from 'react';
import { useStripe, useElements, IbanElement } from '@stripe/react-stripe-js';
import useL10n from 'L10n';
import useForm from 'components/Form';
import { TextButton } from 'components/buttons';
import ValidationMessage from 'components/ValidationMessage';
import IbanForm from './IbanForm';

export default function PaymentSetupForm(props) {
	const _t = useL10n().getText;
	const [error, setError] = useState();
	const [async, setAsync] = useState(false);
	const stripe = useStripe();
	const elements = useElements();

	const formSave = async () => {
		if(!stripe || !elements) {
			console.info('waiting for Stripe to load...');
			return;
		}
		const iban = elements.getElement(IbanElement);
		setAsync(true);
		const result = await stripe.confirmSepaDebitSetup(props.settings.setupIntentClientSecret, {
			payment_method: {
				sepa_debit: iban,
				billing_details: {
					name: form.data.accountholderName,
					email: form.data.accountholderEmail
				},
			}
		});
		setAsync(false);
		if(result.error) {
			setError(result.error.message);
			console.error(result.error.message);
		} else {
			// The SetupIntent is in the 'succeeded' state.
			props.onHide();
		}
	}

	const form = useForm(formSave, {
		accountholderName: true,
		accountholderEmail: true
	});
	const resetRef = useRef(form.reset);

	useEffect(() => {
		if(props.show) {
			resetRef.current({
				accountholderEmail: props.settings.email
			});
		}
	
	}, [props.show, props.settings?.email]);

	return (
		<Fragment>

			{ error
					? <ValidationMessage open={ !!error } message={ error }/>
					:	<ValidationMessage open={ !!form.errors }/>
			}
			

			<IbanForm
				form={ form }
				disabled={ !stripe }
				settings={ props.settings }
			/>

			<div className="Modal-footer">
				<TextButton className="btn-secondary focus" onClick={ props.onHide }>{ _t('BTN_CANCEL') }</TextButton>
				<TextButton className="btn-primary" async={ async } disabled={ !stripe } onClick={ form.onSave }>{ _t('BTN_SET_UP_SEPA_DD') }</TextButton>
			</div>

		</Fragment>
	);
}