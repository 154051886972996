import classNames from "classnames";

const ItemTagList = props => {

	const onClick = e => {
		if(!props.disabled) {
			props.onClick(e);
		}
	}

	const classes = classNames('tagList mt10', { 'tagList_empty': !props.tags?.length }, { 'tagList_disabled': props.disabled });
	return (
		<ul className={ classes } onClick={ onClick }>
			{ props.tags?.length > 0
				?
					(	props.tags.map(tag => {
							return <li key={ tag.id || tag } className="tag">
								{ tag.tagName || tag }
							</li>
					}))
				:
					<li>
						Add tags to enable search<br/>
						<span className="material-icons">bookmark</span>
					</li>
			}
		</ul>
	);

};

export default ItemTagList;