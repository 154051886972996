import { Fragment } from "react";
import { IconButton } from "components/buttons";
import DropdownButton from "components/DropdownButton";
import useForm from "components/Form";
import { FormCheckbox, FormInput, FormRadio } from "components/formControls";
import Typeahead from "components/Typeahead.js";
import states from './states.js';	// sample data for typeahead

const Forms = props => {

	const form = useForm();
	console.log(form.data);
	return (
		<Fragment>

		<div className="controls-stacked">

			<Typeahead
				placeholder="US states"
				name="state"
				data={ states }
				keys="id"
				labels="display"
				maxMatches={ 5 }
				minLength={ 2 }
				highlight={ true }
				onChange={ () => { } }
			/>

			<FormInput form={ form } field="one" placeholder="One"/>

			<FormInput form={ form } field="two" placeholder="Two"/>

			<DropdownButton placeholder="children" name="children" onChange={ () => {} }>
				<option role="menuitem"></option>
				<option value="1">Peter</option>
				<option value="2">Susan</option>
				<option value="3">Edmund</option>
				<option value="4">Lucy</option>
			</DropdownButton>

			<FormInput type="textarea" form={ form } field="three" placeholder="Three"/>

			<div className="form-control_buttoned">
				<FormInput placeholder="Sales Tax Code" form={ form } field="salesTaxCode"/>
				<IconButton icon="more_horiz" onClick={ props.onSalesTaxButtonClick }/>
			</div>

		</div>

		<div className="controls-stacked mt20">
			<div className="controls-stacked_row">
				<FormRadio name="onPromo" className="form-control" form={ form } field="price" value="0" label="Price"/>
				<FormInput form={ form } field="price"/>
			</div>
			<div className="controls-stacked_row">
				<FormRadio name="onPromo" className="form-control" form={ form } field="onPromo" value="1" label="On promo"/>
				<FormInput form={ form } field="promoPrice"/>
			</div>
		</div>

		<div className="controls-stacked mt20">
			<FormCheckbox className="form-control" form={ form } field="one" label="One"/>
			<FormCheckbox className="form-control" form={ form } field="two" label="Two"/>
		</div>

		</Fragment>
	);
};

export default Forms;