import { Fragment, useRef } from "react";
import utilities from "services/utilities";
import { InputWithButton } from "./controls";
import { PaneItem } from "./Pane";

export const SearchControl = ({ menuItems, renderItem, onSearchClick, onSearchItemClick, ...props }) => {
	const menuRef = useRef();

	const searchKeyDownHandler = e => {
		switch(e.key) {
			case 'ArrowUp':
			case 'ArrowDown':
				e.preventDefault();
				const menu = menuRef.current.childNodes;
				const option = menu[0];
				if(option) option.focus();
				break;
			case 'Enter': onSearchClick(); break;
			default: break;
		}
	}

	const optionKeyDownHandler = e => {
		let index, option;
		const items = menuRef.current.children.length;
		switch(e.key) {
			case 'ArrowUp':
				e.preventDefault();
				index = utilities.nodeIndex(e.currentTarget);
				if(index) {
					option = menuRef.current.childNodes[--index];
				} else {
					option = menuRef.current.childNodes[items - 1];
				}
				if(option) option.focus();
				break;
			case 'ArrowDown':
				e.preventDefault();
				index = utilities.nodeIndex(e.currentTarget);
				if(index < (items - 1)) {
					option = menuRef.current.childNodes[++index];
				} else {
					option = menuRef.current.childNodes[0];
				}
				if(option) option.focus();
				break;
			case 'Enter':
				e.currentTarget.click();
				break;
			default: break;
		}
	}

	return (
		<Fragment>
			<InputWithButton
				type="search"
				icon="search"
				onKeyDown={ searchKeyDownHandler }
				onButtonClick={ onSearchClick }
				{ ...props }
			/>
			{ menuItems && menuItems.length > 0 &&
				<ul ref={ menuRef } className="Pane-items Pane-items_inline scrolling">
					{ menuItems.map((item, ix) => {
						return (
							<PaneItem
								key={ ix }
								role="menuitem"
								tabIndex="-1"
								index={ item }
								onClick={ onSearchItemClick }
								onKeyDown={ optionKeyDownHandler }
							>
								{ renderItem(item) }
							</PaneItem>
						);
					})}
				</ul>
			}
		</Fragment>
	);
};