import storeUtils from './storeUtils';

export const actionTypes = {
	rewardsFail: 'REWARDS_FAIL',

	fetchLoyaltyRewardsSettingsRequest: 'FETCH_LOYALTYREWARDSSETTINGS_REWARDS_REQUEST',
	fetchLoyaltyRewardsSettingsSuccess: 'FETCH_LOYALTYREWARDSSETTINGS_REWARDS_SUCCESS',

	updateLoyaltyRewardsSettingsRequest: 'UPDATE_LOYALTYREWARDSSETTINGS_REWARDS_REQUEST',
	updateLoyaltyRewardsSettingsSuccess: 'UPDATE_LOYALTYREWARDSSETTINGS_REWARDS_SUCCESS',

	fetchCustomerLoyaltyStatsRequest: 'FETCH_CUSTOMERLOYALTYSTATS_REWARDS_REQUEST',
	fetchCustomerLoyaltyStatsSuccess: 'FETCH_CUSTOMERLOYALTYSTATS_REWARDS_SUCCESS'
}

const initialState = { loyaltyRewardsSettings: null, loading: null, errors: null };

export const rewardsActionCreators = {
	
	
	fetchLoyaltyRewardsSettings: () => async dispatch => {
		dispatch({ type: actionTypes.fetchLoyaltyRewardsSettingsRequest });
		const response = await storeUtils.execute('rewards', 'getLoyaltyRewardsSettings');
		if(response.errors) {
			dispatch({ type: actionTypes.rewardsFail, errors: response.errors });
		} else {
			dispatch({ type: actionTypes.fetchLoyaltyRewardsSettingsSuccess, response: response });
		}
	},
	
	updateLoyaltyRewardsSettings: loyaltyRewardsSettings => async dispatch => {
		dispatch({ type: actionTypes.updateLoyaltyRewardsSettingsRequest });
		const data = await storeUtils.execute('rewards', 'updateLoyaltyRewardsSettings', loyaltyRewardsSettings);
		if(data.errors) {
			dispatch({ type: actionTypes.rewardsFail, errors: data.errors });
		} else {
			dispatch({ type: actionTypes.updateLoyaltyRewardsSettingsSuccess, loyaltyRewardsSettings: loyaltyRewardsSettings });
		}
	},

	fetchCustomerLoyaltyStats: payload => async dispatch => {
		dispatch({ type: actionTypes.fetchCustomerLoyaltyStatsRequest });
		const response = await storeUtils.execute('rewards', 'getCustomerLoyaltyStats', payload);
		if(response.errors) {
			dispatch({ type: actionTypes.rewardsFail, errors: response.errors });
		} else {
			dispatch({ type: actionTypes.fetchCustomerLoyaltyStatsSuccess, response: response });
		}
	}
};

export const reducer = (state = initialState, action) => {

	if(action.type.endsWith('REWARDS_REQUEST')) {
		return {
			...state,
			loading: action.type,
			errors: null
		}
	}

	switch(action.type) {
		case actionTypes.rewardsFail: {
			return {
				...state,
				loading: null,
				errors: action.errors
			}
		}

		case actionTypes.fetchLoyaltyRewardsSettingsSuccess: {
			return {
				...state,
				loading: null,
				loyaltyRewardsSettings: action.response
			}
		}

		case actionTypes.updateLoyaltyRewardsSettingsSuccess: {
			return {
				...state,
				loading: null,
				loyaltyRewardsSettings: action.loyaltyRewardsSettings
			}
		}

		case actionTypes.fetchCustomerLoyaltyStatsSuccess: {
			return {
				...state,
				loading: null,
				customerLoyaltyStats: action.response
			}
		}

		default: {
			return state;
		}
	}
}