import { Fragment, useEffect, useRef } from 'react';
import classNames from 'classnames';
import utilities from 'services/utilities';
import useL10n from 'L10n';
import { IconButton } from 'components/buttons';
import useForm from 'components/Form';
import { FormCheckbox, FormInput } from 'components/formControls';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'components/Modal';
import { Pane, PaneBody, PaneHeader, PaneItem } from 'components/Pane';

const OrderModifyDialog = props => {
	const _t = useL10n().getText;
	const onShowRef = useRef(props.onShow);

	const form = useForm();
	const formRef = useRef(form);

	useEffect(() => {
		if(props.show === 1) {
			// 1 = freshly opened from order details dlg, not from add order item dialog (2)
			formRef.current.reset({});
			if(onShowRef) onShowRef.current();
		}
	}, [props.show]);

	const buttonClickHandler = e => {
		if(e.currentTarget.id === 'btnOk') {
			props.onHide(e, form.data);
		} else {
			props.onHide(e);
		}
	}

	const isDisabled = () => {
		if(props.itemsToAdd.length) return false;	// if there are items to add, enable submit btn
		if(props.order?.orderItems.find(item => item.delete)) return false;	// if there are items to remove, enable submit btn
		return true;
	}

	const itemClickHandler = (ix, e) => {
		// console.log(e.currentTarget.classList);
		if(e.currentTarget.classList.contains('addMe')) {
			props.removeAddedItem(ix);
		} else {
			const newOrder = { ...props.order };
			if(typeof ix === 'number') {
				const item = newOrder.orderItems[ix];
				item.delete = !item.delete;
				if(item.delete && item.modifierItems.length) {
					// if the item is to be deleted, no need to track the modifiers...
					item.modifierItems.forEach(mod => mod.delete = false);
				}
			} else {
				const item = newOrder.orderItems[ix[0]];
				if(item.delete) return;
				const mod = item.modifierItems[ix[1]];
				// console.log(mod);
				mod.delete = !mod.delete;
			}
			props.setOrder(newOrder);
		}
	}

	const renderItem = (obj, ix, isAdded) => {
		// a new item and an order item currently are a little different so...
		const item = isAdded ? obj.item : obj;
		const qty = obj.qty;
		const cost = item.itemCost || item.price;
		const classes = classNames({ 'deleteMe': item.delete }, {'addMe': obj.item });
		return <Fragment key={ 'i-' + ix }>
			<PaneItem className={ classes } index={ ix } onClick={ itemClickHandler }>
				<div className="col-9">
					{ qty } &nbsp;&nbsp; { item.name }
				</div>
				<div className="col-3">
					{ utilities.formatPrice(cost, props.currencySymbol) }
				</div>
			</PaneItem>
			{	item.modifierItems?.map((mod, mix) => {
					// console.log(mod);
					const index = [ix, mix];
					// we can't yet remove or change modifiers, so the || true permanently locks this in until we have the api support...
					const classes = classNames('modifier', { 'marked': mod.delete }, { 'locked': item.delete || true });
					// onClick={ itemClickHandler } removed for same reason as above...
					return <PaneItem key={ 'm-' + mix } className={ classes } index={ index }>
						<div className="col-9">
							{ mod.name }
						</div>
						<div className="col-3">
							{ mod.itemCost > 0 && utilities.formatPrice(mod.itemCost, props.currencySymbol) }
						</div>
					</PaneItem>
				})
			}
		</Fragment>
	}

	return (
		<Modal id="orderModifyDialog" show={ props.show } onHide={ props.onHide }>
			<ModalHeader>{ _t('HDG_ORDER_MODIFY', true) + ': ' + props.order?.externalOrderId }</ModalHeader>
			<ModalBody>
				<p>{ _t('TXT_ORDER_MODIFY') }</p>

				<Pane>
					<PaneHeader className="Pane-header_headings">
						<div className="col-9">{ _t('HDG_ORDER_ITEM', true) }</div>
						<div className="col-3">{ _t('HDG_PRICE', true) }</div>
						<div className="Pane-toolbar">
							<IconButton title={ _t('TTP_ADD_ITEM') } icon="add" id="btnAdd" onClick={ props.onHide }/>
						</div>
					</PaneHeader>
					<PaneBody tag="ul" className="Pane-items Pane-items_p0 scrolling">
						{
							props.order?.orderItems.map((item, ix) => {
								return renderItem(item, ix, false);
							})
						}
						{
							props.itemsToAdd.map((item, ix) => {
								return renderItem(item, ix, true);
							})
						}
					</PaneBody>
				</Pane>

				<div className="form-row">
					<div className="col">
						<FormInput type="textarea" placeholder={ _t('PLH_CUSTOMER_MSG') } form={ form } field="cancellationNotes" maxLength="255"/>
					</div>
					<div className="col text-right">
						<FormCheckbox form={ form } field="outOfStock" labelFirst>
							{ _t('LBL_MARK_ITEMS') }
						</FormCheckbox>
						<p className="hint">{ _t('TXT_MARK_HELP') }</p>
					</div>
				</div>

			</ModalBody>
			<ModalFooter>
				<button className="btn btn-secondary" onClick={ buttonClickHandler } id="btnCancel">
					{ _t('BTN_CANCEL', true) }
				</button>
				<button className="btn btn-primary focus" disabled={ isDisabled() } onClick={ buttonClickHandler } id="btnOk">
					{ _t('BTN_ORDER_MODIFY', true) }
				</button>
			</ModalFooter>
		</Modal>
	);
};

export default OrderModifyDialog;