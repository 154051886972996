import { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import useL10n from 'L10n';
import dates from 'services/dates';
import { errorsActionCreators } from 'store/errors';
import { ordersActionCreators } from 'store/orders';
import { printersActionCreators } from 'store/printers';
import { settingsActionCreators } from 'store/settings';
import { hasVenue, venuesActionCreators } from 'store/venues';
import useOrderEvents from 'store/OrderEvents';
import Notification from 'components/Notification';
import ValidationMessage from 'components/ValidationMessage';
import ConfirmDialog from 'dialogs/ConfirmDialog';
import OrderDetailsDialog from 'dialogs/OrderDetailsDialog';
import OrderCancelDialog from './dialogs/OrderCancelDialog';
import OrderModifyProcessContainer from './OrderModifyProcessContainer';
import OrdersList from './OrdersList';
import './OrdersList.scss';

const OrdersListContainer = props => {
	const _t = useL10n().getText;
	const [showOrderDetailsDialog, setShowOrderDetailsDialog] = useState(false);
	const [showOrderCancelDialog, setShowOrderCancelDialog] = useState(false);
	const [orderModifyProcessRunning, setOrderModifyProcessRunning] = useState(false);
	const [showConfirmDialog, setShowConfirmDialog] = useState(false);
	const [currentOrder, setCurrentOrder] = useState();
	const [today, setToday] = useState();

	const orderStore = useSelector(state => state.orders);
	const settingStore = useSelector(state => state.settings);
	const venue = useSelector(state => state.venues.venue);
	const currencySymbol = venue?.currencySymbol;
	const timeZone = venue?.openingHours?.timezone.timezone;
	const user = useSelector(state => state.user.user);
	const dispatch = useDispatch();

	const getPayload = options => {
		// console.log(options);
		// prep the payload based on user's settings...
		let from;
		let to;
		const pageSize = calcPageSize(settingStore.fullscreen);
		switch(options.fromOption) {
			case 0:	// today
				from = options.today;
				to = options.today + dates.day - 1;
				break;
			case 1:	// yesterday
				from = options.today - dates.day;
				to = options.today - 1;
				break;
			case 2:	// last 2 days
				from = options.today - dates.day;
				to = options.today + dates.day - 1;
				break;
			case 3:	// last 3 days
				from = options.today - dates.day * 2;
				to = options.today + dates.day - 1;
				break;
			case 4:
				from = dates.zonedDateToUtc(timeZone, options.fromDate).getTime();
				to = options.toDate ? dates.zonedDateToUtc(timeZone, options.toDate).getTime() + dates.day - 1 : null;
				break;
			default: return;
		}
		const payload = {
			orderStatus: options.orderStatus,
			from: from,
			pagingOffset: typeof options.page === 'number' ? (options.page - 1) * pageSize : 0,
			pagingSize: pageSize
		};
		if(to) payload.to = to;
		return payload;
	}

	const setOrders = data => {
		const pageSize = calcPageSize(settingStore.fullscreen);
		dispatch(ordersActionCreators.setOrders(data, settingStore.orderGroupingMinutes, 'all', pageSize));
	}

	const setErrors = errors => {
		dispatch(errorsActionCreators.setErrors(errors));
	}

	const orderEvents = useOrderEvents({
		// verbose: true,
		station: 'all',
		settings: settingStore,
		orders: orderStore.orderData,
		venue: venue,
		setOrders,
		setErrors,
		lostConnectionHandler: lost => setShowConfirmDialog(lost),
		getPayload
	});

	useEffect(() => {
		// this page has no onload api call, so if the page is refreshed, we need to fetch the venue
		if(!venue && hasVenue()) {
			dispatch(venuesActionCreators.fetchVenue());
		}
	}, [venue, dispatch]);

	useEffect(() => {
		// the BUMP button retaining focus can be odd so if it caused the store action, then make it lose focus (PB-244)
		if(!orderStore.loading && currentOrder) {
			const btn = document.activeElement;
			if(btn.classList.contains('Pane-item_textButton')) {
				btn.blur();
			}
		}
	}, [orderStore.loading, currentOrder]);

	useEffect(() => {
		// start by clearing any existing orders in the store...
		dispatch(ordersActionCreators.setOrders({ orders: [] }, settingStore.orderGroupingMinutes));
	}, [dispatch, settingStore.orderGroupingMinutes]);

	useEffect(() => {
		if(orderStore.orderData) {
			// keep store orders and orderEvents orders bound together
			orderEvents.setOrderData(orderStore.orderData);
		}
	}, [orderStore.orderData, orderEvents]);

	useEffect(() => {
		// set today to be today in appropriate TZ
		if(timeZone) {
			const today = dates.getLocalToday(timeZone);
			setToday(today);
		}
	}, [timeZone]);

	const fetchOrders = options => {
		const payload = getPayload(options);
		// console.log('fetchOrders', payload);
		dispatch(ordersActionCreators.fetchOrders(payload, settingStore.orderGroupingMinutes));
	}

	const updateSetting = (setting, value) => {
		dispatch(settingsActionCreators.updateSetting(setting, value));
	}

	const hideOrderCancelDialog = payload => {
		// console.log('dialog returned:', payload);
		setShowOrderCancelDialog(false);
		if(payload) {
			payload.externalOrderId = currentOrder.externalOrderId;
			dispatch(ordersActionCreators.cancelOrder(payload));
		}
	}

	const hideOrderDetailsDialog = btn => {
		// console.log('hideOrderDetailsDialog returned:', btn);
		switch(btn) {
			case 'btnCancel': setShowOrderCancelDialog(true); break;
			case 'btnModify': setOrderModifyProcessRunning(true);; break;
			case 'btnReprint':
				dispatch(printersActionCreators.createPrintJobs({ externalOrderIds: [currentOrder.externalOrderId] }));
				break;
			default: break;
		}
		setShowOrderDetailsDialog(false);
	}

	const orderDetailsHandler = order => {
		const clonedOrder = { ...order };
		clonedOrder.orderItems = [...order.orderItems];
		setCurrentOrder(clonedOrder);
		setShowOrderDetailsDialog(true);
	}

	const bumpHandler = index => {
		setCurrentOrder(orderStore.orderData.orders[index]);
		dispatch(ordersActionCreators.bumpOrders(index, orderStore.orderData.kitchenDisplayType));
	}

	const undoHandler = e => {
		dispatch(ordersActionCreators.undoBumpOrders(orderStore.orderData.kitchenDisplayType));
	}

	const hideConfirmDialogHandler = response => {
		if(response.button === 'btnOk') {
			// user confirms they want to attempt to reconnect to SSE service
			orderEvents.reset();
		}
		setShowConfirmDialog(false);
	}

	return (
		<Fragment>

			{ timeZone
					?
						<OrdersList
							orderStore={ orderStore }
							settings={ settingStore }
							currentOrder={ currentOrder }
							venue={ venue }
							currencySymbol={ currencySymbol }
							timeZone={ timeZone }
							fetchOrders={ fetchOrders }
							fullscreen={ settingStore.fullscreen }
							today={ today }
							loading={ orderEvents.isBusy }
							orderDetailsHandler={ orderDetailsHandler }
							updateSetting={ updateSetting }
							bumpHandler={ bumpHandler }
							undoHandler={ undoHandler }
						/>
					:
						<ValidationMessage
							open={ venue && !venue.openingHours?.timezone }
							message={ _t('TXT_TIMEZONE_MISSING', false, <Link to="/venues/locations/opening-hours">{ _t('TXT_OPENINGHOURS_LINK') }</Link>) }
						/>
			}

			<OrderDetailsDialog
				show={ showOrderDetailsDialog }
				privileged={ user?.hasPrivilegedAccess }
				order={ currentOrder }
				currencySymbol={ currencySymbol }
				timeZone={ timeZone }
				from="OrdersList"
				onHide={ hideOrderDetailsDialog }
			/>

			<OrderCancelDialog
				orderId={ currentOrder?.externalOrderId }
				show={ showOrderCancelDialog }
				onHide={ hideOrderCancelDialog }
			/>

			<OrderModifyProcessContainer
				orderModifyProcessRunning={ orderModifyProcessRunning }
				order={ currentOrder }
				currencySymbol={ currencySymbol }
				setCurrentOrder={ setCurrentOrder }
				setOrderModifyProcessRunning={ setOrderModifyProcessRunning }
			/>

			<ConfirmDialog
				heading={ _t('HDG_LOST_CONNECTION', true) }
				message={ <Notification type="warning">{ _t('TXT_LOST_CONNECTION') }</Notification> }
				okText={ _t('BTN_RECONNECT', true) }
				show={ showConfirmDialog }
				onHide={ hideConfirmDialogHandler }
			/>

		</Fragment>
	);
};

export default OrdersListContainer;

const calcPageSize = fullscreen => {
	const furnitureHeight = fullscreen ? 150 : 360;
	const pageSize = Math.max(Math.round((window.innerHeight - furnitureHeight) / 40), 1);	// - a) / b) a = rough height of page minus rows, b = height of row
	return pageSize;
}