import { Fragment } from 'react';
import { IbanElement } from '@stripe/react-stripe-js';
import verbiage from 'L10n/verbiage';
import { FormInput, FormLabel } from 'components/formControls';

// Custom styling can be passed as options when creating an Element. Values should be sync'd with _import.scss.
const IBAN_STYLE = {
	base: {
		color: '#555',
		fontSize: '14px',
		fontFamily: 'Montserrat, sans-serif',
		'::placeholder': {
			color: '#AAA'
		},
		':-webkit-autofill': {
			color: '#32325d',
		},
	},
	invalid: {
		color: '#E74C34',
		iconColor: '#E74C34',
		':-webkit-autofill': {
			color: '#E74C34',
		},
	}
};

export default function IbanForm(props) {
	const IBAN_ELEMENT_OPTIONS = {
		supportedCountries: ['SEPA'],
		// Elements can use a placeholder as an example IBAN that reflects
		// the IBAN format of your customer's country. If you know your
		// customer's country, we recommend that you pass it to the Element as the
		// placeholderCountry.
		placeholderCountry: props.settings?.countryCode,
		style: IBAN_STYLE,
	};

	return (
		<Fragment>

			<div className="form-row">
				<FormLabel htmlFor="accountholderName" className="col-3" label="_NAME"/>
				<div className="col-9">
					<FormInput form={ props.form } field="accountholderName"/>
				</div>
			</div>

			<div className="form-row">
				<FormLabel htmlFor="accountholderEmail" className="col-3" label="_EMAIL_ADDRESS"/>
				<div className="col-9">
					<FormInput form={ props.form } field="accountholderEmail"/>
				</div>
			</div>

			<div className="form-row">
				<label className="col-form-label col-3" htmlFor="accountholder-email">IBAN</label>
				<div className="col-9">
					<IbanElement className="form-control" options={ IBAN_ELEMENT_OPTIONS } />
				</div>
			</div>

			<div className="mandate-acceptance">{ verbiage.get('mandateAcceptance') }</div>

		</Fragment>
	);
};