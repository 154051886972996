// props.date should be of format 'YYYY-MM-DD'
// internally date is stored as { day: DD, month: MM, year: YYYY }
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import './Calendar.scss';

const Calendar = props => {

	const today = new Date();
	const [date, setDate] = useState(today);
	const [view, setView] = useState('days');
	// const [maxDate, setMaxDate] = useState();	// started implementing a max and min date but then realised I don't think we need this now
	const firstDayOfWeek = props.firstDayOfWeek ?? 1;	// dafault = Monday
	const compactness = props.compactness ?? 1;	// default is suited to a smallish popup calendar
	const previousIcon = props.previousIcon ?? <span className="material-icons">chevron_left</span>;
	const nextIcon = props.nextIcon ?? <span className="material-icons">chevron_right</span>;
	const localeData = props.localeData?.daysLong ? props.localeData : { daysLong, daysShort, monthsLong, monthsShort, clear: 'Clear' };

	useEffect(() => {
		if(props.date instanceof Date) {
			setDate(props.date);
		}
	}, [props.date]);

	// useEffect(() => {
	// 	if(props.maxDate) {
	// 		setMaxDate(simplifyDate(props.maxDate));
	// 	}
	// }, [props.maxDate]);

	const changeDate = (offset, part) => {
		const newDate = new Date(date);
		switch(part) {
			case 'month': newDate.setMonth(newDate.getMonth() + offset); break;
			case 'year': newDate.setYear(newDate.getFullYear() + offset); break;
			default: break;
		}
		// console.log(newDate, offset, part);
		setDate(newDate);
	}

	const previousClickHandler = e => {
		switch(view) {
			case 'months': changeDate(e.shiftKey ? -10 : -1, 'year'); break;
			case 'years': changeDate(e.shiftKey ? -100 : -10, 'year'); break;
			default: changeDate(e.shiftKey ? -12 : -1, 'month'); break;
		}
	}

	const nextClickHandler = e => {
		switch(view) {
			case 'months': changeDate(e.shiftKey ? 10 : 1, 'year'); break;
			case 'years': changeDate(e.shiftKey ? 100 : 10, 'year'); break;
			default: changeDate(e.shiftKey ? 12 : 1, 'month'); break;
		}
	}

	const headerClickHander = e => {
		var newView;
		switch(view) {
			case 'days': newView = 'months'; break;
			case 'months': newView = 'years'; break;
			default: newView = 'days'; break;
		}
		setView(newView);
	}

	const clickHander = e => {
		const cell = e.target;
		switch(view) {
			case 'months': {
				const row = Array.prototype.indexOf.call(cell.parentNode.parentNode.children, cell.parentNode);
				const col = Array.prototype.indexOf.call(cell.parentNode.children, cell);
				const month = row * 3 + col;
				const newDate = new Date(date.getFullYear(), month);
				setDate(newDate);
				setView('days');
				break;
			}
			case 'years': {
				const year = parseInt(cell.innerText, 10);
				const newDate = new Date(year, date.getMonth());
				setDate(newDate);
				setView('months');
				break;
			}
			default: {
				const day = cell.textContent;
				let newDate;
				if(cell.classList.contains('Calendar_faded')) {
					var row = Array.prototype.indexOf.call(cell.parentNode.parentNode.children, cell.parentNode);
					if(row === 1) {
					// test and account for crossing year boundary if selected day in prev month
					if(date.getMonth()) {
							// newDate = { year: date.year, month: date.month - 1, day: day }
							newDate = new Date(date.getFullYear(), date.getMonth() - 1, day);
						} else {
							// newDate = { year: date.year - 1, month: 12, day: day }
							newDate = new Date(date.getFullYear() - 1, 11, day);
						}
					} else {
						// test and account for crossing year boundary if selected day in next month
						if(date.getMonth() < 11) {
							// newDate = { year: date.year, month: date.month + 1, day: day }
							newDate = new Date(date.getFullYear(), date.getMonth() + 1, day);
						} else {
							// newDate = { year: date.year + 1, month: 1, day: day }
							newDate = new Date(date.getFullYear() + 1, 0, day);
						}
					}
				} else {
					newDate = new Date(date.getFullYear(), date.getMonth(), day);
				}
				// console.log(newDate);
				if(props.onChange) props.onChange(newDate, e);
				break;
			}
		}
	}

	const renderHeaderText = () => {
		const monthNames = compactness === 0 ? localeData.monthsShort : localeData.monthsLong;
		switch(view) {
			case 'days': return monthNames[date.getMonth()] + ' ' + date.getFullYear();
			case 'months': return date.getFullYear();
			default:
				const decade = parseInt(date.getFullYear() / 10, 10) * 10;
				return decade + ' - ' + (decade + 9);
		}
	}

	const renderYears = () => {
		// render the instance date's decade (plus one year before and after since we have 12 slots)
		var decade = parseInt(date.getFullYear() / 10, 10) * 10;
		return <div className="Calendar-body">
			{[...Array(4)].map((el, y) => {
				return <div key={ y } className="Calendar-row">
					{[...Array(3)].map((el, x) => {
						const year = decade + y * 3 + x - 1;
						const classes = classNames('Calendar-cell',
							{ 'Calendar_faded': (x === 0 && y === 0) || (x === 2 && y === 3) },
							{ 'Calendar_active': props.date?.getFullYear() === year });
						return <button className={ classes } key={ x } onClick={ clickHander }>{ year }</button>
					})}
				</div>
			})}
		</div>
	}

	const renderMonths = () => {
		return <div className="Calendar-body"> { /* onClick={ onMonthsClick }> */ }
			{[...Array(4)].map((y, y_ix) => {
				return <div key={ y_ix } className="Calendar-row">
					{[...Array(3)].map((x, x_ix) => {
						const offset = y_ix * 3 + x_ix;
						const classes = classNames('Calendar-cell',
							{ 'Calendar_active': props.date?.getFullYear() === date.getFullYear() && props.date?.getMonth() === (offset) });
						return <button className={ classes } key={ x_ix } onClick={ clickHander }>
							{ compactness >= 2 ? localeData.monthsLong[offset] : localeData.monthsShort[offset] }
						</button>
					})}
				</div>
			})}
		</div>
	}

	const renderDays = () => {
		let day, dayNo = 1, done = false;
		const month = date.getMonth();
		const year = date.getFullYear();
		let daysInThisMonth = monthDays[month];
		let daysInLastMonth = (month === 0) ? monthDays[11] : monthDays[month - 1];
		const firstDay = new Date(year, month, 1); // first day of month
		let firstDayColumn = firstDay.getDay() - firstDayOfWeek;
		if(firstDayColumn < 0) {
			firstDayColumn += 7;
		}
		if(daysInThisMonth === 28) {
			daysInThisMonth += (((!(year % 4)) && (year % 100)) || !(year % 400)) ? 1 : 0; // check for leap year
		}
		if(daysInLastMonth === 28) {
			daysInLastMonth += (((!(year % 4)) && (year % 100)) || !(year % 400)) ? 1 : 0; // check for leap year
		}
		return (
			<div className="Calendar-body Calendar-body_days">
				{[...Array(7)].map((el, y) => {
					return <div key={ y } className="Calendar-row">
						{[...Array(7)].map((el, x) => {
							const offset = y * 3 + x;
							let classes = 'Calendar-cell';
							let disabled = false;
							if(y) {
								if(y === 1) {
									if(x < firstDayColumn) {
										day = daysInLastMonth - firstDayColumn + x + 1;
										classes = classNames(classes, 'Calendar_faded',
											{ 'Calendar_today': isSameDay(month ? year : year - 1, month ? month - 1 : 11, day, today) },
											{ 'Calendar_active': isSameDay(month ? year : year - 1, month ? month - 1 : 11, day, props.date) });
										// disabled = isDisabled(year, month - 1, day, null, maxDate);
									} else {
										day = dayNo++;
										classes = classNames(classes,
											{ 'Calendar_today': isSameDay(year, month, day, today) },
											{ 'Calendar_active': isSameDay(year, month, day, props.date) });
										// disabled = isDisabled(year, month, day, null, maxDate);
									}
								} else {
									if(dayNo > daysInThisMonth) {
										if(x === 0) {
											// if we're starting a new row but are already beyond the end of the month, we're done
											done = true;
										}
										day = -daysInThisMonth + dayNo++;
										classes = classNames(classes, 'Calendar_faded',
											{ 'Calendar_today': isSameDay(month === 11 ? year + 1 : year, month === 11 ? 0 : month + 1, day, today) },
											{ 'Calendar_active': isSameDay(month === 11 ? year + 1 : year, month === 11 ? 0 : month + 1, day, props.date) });
										// disabled = isDisabled(month === 11 ? year + 1 : year, month === 11 ? 0 : month + 1, day, null, maxDate);
									} else {
										day = dayNo++;
										classes = classNames(classes,
											{ 'Calendar_today': isSameDay(year, month, day, today) },
											{ 'Calendar_active': isSameDay(year, month, day, props.date) });
										// disabled = isDisabled(year, month, day, null, maxDate);
									}
								}
								return done ? null : <button className={ classes } key={ offset } disabled={ disabled } onClick={ clickHander }>{ day }</button>
							} else {
								// create top row of day names
								var dayNames = compactness >= 3 ? localeData.daysLong : localeData.daysShort;
								var dayName = dayNames[(x + firstDayOfWeek + 6) % 7];
								return <div key={ offset }>{ dayName }</div>
							}
						})}
					</div>
				})}
			</div>
		);
	}

	return (
		<div className={ classNames('Calendar', 'Calendar_' + view, props.className) }>
			<header className="Calendar-header">
				<button type="button" className="Calendar-button Calendar-button_icon" onClick={ previousClickHandler }>{ previousIcon }</button>
				<button type="button" className="Calendar-button" onClick={ headerClickHander }>
					<div>{ renderHeaderText() }</div>
					<i className="material-icons">keyboard_arrow_down</i>
				</button>
				<button type="button" className="Calendar-button Calendar-button_icon" onClick={ nextClickHandler }>{ nextIcon }</button>
			</header>
			{ view === 'days' && renderDays() }
			{ view === 'months' && renderMonths() }
			{ view === 'years' && renderYears() }
			{ typeof props.onClear === 'function' &&
				<div className="Calendar-footer">
					<button type="button" className="Calendar-button" onClick={ props.onClear }>{ props.localeData.clear }</button>
				</div>
			}
		</div>
	);
};

Calendar.propTypes = {
	className: PropTypes.string,	// add a custom class to the component's own
	// date: PropTypes.string,
	firstDayOfWeek: PropTypes.number,
	compactness: PropTypes.number,
	previousIcon: PropTypes.string,
	nextIcon: PropTypes.string,
	localeData: PropTypes.object,
	onChange: PropTypes.func,
	onClear: PropTypes.func
};

export default Calendar;

const monthDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
const daysLong = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
const daysShort = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
const monthsLong = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
const monthsShort = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

// function isDisabled(year, month, day, minDate, maxDate) {
// 	return checkMaxDate(year, month, day, maxDate);
// }

// function checkMaxDate(year, month, day, maxDate) {
// 	if(!maxDate) return false;
// 	if(year < maxDate.year) return false;
// 	if(year === maxDate.year && month < maxDate.month) return false;
// 	if(year > maxDate.year) return true;
// 	if(year === maxDate.year && month > maxDate.month) return true;
// 	if(day <= maxDate.day) return false;
// 	return true;
// }

function isSameDay(year, month, day, date) {
	// console.log(year, month, day, date);
	// checks if a date based on year, month and date is the same day as date
	if(date instanceof Date) {
		const isSame = date.getDate() === day && date.getMonth() === month && date.getFullYear() === year;
		return isSame;
	} else {
		return false;
	}
}