import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import useL10n from 'L10n';
import { billingActionCreators } from 'store/billing';
import { hasVenue } from 'store/venues';
import { Pane, PaneBody, PaneFooter, PaneHeader } from 'components/Pane';
import { Checkbox } from 'components/controls';
import Feedback from './Feedback';

const Status = props => {

	const billingStore = useSelector(state => state.billing);
	const venueStore = useSelector(state => state.venues);
	const dispatch = useDispatch();
	const _t = useL10n().getText;

	useEffect(() => {
		if(hasVenue()) {
			dispatch(billingActionCreators.getOrderingStatus());
		}
	}, [dispatch]);

	const onChange = e => {
		if(e.target.checked) {
			dispatch(billingActionCreators.enableOrdering());
		} else {
			dispatch(billingActionCreators.disableOrdering());
		}
	}

	return (
		<Pane className="Status animated fadeIn">
			<PaneHeader className={ billingStore.loading ? 'async': ''}>

				<h1>{ _t('HDG_STATUS', true) }</h1>

			</PaneHeader>
			<PaneBody className="container">

				<section className="Feedback">
					<ul>
						<li className="notification">
							<i className="material-icons" title="error">keyboard_arrow_right</i>
							{ _t('HDG_POS_INTEGRATION') }:&nbsp;
							<em>{ venueStore.venue?.receivesOrdersInOwnPOS ? _t('LBL_ENABLED') : _t('LBL_DISABLED') }</em>
						</li>
						<li className="notification">
							<i className="material-icons" title="error">keyboard_arrow_right</i>
							{ _t('TXT_PROCESSED_BY') }:&nbsp;
							<em>{ venueStore.venue?.receivesOrdersInOwnPOS ? 'the venue’s POS' : 'Pictabite' }</em>
						</li>
						<li className="notification">
							<i className="material-icons" title="error">keyboard_arrow_right</i>
							{ _t('TXT_CASH_PAYMENTS') }:&nbsp;
							<em>{ billingStore.status?.cashPaymentsEnabled ? _t('LBL_ENABLED') : _t('LBL_DISABLED') }</em>
						</li>
						<li className="notification ws_pre">
							<i className="material-icons" title="error">keyboard_arrow_right</i>
							{ _t('TXT_ORDERS_WHEN', false, <Link to="/venues/locations/opening-hours">{ _t('TXT_OPENINGHOURS_LINK') }</Link>) }
						</li>

						<li className="notification">
							<i className="material-icons" title="error">keyboard_arrow_right</i>
							{ _t('TXT_MARK_OUT_OF_STOCK') }
						</li>
					</ul>
				</section>

				<Feedback status={ billingStore.status }/>

			</PaneBody>
			<PaneFooter className="align-items-center justify-content-center">

				<Checkbox checked={ venueStore.venue?.orderingEnabled ?? false } onChange={ onChange }>Accept Orders</Checkbox>

			</PaneFooter>
		</Pane>
	);

}

export default Status;